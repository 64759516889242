import { useEffect, useMemo, useState } from 'react'
import { getSbtScoreLog } from '../utils/fetch/login'
import useStarterWallet from './useStarterWallet'


export default function useTdaoLastInfo(size:number,TestApiUrl?:string) {
  const [page,setPage] = useState(1)
  const [total,setTotal] = useState(null)
  const {account:addr} = useStarterWallet()
  const [lists,setLists] = useState(null)
  const isOver = useMemo(()=>{
    if(total === null)
      return true
    return total/10 <= page
  },[total,page])
  const onMore=async()=>{
    const {code,data:result} = await getSbtScoreLog({page,addr,size},TestApiUrl)
    if(code === 200){
      const reslist = lists ? lists.concat(result.list):result.list
      setLists(reslist)
      setPage(page+1)
      setTotal(result?.total || 0)
    }
   
  }
  useEffect(() => {
    addr && onMore()
  }, [addr])

  return {lists,onMore,isOver}
}
