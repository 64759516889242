import React, { useState } from "react";
import styled from "styled-components";
import CloseIcon from "../../assets/connect/close@2x.png";
import CloseIcon2 from "../../assets/connect/close2@2x.png";
import TweenOne from 'rc-tween-one';
import {CloseBox} from './Close'

const Modal: React.FC<{
  children: React.ReactNode;
  onClose?: any;
}> = ({ children, onClose }) => {
  return (
    <ModalBase >
      <ModalContent animation={{ y: 0, opacity: 0,scale:.8,delay:0,duration:200, type: 'from' }}>
        <CloseBoxm onClick={onClose}/>
        {children}
      </ModalContent>
    </ModalBase>
  );
};

export default Modal;

const CloseBoxm=styled(CloseBox)`
right: 20px;
  top: 20px;
`
const CloseImg = styled.img`
  width: 11px;
  height: 11px;
`;
const ModalBase = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ModalContent = styled(TweenOne)`
  position: relative;
  width: 574px;
  /* height: 357px; */
  padding: 66px;
  background: #1d1d1d;
  border-radius: 14px;
`;
