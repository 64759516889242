import React from "react";
import styled from "styled-components";



interface CardProps {
  key?: any;
  onClick?:any;
  border?:boolean;
  bgcolor?:string;
  radius?:number;
  className?: string;
  children?:any;
}

export const Base = styled.div<{
  padding?: string;
  width?: string;
  borderRadius?: string;
  altDisabledStyle?: boolean;
}>`
  padding: ${({ padding }) => (padding ? padding : "9px 14px")};
  width: ${({ width }) => (width ? width : "100%")};
  border-radius: 4px;
  border-radius: ${({ borderRadius }) => borderRadius && borderRadius};
  > * {
    user-select: none;
  }
`;

export const CardPrimary = styled(Base)`
  border-radius: 18px;
  border: 2px solid #666666;
`;

interface CardHightProps {
  key?: any;
  onClick?:any;
  border?:boolean;
  bgcolor?:string;
  radius?:number;
  className?: string;
  children?:any;
}

export const CardHight: React.FC<CardHightProps> = ({ bgcolor,radius=11,className,onClick,border, children }) => {
  return (
    <WaiBox radius={radius} className="waibox" border={border} bgcolor={bgcolor} onClick={onClick}>
      <NeiBg radius={radius} className="neibox">
        <NeiBox className={className}>{children}</NeiBox>
      </NeiBg>
    </WaiBox>
  )
}

const WaiBox = styled.div<{bgcolor:string,radius:number,border:boolean}>`
  border-radius: ${props=>props.radius}px;
  padding:1px;
  background-color:${props=>props.bgcolor || 'transparent'};
 ${props=>(props.bgcolor !== 'transparent' ? `background-image:${(props.border ? 'linear-gradient( 270deg,rgba(31, 183, 255, 1),rgba(24, 241, 149, 1))':'none')};
 &:hover {
   background-image: linear-gradient(270deg,rgba(31, 183, 255, 1),rgba(24, 241, 149, 1))
 }`:``)} 
`
const NeiBg = styled.div<{radius:number}>`
  background-color: #0c0f14;
  border-radius: ${props=>props.radius}px;
`
const NeiBox = styled.div``;




export const Card: React.FC<CardProps> = ({ bgcolor,radius=11,className,onClick,border, children }) => {
  return (
    <CardWaiBox radius={radius} className="waibox" border={border} bgcolor={bgcolor} onClick={onClick}>
      <CardNeiBg className={className} radius={radius}>
        <CardNeiBox >{children}</CardNeiBox>
      </CardNeiBg>
    </CardWaiBox>
  )
}


const CardNeiBg = styled.div<{radius:number}>`
  background-color: #0c0f14;
  border-radius: ${props=>props.radius}px;
  overflow:hidden ;
`
const CardWaiBox = styled.div<{bgcolor?:string,radius:number,border?:boolean}>`
  border-radius: ${props=>props.radius}px;
  padding:1px;
  background-color:${props=>props.bgcolor || 'transparent'};
  background-image: linear-gradient(270deg,rgba(31, 183, 255, 1),rgba(24, 241, 149, 1));
  font-weight: 600;
  color: #FFFFFF;
  text-align:center;
`

const CardNeiBox = styled.div``;