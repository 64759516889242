import React,{ useEffect, useState }  from "react";
import { useHistory } from "react-router-dom";
import ContainerWrapper from "../../components/ContainerWrapper";
import CommonInput from "../../components/Input";
import styled from "styled-components";
import leftoutlined from "../../assets/images/left.png";
import NoData from '../../assets/home/noData.png'
import NoDataIcon from "../../components/NoDataIcon";

const NodataDetails = () => {
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };
  return (
    <ContainerWrapper padding={"81px 0 129px"}>
      <NodataHeader>
        <div className="Account-back" onClick={() => goBack()}>
          <img src={leftoutlined} alt="" />
          <span className="title">Back</span>
        </div>
        <CommonInput width="523px" />
      </NodataHeader>
      <EmptyWrapper>
        <NoDataIcon size={260} title="We couldn’t find your search"/>
        {/* <img src={NoData} alt="" /> */}
      </EmptyWrapper>

    </ContainerWrapper>
  );
};

export default NodataDetails;

const NodataHeader = styled.div`
  margin-bottom: 60px;
  display: flex;
  justify-content: space-between;
  .Account-back {
    cursor: pointer;
    img {
      width: 24px;
      height: 24px;
      margin-right: 20px;
      vertical-align: unset;
    }
    .title {
      font-size: 32px;
      font-family: Poppins;
      color: #ffffff;
    }
  }
`;
const EmptyWrapper = styled.div`
      text-align: center;
      /* img{
        margin-top:200px;
        width: 260px;
        height:266px;
      } */
`

