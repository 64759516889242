import { Web3Provider } from "@ethersproject/providers";
import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { PortisConnector } from "@web3-react/portis-connector";
import { getEnv } from "../utils/base/string";

import { FortmaticConnector } from "./Fortmatic";
import { NetworkConnector } from "./NetworkConnector";
import config from "../config";

const NETWORK_URL = getEnv("REACT_APP_NETWORK_URL");
const FORMATIC_KEY = getEnv("REACT_APP_FORTMATIC_KEY");
const PORTIS_ID = getEnv("REACT_APP_PORTIS_ID");

export const NETWORK_CHAIN_ID: number = parseInt(
  "56"
);

if (typeof NETWORK_URL === "undefined") {
  throw new Error(
    `REACT_APP_NETWORK_URL must be a defined environment variable`
  )
}

export const network = new NetworkConnector({
  urls: { [NETWORK_CHAIN_ID]: NETWORK_URL },
});

let networkLibrary: Web3Provider | undefined;
export function getNetworkLibrary(): Web3Provider {
  return (networkLibrary =
    networkLibrary ?? new Web3Provider(network.provider as any));
}

export const injected = new InjectedConnector({
  supportedChainIds: [config.BSC.chainId,config.ETH.chainId],
});

// mainnet only
export const walletconnect = new WalletConnectConnector({
  rpc: { [config.BSC.chainId]: config.BSC.defaultProvider,[config.ETH.chainId]:config.ETH.defaultProvider },
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
  // pollingInterval: 15000,
  // infuraId:''
});

// mainnet only
export const fortmatic = new FortmaticConnector({
  apiKey: FORMATIC_KEY ?? "",
  chainId: 1,
});

// mainnet only
export const portis = new PortisConnector({
  dAppId: PORTIS_ID ?? "",
  networks: [1],
});

// mainnet only
export const walletlink = new WalletLinkConnector({
  url: NETWORK_URL,
  appName: "Uniswap",
  appLogoUrl:
    "https://mpng.pngfly.com/20181202/bex/kisspng-emoji-domain-unicorn-pin-badges-sticker-unicorn-tumblr-emoji-unicorn-iphoneemoji-5c046729264a77.5671679315437924251569.jpg",
});
