import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ContainerWrapper from "../../components/ContainerWrapper";
import CommonInput from "../../components/Input";
import styled from "styled-components";
import useCopyClipboard from "../../hooks/useCopyClipboard";
import copyIcon from "../../assets/images/copy.png";
import copyIcon_act from "../../assets/images/copy_act.png";
import leftoutlined from "../../assets/images/left.png";
import transcation_light from "../../assets/images/transcation-light.png";
import useStarterWallet from "../../hooks/useStarterWallet";
import {
  CustomDescriptions,
  CustomDescriptionsItems,
} from "../../components/Descriptions";
import { Tabs, Descriptions, Spin, Icon } from "antd";
import { getTransactionDetail } from "../../utils/fetch/homepage";
import {
  hide85,
  hide90,
  hide42,
  hide10,
  number_format,
} from "../../utils/utils";
import DiscordModel from "../../components/DiscordModel";
import line_icon from "../../assets/home/line.png";
import { toNonExponential } from "../../utils/utils";
import Copide from "../../components/Copide";
import { dateFormat, formatListType, fromatNumber, getDisplayBalance } from "../../utils/tools";
import { useDecry } from "../../state/application/hooks";
import useBanks from "../../hooks/useBanks";
import { ethers } from "ethers";
import ValueSkeleton from "../../components/ValueSkeleton";
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs;
const antIcon = <Icon type="loading" style={{ fontSize: 30 }} spin />;

const TransactionDetails = () => {
  const history = useHistory();
  const { banks } = useBanks();
  const paramsId = useParams<any>();
  const [loading, setLoading] = useState<Boolean>(false);
  const isFromOther = sessionStorage.getItem("isFromOther");
  const [pageData, setTransationData] = useState<any>();
  const [balance, setBalance] = useState(null);
  // const [isDecry, setIsDecry] = useState<Boolean>(
  //   JSON.parse(sessionStorage.getItem("decryItem")) || false
  // );

  const [isDecry] = useDecry();
  const { wallet, loadKey, decryptStr, account } = useStarterWallet();
  const accountSecond = wallet?.babyjubWallet?.publicCompressed;
  // const accountSecond =
  //   "0x91647a650a1ac6cd0d109bf1c7803598a3c8d6ec4679d8e9af16952185fa9115";
  // const account =
  //   "0x6b73bc5f02fd82f8fcfb9792809a04d330ea94d3f25208982f43c4f2c826de21";
  const statusColor = {
    Verified: "#2070FF",
    Pending: "#FF6F00",
    Committed: "#FF00D8",
    Executed: "#18F195",
    Failed: "#FF3700",
  };

  const transationData = useMemo(() => {
    console.log({detail:decryptStr(pageData, "detail")})
    return decryptStr(pageData, "detail");
  }, [pageData, loadKey]);

  const OriginBalance = useMemo(()=>{
    if(balance){
      const num1 = Number(balance)
      const num2 =  Number(transationData?.transactions_data?.assets?.amount)
      const type = formatListType(transationData?.transactions_data?.type)
      return number_format(type === 'Deposit' ? String(num1) :String(num1+num2),6)
    }
    return null
  },[balance,transationData,isDecry])

  const NewBalance = useMemo(()=>{
    if(balance){
      const num1 = Number(balance)
      const num2 =  Number(transationData?.transactions_data?.assets?.amount)
      const type = formatListType(transationData?.transactions_data?.type)
      const cha = num1-num2
      console.log({cha,num1,num2})
      return number_format(type === 'Deposit' ? String(num1+num2) :num1,6)
    }
    return null
  },[balance,transationData,isDecry])
  const tokenName = useMemo(() => {
    if (isDecry &&transationData?.transactions_data?.assets?.coin.length <= 42) {
      return transationData?.transactions_data?.assets?.coin
    }
  }, [isDecry, transationData])

  const bank = useMemo(() => {
    if (tokenName) {
      const item = banks.find((itm) => itm.depositTokenName === tokenName);
      return item
    }
  }, [tokenName])

  const getDetailFun = () => {
    setLoading(false)
    const params = paramsId?.id

    const datas = {
      addr: accountSecond,
      is_decryption: false,
    }

    getTransactionDetail(params, datas).then((res: any) => {
        if (res.code === 200) {
          setTransationData(res?.data)
          setLoading(true)
        }
      }).catch((error) => {
        console.log("request", error);
      })
  };
  const updateBalance = async () => {
    if (bank) {
      const balance = await bank.depositToken.balanceOf(account);
      console.log({balance:getDisplayBalance})
      setBalance(number_format(ethers.utils.formatEther(balance), 6));
    }
  };

  useEffect(() => {
    // bank && account && updateBalance().catch(() => {});
  }, [bank, account]);

  useEffect(() => {
    getDetailFun();
  }, [accountSecond, isDecry, paramsId?.id]);

  const goBack = () => {
    if (isFromOther === "true") {
      history.goBack();
      return;
    }
    history.push("/");
  };

  // console.log({ transationData, balance ,OriginBalance});
  const {t}=useTranslation()
  return (
    <ContainerWrapper padding={"80px 0 93px"}>
      {loading ? (
        <>
          <TransactionHeader>
            <div className="Transaction-back" onClick={() => goBack()}>
              <img src={leftoutlined} alt="" />
              <span className="title">{t('Transactions details')}</span>
            </div>
            <CommonInput width="523px" />
          </TransactionHeader>
          <TransactionDetail>
            <TransactionTitle>{t('Transactions info')}</TransactionTitle>
            <Basetab marginLeft="10px" height="222px">
              <Tabs defaultActiveKey="1">
                <TabPane tab={t('Base')} key="1">
                  <div className="Transaction-hash">
                    <div className="Transaction-title">{t('Tx Hash')}</div>
                    <div className="Transaction-content">
                      <span>{transationData?.base_info?.tx_hash}</span>
                      <Copide text={transationData?.base_info?.tx_hash} />
                    </div>
                  </div>
                  <Descriptions title={false} column={2} colon={false}>
                    <Descriptions.Item label={t('Status')}>
                      <StatusBar
                        color={statusColor[transationData?.base_info?.status]}
                      >
                        {t(transationData?.base_info?.status)}
                      </StatusBar>
                    </Descriptions.Item>
                    <Descriptions.Item label={t('Nonce')}>
                      {transationData?.base_info?.nonce}
                    </Descriptions.Item>
                    <Descriptions.Item label={t('Height')}>
                      <span
                        style={{ cursor: "pointer", color: "#18F195" }}
                        onClick={() => {
                          history.push(
                            `/explore/blockdetail/${transationData?.base_info?.height}`
                          );
                        }}
                      >
                        {transationData?.base_info?.height}
                      </span>
                    </Descriptions.Item>
                    <Descriptions.Item label={t('Create at')}>
                      {dateFormat(transationData?.base_info?.create_at)}
                    </Descriptions.Item>
                    <div className="line"></div>
                  </Descriptions>
                </TabPane>
              </Tabs>
            </Basetab>
          </TransactionDetail>
          <TransactionTable>
            <TransactionTitle marginbottom={"40px"}>
             {t('Transactions details')} 
            </TransactionTitle>
            <TransactionContent>
              <CustomDescriptions marginBottom={"17px"} titleWidth={"97px"}>
                <CustomDescriptionsItems label={t("Type")}>
                  {t(formatListType(transationData?.transactions_data?.type))}
                </CustomDescriptionsItems>
                <CustomDescriptionsItems label={t("From Address")}>
                  {transationData?.transactions_data?.from.map((item: any) => {
                    return (
                      <>
                        <div>
                          {item.is_contract && (
                            <span
                              style={{
                                color: "#18F195",
                                display: "inline-block",
                                marginRight: "10px",
                              }}
                            >
                              contract
                            </span>
                          )}
                          {isDecry && item.address.length <= 42 ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 5,
                                color: "#18F195",
                                cursor: "pointer",
                              }}
                            >
                              <span
                                onClick={() => {
                                  // history.push(
                                  //   `/explore/accountdetail/${paramsId?.id}/${transationData?.transactions_data?.chain_url}`
                                  // );
                                }}
                              >
                                {item.address}
                              </span>
                              <Copide text={item.address} />
                            </div>
                          ) : (
                            hide90(item.address)
                          )}
                        </div>
                        {/* <div>
                          {item?.balance.map((item1: any) => {
                            return (
                              <div className="balanceClass">
                                <div>
                                  {t('Origin Balance')}:
                                  <span>{isDecry &&  item1?.origin?.coin.length <= 6 ? (OriginBalance === null ? <ValueSkeleton width={20}/> :OriginBalance):item1?.origin?.amount}</span>
                                  <span>
                                    {isDecry && item1?.origin?.coin.length <= 6
                                      ? `${item1?.origin?.coin}`
                                      : hide10(item1?.origin?.coin)}
                                  </span>
                                </div>
                                <div>
                                  {t('New Balance')}: <span>{isDecry &&  item1?.new?.coin.length <= 6 ? (NewBalance === null ? <ValueSkeleton width={20}/> :NewBalance):item1?.new?.amount}</span>
                                  <span>
                                    {isDecry && item1?.new?.coin.length <= 6
                                      ? item1?.new?.coin
                                      : hide10(item1?.new?.coin)}
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                        </div> */}
                      </>
                    );
                  })}
                </CustomDescriptionsItems>
                <CustomDescriptionsItems label={t("To Address")}>
                  {transationData?.transactions_data?.to.map((item: any) => {
                    return (
                      <>
                        <div>
                          {item.is_contract && (
                            <span
                              style={{
                                color: "#18F195",
                                display: "inline-block",
                                marginRight: "10px",
                              }}
                            >
                             {t('contract')} 
                            </span>
                          )}
                          {isDecry && item.address.length <= 42 ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 5,
                              }}
                            >
                              <span
                                style={{
                                  color: "#18F195",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  // history.push(
                                  //   `/explore/accountdetail/${paramsId?.id}/${transationData?.transactions_data?.chain_url}`
                                  // );
                                }}
                              >
                                {item.address}
                              </span>
                              <Copide text={item.address} />
                            </div>
                          ) : (
                            hide90(item.address)
                          )}
                        </div>
                        <div>
                          {item?.balance.map((item1: any) => {
                            return (
                              <div className="balanceClass">
                                <div>
                                  {t('Origin Balance')}:
                                  <span>
                                    {Math.floor(item1?.origin?.amount)}
                                  </span>
                                  <span>
                                    {isDecry && item1?.origin?.coin.length <= 42
                                      ? item1?.origin?.coin
                                      : hide85(item1?.origin?.coin)}
                                  </span>
                                </div>
                                <div>
                                  {t('New Balance')}: <span>{item1?.new?.amount}</span>
                                  <span>
                                    {isDecry && item1?.new?.coin.length <= 42
                                      ? item1?.new?.coin
                                      : hide85(item1?.new?.coin)}
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    );
                  })}
                </CustomDescriptionsItems>
                <CustomDescriptionsItems label={t("Assets")}>
                  <span>
                    {isDecry &&
                    transationData?.transactions_data?.assets?.amount.length <=
                      42
                      ? transationData?.transactions_data?.assets?.amount
                      : hide42(
                          transationData?.transactions_data?.assets?.amount
                        )}
                  </span>
                  <span style={{ marginLeft: "10px" }}>
                    {isDecry &&
                    transationData?.transactions_data?.assets?.coin.length <= 42
                      ? transationData?.transactions_data?.assets?.coin
                      : hide42(transationData?.transactions_data?.assets?.coin)}
                  </span>
                </CustomDescriptionsItems>
                <CustomDescriptionsItems label={t("Gas Fee")}>
                  <span>
                    {isDecry &&
                    transationData?.transactions_data?.gas_fee?.fee.length <= 42
                      ? fromatNumber(transationData?.transactions_data?.gas_fee?.fee)
                      : hide42(transationData?.transactions_data?.gas_fee?.fee)}
                  </span>

                  <span style={{ marginLeft: "10px" }}>
                    {isDecry &&
                    transationData?.transactions_data?.gas_fee?.fee.length <= 42
                      ? transationData?.transactions_data?.gas_fee?.coin
                      : hide42(transationData?.transactions_data?.gas_fee?.coin)}
                  </span>
                </CustomDescriptionsItems>
                <CustomDescriptionsItems label={t("Chain")}>
                  {formatListType(transationData?.transactions_data?.type) ===
                  "Send"
                    ? "Tusima"
                    : transationData?.transactions_data?.chain}
                </CustomDescriptionsItems>
              </CustomDescriptions>
            </TransactionContent>
          </TransactionTable>
          <TransactionLog>
            <TransactionTitle>{t('Transactions log')}</TransactionTitle>
            <Basetab marginLeft="28px">
              <CustomDescriptions marginBottom={"17px"} titleWidth={"99px"}>
                {transationData?.transactions_log?.committed_at && (
                  <CustomDescriptionsItems label={t("Committed At")}>
                    {dateFormat(transationData?.transactions_log?.committed_at)}
                  </CustomDescriptionsItems>
                )}
                {transationData?.transactions_log?.executed_at && (
                  <CustomDescriptionsItems label={t("Executed At")}>
                    {dateFormat(transationData?.transactions_log?.executed_at)}
                  </CustomDescriptionsItems>
                )}
                {transationData?.transactions_log?.verified_at && (
                  <CustomDescriptionsItems label={t("Verified At")}>
                    {dateFormat(transationData?.transactions_log?.verified_at)}
                  </CustomDescriptionsItems>
                )}

                {transationData?.transactions_log?.eth_forge_hash && (
                  <CustomDescriptionsItems label={t("Forge Hash")} name={"ETH"}>
                    {dateFormat(
                      transationData?.transactions_log?.eth_forge_hash
                    )}
                  </CustomDescriptionsItems>
                )}
              </CustomDescriptions>
            </Basetab>
          </TransactionLog>
          <TranscationLight></TranscationLight>
        </>
      ) : (
        <Spin indicator={antIcon} />
      )}

      <DiscordModel url="https://discord.com/channels/965918503070728203/1011838420630380614"></DiscordModel>
    </ContainerWrapper>
  );
};

export default TransactionDetails;

const TransactionHeader = styled.div`
  margin-bottom: 60px;
  display: flex;
  justify-content: space-between;
  .Transaction-back {
    cursor: pointer;
    img {
      width: 24px;
      height: 24px;
      margin-right: 20px;
      vertical-align: unset;
    }
    .title {
      font-size: 32px;
      font-family: Poppins;
      color: #ffffff;
    }
  }
`;

const TranscationLight = styled.div`
  position: absolute;
  top: 344px;
  right: 212px;
  width: 725px;
  height: 738px;
  background-image: url(${transcation_light});
  background-size: 100% 100%;
`;

const StatusBar = styled.div<{ color: string }>`
  color: ${({ color }) => color};
`;

const TransactionDetail = styled.div`
  margin-bottom: 60px;
  border-radius: 8px;
  .Transaction-hash {
    display: flex;
    position: relative;
    justify-content: center;
    z-index: 1;
    /* margin-left: 257px; */
    margin-bottom: 20px;
    .Transaction-title {
      width: 54px;
      font-size: 14px;
      font-family: Poppins-Regular, Poppins;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.4);
      margin-right: 20px;
    }
    .Transaction-content {
      font-size: 14px;
      font-family: Poppins;
      color: #ffffff;
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
  .line {
    position: absolute;
    height: 92px;
    width: 3px;
    background-image: url(${line_icon});
    background-size: 100% 100%;
    top: 56%;
    left: 47%;
  }
  .Proof {
    display: inline-block;
    color: rgba(255, 255, 255, 0.8);
    max-height: 72px;
    overflow: auto;
  }
  .copy {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-top: 20px;
    background-image: url(${copyIcon});
    background-size: 100% 100%;
    cursor: pointer;
    &:hover {
      color: #fff;
      background-image: url(${copyIcon_act});
    }
  }
  .ant-descriptions-item:nth-child(odd) {
    .ant-descriptions-item-content {
      width: 50%;
    }
  }

  .ant-descriptions-item-label {
    width: 39%;
    text-align: left;
    font-family: Poppins-Regular, Poppins;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.4);
  }
  .ant-descriptions-row > th,
  .ant-descriptions-row > td {
    padding-bottom: 17px;
  }
  .ant-descriptions-item-content {
    width: 50%;
    text-align: right;
    font-size: 14px;
    font-family: Poppins-Medium, Poppins;
    font-weight: 500;
    color: #ffffff;
  }
  .ant-descriptions-item:nth-child(even) {
    .ant-descriptions-item-label {
      width: 30%;
    }
    .ant-descriptions-item-content {
      width: 70%;
    }
  }
`;
const TransactionTable = styled.div`
  margin-bottom: 60px;

  .balanceClass {
    color: rgba(255, 255, 255, 0.8);
    span {
      display: inline-block;
      margin-left: 10px !important;
    }
  }
`;

const TransactionContent = styled.div`
  position: relative;
  z-index: 11;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 30px;
`;
const TransactionLog = styled.div``;

const TransactionTitle = styled.div<{ marginbottom?: string }>`
  font-size: 24px;
  font-family: Poppins;
  color: #ffffff;
  margin-bottom: ${({ marginbottom }) =>
    marginbottom ? marginbottom : "40px"};
`;

const Basetab = styled.div<{ marginLeft?: string; height?: string }>`
  position: relative;
  height: ${({ height }) => (height ? height : "100%")};
  padding: 30px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  .ant-tabs-nav .ant-tabs-tab {
    font-size: 16px;
    font-family: Poppins-Regular, Poppins;
    font-weight: 500;
    color: #fff;
    margin: 0;
    // margin-right: 30px;
    padding: 0 0px 8px 0;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    color: #18f195;
    font-size: 16px;
    font-family: Poppins;
    margin: 0;
    // margin-right: 30px;
    padding: 0 0px 8px 0;
  }
  .ant-tabs-ink-bar {
    width: 16px !important;
    position:relative;
    margin:0 auto;
    background: #18f195 !important;
  }
  
  .ant-tabs-bar {
    margin-bottom: 30px;
    border: none;
  }
  .ant-tabs-nav-scroll {
    text-align: center;
  }
`;
