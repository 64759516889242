import React from 'react';
// import styled from 'styled-components';
import styled from 'styled-components';
import CircleStatus from './circleStatus'
import { hideAddress } from "../../../utils/utils";
import { dateFormat, formatListType } from '../../../utils/tools';
import {Trans} from  "react-i18next";

const lasttransaction = [
  {
    title: 'Hash',
    dataIndex: 'hash',
    render:(record:any)=><>{hideAddress(record)}</>
  },
  {
    title: 'Address',
    dataIndex: 'fromEthAddr',
    render:(record:any)=><>{hideAddress(record)}</>
  },
  {
    title: 'Type',
    dataIndex: 'type',
    render: (record: any) => <Trans>{formatListType(record)}</Trans>,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (record: any) => <><CircleStatus status={record} ></CircleStatus><Trans>{record}</Trans></>,
  },
  {
    title: 'Executed At',
    dataIndex: 'executed_at',
    render:(record:any)=>dateFormat(record,'lists')
  },
];

export default lasttransaction;
