import React from 'react';
import styled from 'styled-components';
import CircleStatus from './circleStatus'
import { hideAddress } from "../../../utils/utils";
import { dateFormat } from '../../../utils/tools';
import {Trans} from  "react-i18next";
const lastblock = [
  {
    title: 'Block',
    dataIndex: 'id',
    render: (record: any) => <Block>{record}</Block>,
  },
  {
    title: 'Block Hash',
    dataIndex: 'hash',
    render:(record:any)=><>{hideAddress(record)}</>
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (record: any) => <><CircleStatus status={record} ></CircleStatus><Trans>{record}</Trans></>,
  },
  {
    title: 'Txn',
    dataIndex: 'txn',
  },
  {
    title: 'Committed At',
    dataIndex: 'committed_at',
    render:(record:any)=>dateFormat(record,'lists')
  },
  {
    title: 'Verified At',
    dataIndex: 'verified_at',
    render:(record:any)=>dateFormat(record,'lists')
  },
  {
    title: 'Executed At',
    dataIndex: 'executed_at',
    render:(record:any)=>dateFormat(record,'lists')
  },
];

export default lastblock;

const Block = styled.div`
  color: #18f195;
`;

