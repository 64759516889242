import abiRollup from "./abiRollup.json"
import abiShuiPool from "./abifaucel.json"
import { BankInfo, Deployments, VersionType } from "./poolsType"

export const externalTokens: { [contractName: string]: [string, number] } = {
  wETH: ["0xa8C059Edf29b9c21DB9d25Df237da577D0f5A196", 18],
  USDT: ["0x1dA38b22066bC81ced6b966f07F24D3627263B47", 18],
  USDC: ["0xdE86815690FD8bdfF7046152aFe4EE36D05D38b5", 18],
  wBNB: ["0x918AfE3642902674F47f51eC1305B3551c4cc621", 18],
  TSM: ["0x71a542Ccd428ba401549Efc4915C813c987d8091", 18],
  NFT: ["0xc17D52973C6C3A1b98c0655d915E4c912FF2A539", 18],

  wETH_V2: ["0xa8C059Edf29b9c21DB9d25Df237da577D0f5A196", 18],
  USDT_V2: ["0x1dA38b22066bC81ced6b966f07F24D3627263B47", 18],
  USDC_V2: ["0xdE86815690FD8bdfF7046152aFe4EE36D05D38b5", 18],
  wBNB_V2: ["0x918AfE3642902674F47f51eC1305B3551c4cc621", 18],
  TSM_V2: ["0x71a542Ccd428ba401549Efc4915C813c987d8091", 18],
  NFT_V2: ["0xc17D52973C6C3A1b98c0655d915E4c912FF2A539", 18],
}

export const deployments: Deployments = {
  ETHPool: {
    "address": "0x40d8AC2691291B459DBC66aF52C739378B3F74Ef",
    "abi": abiRollup,
  },
  USDTPool: {
    "address": "0x40d8AC2691291B459DBC66aF52C739378B3F74Ef",
    "abi": abiRollup,
  },
  BNBPool: {
    "address": "0x40d8AC2691291B459DBC66aF52C739378B3F74Ef",
    "abi": abiRollup,
  },
  USDCPool: {
    "address": "0x40d8AC2691291B459DBC66aF52C739378B3F74Ef",
    "abi": abiRollup,
  },
  TSMPool: {
    "address": "0x40d8AC2691291B459DBC66aF52C739378B3F74Ef",
    "abi": abiRollup,
  },
  ShuiPool: {
    "address": "0xfbaf6aE325CCAc9f5639C9DAf09dfa6509Acce25",
    "abi": abiShuiPool,
  },
}
// 721 token
export const tokens721: { [contractName: string]: [string, number] } = {
  "NFTToken": ["0x6dadD1cb33521700b6ea9B278adEe08891fB55C4", 18],
}
export const bankDefinitions: BankInfo[] = [
  {
    name: "wETH",
    contract: "ETHPool",
    tokenId: 0,
    depositTokenName: "wETH",
    earnTokenName: "wETH",
    finished: false,
    fee: "0.001%",
    sort: 1,
    minVal: 0.0001,
    stepVal: 0.0001,
    display: true,
    version: VersionType.V1,
  },
  {
    name: "TSM",
    contract: "TSMPool",
    tokenId: 1,
    depositTokenName: "TSM",
    earnTokenName: "TSM",
    finished: false,
    fee: "0.005%",
    sort: 2,
    minVal: 1,
    stepVal: 0.5,
    display: true,
    version: VersionType.V1,
  },
  {
    name: "USDT",
    contract: "USDTPool",
    tokenId: 2,
    depositTokenName: "USDT",
    earnTokenName: "USDT",
    finished: false,
    fee: "0.005%",
    sort: 3,
    minVal: 1,
    stepVal: 0.5,
    display: true,
    version: VersionType.V1,
  },
  {
    name: "USDC",
    contract: "USDCPool",
    tokenId: 3,
    depositTokenName: "USDC",
    earnTokenName: "USDC",
    finished: false,
    fee: "0.005%",
    sort: 4,
    minVal: 1,
    stepVal: 0.5,
    display: true,
    version: VersionType.V1,
  },
  {
    name: "wBNB",
    contract: "BNBPool",
    tokenId: 4,
    depositTokenName: "wBNB",
    earnTokenName: "wBNB",
    finished: false,
    fee: "0.002%",
    sort: 5,
    minVal: 0.01,
    stepVal: 0.01,
    display: true,
    version: VersionType.V1,
  },

  {
    name: "wETH",
    contract: "ETHPool",
    tokenId: 0,
    depositTokenName: "wETH_V2",
    earnTokenName: "wETH_V2",
    finished: false,
    fee: "0.001%",
    sort: 1,
    minVal: 0.0001,
    stepVal: 0.0001,
    display: true,
    version: VersionType.V2,
  },
  {
    name: "TSM",
    contract: "TSMPool",
    tokenId: 1,
    depositTokenName: "TSM_V2",
    earnTokenName: "TSM_V2",
    finished: false,
    fee: "0.005%",
    sort: 2,
    minVal: 1,
    stepVal: 0.5,
    display: true,
    version: VersionType.V2,
  },
  {
    name: "USDT",
    contract: "USDTPool",
    tokenId: 2,
    depositTokenName: "USDT_V2",
    earnTokenName: "USDT_V2",
    finished: false,
    fee: "0.005%",
    sort: 3,
    minVal: 1,
    stepVal: 0.5,
    display: true,
    version: VersionType.V1,
  },
  {
    name: "USDC",
    contract: "USDCPool",
    tokenId: 3,
    depositTokenName: "USDC_V2",
    earnTokenName: "USDC_V2",
    finished: false,
    fee: "0.005%",
    sort: 4,
    minVal: 1,
    stepVal: 0.5,
    display: true,
    version: VersionType.V2,
  },
  {
    name: "wBNB",
    contract: "BNBPool",
    tokenId: 4,
    depositTokenName: "wBNB_V2",
    earnTokenName: "wBNB_V2",
    finished: false,
    fee: "0.002%",
    sort: 5,
    minVal: 0.01,
    stepVal: 0.01,
    display: true,
    version: VersionType.V2,
  },
].filter((itm) => !itm.finished)
