import React from "react";
import Svg from "./Svg";

const Icon: React.FC<React.PropsWithChildren<any>> = (props) => (
  <Svg viewBox="0 0 24 24" {...props}>
    <path d="M20.834899,10.0815425 L1.22558229,10.0815425 C0.549060868,10.0815425 0,9.37301144 0,8.5 C0,7.62698856 0.549060868,6.91845753 1.22558229,6.91845753 L20.834899,6.91845753 C21.5114204,6.91845753 22.0604813,7.62698856 22.0604813,8.5 C22.0604813,9.37301144 21.5114204,10.0815425 20.834899,10.0815425 Z" id="lll1"></path>
    <path d="M21.3287644,9.40780538 L14.6964079,2.69890222 C14.0866438,2.08210066 14.0866438,1.07940274 14.6964079,0.462601172 C15.3061719,-0.154200391 16.2974293,-0.154200391 16.9071934,0.462601172 L23.542677,7.17150433 C24.152441,7.78830589 24.152441,8.79100381 23.542677,9.40780538 C22.9297859,10.02777 21.9416555,10.02777 21.3287644,9.40780538 Z" id="lll2"></path>
    <path d="M14.6964079,14.3010978 L21.3287644,7.59219462 C21.9385285,6.97539306 22.9297859,6.97539306 23.53955,7.59219462 C24.149314,8.20899619 24.149314,9.21169411 23.53955,9.82849567 L16.9071934,16.5373988 C16.2974293,17.1542004 15.3061719,17.1542004 14.6964079,16.5373988 C14.0866438,15.9205973 14.0866438,14.9178993 14.6964079,14.3010978 Z" id="lll3"></path>
  </Svg>
);

export default Icon;
