import React, { useEffect, useState, useRef, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import ContainerWrapper from "../../components/ContainerWrapper";
import CommonInput from "../../components/Input";
import CustomTable from "../../components/CustomTable";
import { accountPage } from "./columns";
import styled from "styled-components";
import leftoutlined from "../../assets/images/left.png";
import account_light from "../../assets/images/account-light.png";
import { Descriptions } from "antd";
import { getMyDetail } from "../../utils/fetch/homepage";
import { hideAddress } from "../../utils/utils";
import useStarterWallet from "../../hooks/useStarterWallet";
import { useDecry } from "../../state/application/hooks";
import { useTranslation } from "react-i18next";

const AccountDetails = () => {
  const history = useHistory();
  const paramsId = useParams<any>();
  const isFromOther = sessionStorage.getItem("isFromOther");
  const [baseInfo, setBaseInfo] = useState<any>();
  const [balanceData, setBalanceData] = useState<any>();
  // const [isDecry, setIsDecry] = useState<Boolean>(
  //   JSON.parse(sessionStorage.getItem("decryItem")) || false
  // );
  const [isDecry, setIsDecry] = useDecry() 
  const { wallet } =
    useStarterWallet();

  const accountSecond = wallet?.babyjubWallet?.publicCompressed;
  // const accountSecond =
  //   "0x91647a650a1ac6cd0d109bf1c7803598a3c8d6ec4679d8e9af16952185fa9115";
  const token = {
    1: "wETH",
    0: "TSM",
    2: "USDT",
    3: "USDC",
    4: "wBNB",
  };

  //balance
  const balanceColumns = [
    {
      title: "token",
      dataIndex: "token",
      render: (record: any) => (
        <>
          {!isDecry
            ? token[record]
            : record}
        </>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (record: any) => (
        <>
          {isDecry
            ? hideAddress(record)
            : Math.floor(record)}
        </>
      ),
    },
    {
      title: "Value",
      dataIndex: "value",
    },
  ];
  const goBack = () => {
    if (isFromOther === "true") {
      history.goBack();
      return;
    }
    history.push("/");
  };
  const DetailFun = () => {
    getMyDetail(paramsId?.id, {
      is_decryption: isDecry,
      chain: paramsId?.chain,
      addr: accountSecond,
    }).then((res: any) => {
        if (res.code === 200) {
          setBaseInfo(res?.data?.base_info);
        }
      }).catch((error) => {
        console.log("request ", error);
      });
  };
  useEffect(() => {
    accountSecond && DetailFun();
  }, [accountSecond]);

  const {t} = useTranslation()
  return (
    <ContainerWrapper padding={"81px 0 129px"}>
      <AccountHeader>
        <div className="Account-back" onClick={() => goBack()}>
          <img src={leftoutlined} alt="" />
          <span className="title">{t('Account Details')}</span>
        </div>
        <CommonInput width="523px" />
      </AccountHeader>
      {accountSecond?<AccountDetail>
        <AccountTitle>{t('Base info')}</AccountTitle>
        <Basetab height="204px">
          <div className="Account-adress">
            {hideAddress(baseInfo?.ethAddr)}
          </div>
          <div className="Account-hash">
            <div className="Account-title">{t('Total amount')}</div>
            <div className="Account-content">{baseInfo?.total_amount}</div>
          </div>
          <Descriptions title={false} column={2} colon={false}>
            <Descriptions.Item label={t('Status')}>
              <StatusBar color={"Account ID"}>{baseInfo?.idx}</StatusBar>
            </Descriptions.Item>
            <Descriptions.Item label={t('Public Key')}>
              {accountSecond}
            </Descriptions.Item>
            <Descriptions.Item label={t('Total Tx')}>
              {baseInfo?.total_tx}
            </Descriptions.Item>
            <Descriptions.Item label={t('Create at')}>
              {baseInfo?.create_at}
            </Descriptions.Item>
            <div className="line"></div>
          </Descriptions>
        </Basetab>
      </AccountDetail>:''}
      {accountSecond ? <AccountTable>
        <AccountTitle marginbottom={"20px"}>{t('Balance')}</AccountTitle>
        <CustomTable
          isLight={false}
          type="aaa"
          columns={balanceColumns}
          params={{ is_decryption: isDecry, addr: accountSecond }}
          pagination={false}
          onCallBack={(params: any) => {
            if (isDecry) {
              params?.map((item: any) => {
                return console.log(1 * item?.amount);
              });
            }
          }}
          url={`/api/v1/home/my_balance/${accountSecond}`}
        />
      </AccountTable>: null}
      {accountSecond?<AccountTable>
        <AccountTitle marginbottom={"20px"}>{t('Transactions')}</AccountTitle>
        <CustomTable
          columns={accountPage.transactionColumns}
          pagination
          params={{ is_decryption: isDecry, addr: accountSecond }}
          onRow={(params) => {
            history.push(`/explore/transactiondetail/${params?.id}`);
          }}
          url={`/api/v1/home/my_transaction/${accountSecond}`}
        />
      </AccountTable>:''}
      <AccountLight></AccountLight>
    </ContainerWrapper>
  );
};

export default AccountDetails;

const AccountHeader = styled.div`
  margin-bottom: 60px;
  display: flex;
  justify-content: space-between;
  .Account-back {
    cursor: pointer;
    img {
      width: 24px;
      height: 24px;
      margin-right: 20px;
      vertical-align: unset;
    }
    .title {
      font-size: 32px;
      font-family: Poppins;
      color: #ffffff;
    }
  }
`;

const AccountLight = styled.div`
  position: absolute;
  top: 281px;
  right: 212px;
  width: 725px;
  height: 738px;
  background-image: url(${account_light});
  background-size: 100% 100%;
`;

const StatusBar = styled.div<{ color: string }>`
  color: ${({ color }) => color};
`;

const AccountDetail = styled.div`
  margin-bottom: 60px;
  border-radius: 8px;

  .Account-adress {
    height: 14px;
    line-height: 14px;
    color: #18f195;
    text-align: center;
    margin-bottom: 24px;
  }
  .Account-hash {
    display: flex;
    height: 14px;
    line-height: 14px;
    margin-left: 425px;
    margin-bottom: 35px;
    .Account-title {
      width: 94px;
      font-size: 14px;
      font-family: Poppins-Regular, Poppins;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.4);
      margin-right: 20px;
    }
    .Account-content {
      font-size: 14px;
      font-family: Poppins;
      color: #ffffff;
    }
  }
  .line {
    position: absolute;
    height: 34px;
    border: 1px dashed rgba(255, 255, 255, 0.2);
    top: 54%;
    left: 48%;
  }
  .ant-descriptions-item-label {
    width: 39%;
    text-align: left;
    font-family: Poppins-Regular, Poppins;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.4);
  }
  .ant-descriptions-row > th,
  .ant-descriptions-row > td {
    padding-bottom: 17px;
  }
  .ant-descriptions-item-content {
    width: 50%;
    text-align: right;
    font-size: 14px;
    font-family: Poppins-Medium, Poppins;
    font-weight: 500;
    color: #ffffff;
  }
`;
const AccountTable = styled.div`
  margin-bottom: 60px;
`;

const AccountTitle = styled.div<{ marginbottom?: string }>`
  font-size: 24px;
  font-family: Poppins;
  color: #ffffff;
  margin-bottom: ${({ marginbottom }) =>
    marginbottom ? marginbottom : "40px"};
`;

const Basetab = styled.div<{ height: string }>`
  height: ${({ height }) => height};
  padding: 30px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
`;
