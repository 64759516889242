/* eslint-disable */
import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

export interface OptionProp {
  name: string;
  value: string;
}

export default function WelcomeTitle() {
  const {t}=useTranslation()

  const welcomeTxt = t('Welcome to the Tusima Privacy Finance Network')

  // const res = welcomeTxt.split('the')
  return (
    <TitleBox>
      <TitleText dangerouslySetInnerHTML={{__html:welcomeTxt}}/>
    </TitleBox>
  );
}

const TitleBox = styled.div`
  display: flex;
`;
const TitleText = styled.div`
  margin: 0 auto;
  text-align: left;
  font-size: 48px;
  font-family: Poppins;
  color: #ffffff;
  line-height: 55px;
  font-weight: 600;
  /* padding-top: 100px; */
  .on1 {
    /* padding-left: 168px; */
  }
  p{
    padding:0 ;
    margin:0 ;
    &:last-child {
    font-size: 36px;
    padding-left: 158px;
    span {
      color: #18f195;
    }
  }
  }
`;
