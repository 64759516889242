import React from "react";
import styled from "styled-components";
import Copide from "../Copide";
import { DescriptionProps } from "./data";

export const CustomDescriptionsItems = (props: any) => {
  const { children, label, name,isCopy } = props;
  return (
    <ItemsWrapper className="items-wrapper">
      <DiscriptionsTitle className="title">{label}</DiscriptionsTitle>
      <DiscriptionsContent>
        <span style={{ cursor: name ? "pointer" : '' }}
        className="content"
        onClick={() => {
          if (name === "ETH" && children.length > 10) {
            window.open(`https://goerli.etherscan.io/tx/${children}`, "_blank");
          } else if (name === "BNB" && children.length > 10) {
            window.open(`https://testnet.bscscan.com/tx/${children}`, "_blank");
          }
        }}>
         {children} 
        </span>
       {isCopy && <Copide text={children}/>}
      </DiscriptionsContent>
    </ItemsWrapper>
  )
};

export const CustomDescriptions = (props: DescriptionProps) => {
  const { children, marginLeft, marginBottom, titleWidth, contentWidth } =
    props;
  return (
    <DiscriptionsWrapper
      marginLeft={marginLeft}
      marginBottom={marginBottom}
      titleWidth={titleWidth}
      contentWidth={contentWidth}
    >
      {children}
    </DiscriptionsWrapper>
  );
};

const DiscriptionsWrapper = styled.div<{
  marginLeft?: string;
  marginBottom?: string;
  titleWidth?: string;
  contentWidth?: string;
}>`
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : "0")};
  .items-wrapper {
    overflow:hidden;
    margin-bottom: ${({ marginBottom }) =>marginBottom ? marginBottom : "20px"};
    &:last-child {
      margin-bottom: 0;
    }
  }
  .title {
    width: ${({ titleWidth }) => (titleWidth ? titleWidth : "100%")};
  }
  .content {
    max-width: ${({ contentWidth }) => (contentWidth ? contentWidth : "100%")};
    a {
      display: inline-block;
    }
  }
`;

const ItemsWrapper = styled.div`
  display: flex;
`;

const DiscriptionsTitle = styled.div`
  font-size: 14px;
  font-family: Poppins-Regular, Poppins;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.4);
  margin-right: 20px;
`;
const DiscriptionsContent = styled.div`
  font-size: 14px;
  font-family: Poppins;
  color: #ffffff;
  display:flex;
  align-items:center;
  gap:5px;
`;
