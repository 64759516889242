/* eslint-disable */
import React, { useState, useCallback, useEffect, useMemo } from "react"

import HeaderLogo from "../../assets/images/nav-icons/logo.png"

import HeaderLogoJp from "../../assets/images/nav-icons/LOGO_jp@2x.png"
import newIcon from "../../assets/images/nav-icons/new.png"
import hotIcon from "../../assets/images/nav-icons/hot.png"
import styled from "styled-components"
import { ButtonSecondary } from "../Button"
import { useHistory } from "react-router-dom"
import { hidehash } from "../../utils/utils"
import useStarterWallet, { LoginState } from "../../hooks/useStarterWallet"
import Ico_address from "../../assets/images/image_btn.png"
import { useLocation } from "react-router"
import { ListFormat } from "typescript"
import { $CombinedState } from "@reduxjs/toolkit"
import DisconnectIcon from '../../assets/connect/disconnect@2x.png'
import { CardHight, Card } from "../../components/Card";
import LoadingIcon from "../Button/LoadingIcon";
import { AccountSelect } from "../Select";
import UpRateIcon from '../../assets/connect/uprate@2x.png'
import Marquee from "../Marquee";
import LangIcon from "./LangIcon";
import { useTranslation } from "react-i18next"
const StyledNav = styled.div`
  &.head-nav {
    max-width: calc(${({ theme }) => theme.containWidth});
    margin: 0 auto;
    height: 72px;
    z-index: 111;
    .nav-contain {
      display: flex;
      /* justify-content: space-between; */
      align-items: center;
      max-width: 1200px;
      padding: 0;
      margin: auto;
      > * {
        flex-shrink: 0;
      }
      .logoTit {
        /* width: 120px;
        height: 36px; */
        img {
          height:43px;
          /* width: 100%; */
        }
      }
      height: 72px;
      .menu {
        margin-top: 16px;
        border: none;
        background-color: transparent;
        display: flex;
        align-items: center;
        color: ${({ theme }) => theme.text6};

        > li {
          padding: 12px 0;
          margin-left: 80px;
          position: relative;
          .default-btn {
            outline: none;
            border: 1px solid transparent;
            text-align: center;
            color: white;
            text-decoration: none;
            display: flex;
            justify-content: center;
            flex-wrap: nowrap;
            align-items: center;
            cursor: pointer;
            position: relative;
            background-color: transparent;

            width: 98px;
            height: 38px;
            align-self: flex-start;
            background: ${({ theme }) => theme.text1};
            border-radius: 19px;
            color: #000;
          }
          svg {
            width: 15px;
            height: 15px;
          }
          :hover {
            .icon {
              margin-left: 10px;
              transition: all 0.5s;
              transform: rotate(-180deg);
            }
          }
          .menu-list-paper {
            display: flex;
            align-items: center;
            color: #fff;
            .icon {
              margin-left: 10px;
              transition: all 0.5s;
            }
            .middleArrow {
              display: none;
              width: 150px;
              height: 45px;
              background-color: none;
              position: absolute;
              top: 29px;
              left: 50%;
              margin-left: -75px;
              span {
                // display:none;
                background: #1c2129;
                width: 15px;
                height: 15px;
                position: absolute;
                bottom: 0;
                left: 50%;
                margin-left: -8px;
                transform: rotate(45deg);
              }
            }
          }

          .navLink {
            display: inline-block;
            font-size: 14px;
            font-weight: 600;
            color: #fff;

            &:hover,
            &:active {
              cursor: pointer;
              color: #18f195;
              line-height: 16px;
              background: linear-gradient(90deg, #18f195 0%, #1fb7ff 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
          .addColor {
            background: linear-gradient(90deg, #18f195 0%, #1fb7ff 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }
  }

  .nav-mobile-contain {
    padding: 0 24px;
    height: 100%;
    display: flex;
    align-items: center;
    > * {
      flex-shrink: 0;
    }
    justify-content: space-between;
    @media screen and (min-width: 1021px) {
      display: none;
    }

    > * {
      width: 40;
      flex-shrink: 0;
    }
    .menu-tag {
      cursor: pointer;
    }
  }

  .mobile-menu {
    color: #fff;
    @media screen and (min-width: 1021px) {
      display: none;
    }

    position: absolute;
    width: 220px;
    right: 0;
    top: 76px;
    z-index: 11;
    background-color: #1c2129;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);

    &::before {
      content: "";
      position: absolute;
      right: 24px;
      top: -10px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #1c2129;
    }

    li {
      position: relative;
      color: ${({ theme }) => theme.mobileHeaderHeight};
      height: 44px;
      color: ${({ theme }) => theme.mobileHeaderHeight};
      font-size: 14px;
      .new-icon {
        position: absolute;
        display: inline-block;
        width: 24px;
        height: 17px;
        top: 15px;
        left: 78px;
        // background: url(${newIcon}) no-repeat;
        background-size: 24px 17px;
      }

      &.selected {
        background-color: #2b3647;
        color: ${({ theme }) => theme.text1};
      }
      a {
        display: block;
        align-items: center;
        padding: 0 18px;
        height: 100%;
        color: inherit;
      }
      .menu-list-paper {
        color: #fff;
      }
    }
    .child-item {
      li {
        padding-left: 50px;
        position: relative;
        .hot-icon {
          position: absolute;
          display: inline-block;
          width: 29px;
          height: 28px;
          top: 0;
          right: 0;
          background: url(${hotIcon}) no-repeat;
          background-size: 29px 28px;
        }
      }
    }
  }
`;

export const TitleIcon = styled.img`
  width: 16px;
  height: 16px;
  display: block;
  margin-right: 8px;
`;
const NavHeader: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const path = location.pathname;
  const [show, setShow] = useState(false);
  const { account, loginState, accountloading, clearLogin } = useStarterWallet()
  const [bgOpacity, setBgOpacity] = useState<number>(0)
  const calcBgOpacity = useCallback(() => {
    const top = document.documentElement.scrollTop
    if (top >= 50) {
      let nav = document.getElementsByClassName("head-nav")[0] as any
      nav.style.background = "linear-gradient(180deg, rgba(216, 216, 216, 0.02) 0%, rgba(238, 238, 238, 0.03) 100%)"
      nav.style.backdropFilter = "blur(20px)"
      nav.style.webkitBackdropFilter = "blur(20px)"
      setBgOpacity(1)
    } else {
      let nav = document.getElementsByClassName("head-nav")[0] as any
      nav.style.background = "none";
      nav.style.backdropFilter = "none";
    }
  }, [bgOpacity])

  useEffect(() => {
    console.log(path.substring(0, 8))
  }, [path])

  useEffect(() => {
    document.addEventListener("scroll", calcBgOpacity)
    return () => {
      document.removeEventListener("scroll", calcBgOpacity)
    };
  }, [calcBgOpacity])

  useEffect(() => {
    const fun = () => {
      if (show) {
        setShow(false);
      }
    }
    document.addEventListener('click', fun)
    return () => document.removeEventListener('click', fun)
  }, [show])

  const onConnect = async () => {
    history.push("/login");
  };

  const onClickAccount = async () => {
    setShow(!show)
  }
  const marqueeClick = () => {
    history.push('/tdao')
  }
  const { t, i18n } = useTranslation()
  const marqueeTxt = t('You can claim a TSBT acknowledging your contribution by completing the task.')
  return (
    <StyledNav className="head-nav">
      {/* <Marquee duration={10} onClick={marqueeClick} content={marqueeTxt} /> */}
      <div className="nav-contain">
        <a href="/" className="logoTit">
          <img className="logo" alt="" src={i18n.language === 'jp' ? HeaderLogoJp : HeaderLogo}></img>
        </a>
        <div style={{marginTop:20}}>
        <LangIcon />
        </div>
        <ul className="menu">
          {/* <li>
            <span
              className={
                path.substring(0, 6) === "/login" ||
                  path.substring(0, 7) === "/wallet" ||
                  path.substring(0, 5) == "/send" ||
                  path.substring(0, 5) == "/with" ||
                  path.substring(0, 5) == "/depo"
                  ? "navLink addColor"
                  : "navLink"
              }
              onClick={() => {
                history.push("/wallet");
              }}
            >
              <span>{t('Wallet')}</span>
            </span>
          </li>
          <li>
            <span
              className={
                path === "/explorer" || path.substring(0, 5) === "/expl"
                  ? "navLink addColor"
                  : "navLink"
              }
              onClick={() => {
                history.push("/explorer");
              }}>
              <span>{t('Explorer')}</span>
            </span>
          </li>
          <li>
            <span
              className={path === "/faucet" ? "navLink addColor" : "navLink"}
              onClick={() => {
                history.push("/faucet");
              }}
            >
              <span>{t('Faucet')}</span>
            </span>
          </li> */}
          {/* <LaunchTdaoApp path={path} history={history}/> */}
          {/* <li>
            <span
              className={path === "/tdao" ? "navLink addColor" : "navLink"}
              onClick={() => {
                history.push("/tdao");
              }}
            >
              <span>{t('TDAO SBT')}</span>
            </span>
          </li> */}
          {/* <li className="addressModel">
            {loginState === LoginState.loginSuccess ? (
              <ButtonConnect onClick={onClickAccount} radius={20} border className={`${show ? "show" : "off"}`}>
                {accountloading ? <>
                  <strong>Pending..</strong> <LoadingIcon size={16} color="#fff" />
                </> : <><span>{hidehash(account)}</span><SanJiaoBox /></>}
                <SelectListsBox className={`options ${show ? "unfold" : ""}`}>
                  <SelectOptionsBox onClick={clearLogin}>
                    <OptionIcon src={DisconnectIcon} />
                    <span className="span">{t('Disconnect')}</span>
                  </SelectOptionsBox>
                </SelectListsBox>
              </ButtonConnect>
            ) : (
              <ButtonConnect radius={20} border onClick={onConnect}>
                <span>{t('Connect')}</span>
              </ButtonConnect>
            )}
          </li> */}
         
        </ul>
      </div>
    </StyledNav>
  );
}

export default NavHeader



const LaunchTdaoApp: React.FC<{ path: string, history: any }> = ({ path,history }) => {

  const { t } = useTranslation()
  const [show, setShow] = useState(false)
  useEffect(() => {

    const showfun = () => {
      setShow(true)
    }

    const closefun = () => {
      setShow(false)
    }

    document.getElementById('launch_list').addEventListener('mouseover', showfun)
    document.getElementById('launch_list').addEventListener('mouseleave', closefun)

    return () =>{
      
      document.getElementById('launch_list').removeEventListener('mouseleave', closefun)
      document.getElementById('launch_list').removeEventListener('mouseover', showfun)
    } 
  }, [show])
  
  return <LaunchAppBox show={show} id="launch_list">
    <span
      className={['/points','/tdao'].includes(path) ? "navLink addColor" : "navLink"}
      >{t('TDAO')}</span>
    {<LaunchAppListBox className={show ? 'hover':''} show={show}>
      <LaunchAppLine>
        <LaunchAppItem onClick={() => {
                history.push("/tdao");
                setShow(false)
              }}>
          TDAO SBT
        </LaunchAppItem>
        <LaunchAppItem onClick={(e) => {
                history.push("/points");
                setShow(false)
              }}>
         {t('Points Board')} 
        </LaunchAppItem>
      </LaunchAppLine>
    </LaunchAppListBox>}
  </LaunchAppBox>

  //   return <li>
  //   <span
  //     className={path === "/tdao" ? "navLink addColor" : "navLink"}
  //     onClick={() => {
  //       history.push("/tdao");
  //     }}
  //   >
  //     <span>{t('TDAO SBT')}</span>
  //   </span>
  // </li>
}
const OptionIcon = styled.img`
  width:12px;
  height:12px;
`
const SelectOptionsBox = styled.div`
padding:8px 13px; 
width:120px;

.span{
  margin-left:8px;
      font-weight: 400;
      color: #18f195;
      background: #000;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      
  }
`
const SelectListsBox = styled.div`
  position: absolute;
  z-index: 100;
  top: 38px;
  left: 5px;
  left:-2px;
  /* padding:13px; */
  overflow: hidden;
  background-color: #fff;
  color: #000;
  border-radius: 11px;
  /* width: 200px; */
  &.options {
    transition: all 0.3s;
    max-height: 0;
  }
  &.unfold {
    max-height: 150px;
  }
  
`;
const SanJiaoBox = styled.div`
  width:16px;
  height:16px;
  background-size:100% 100%;
  background-image:url(${UpRateIcon}) ;
`
const ButtonConnect = styled(CardHight)`
  width: 110px;
  height: 30px;
  position:relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-direction: column; */
  border-radius: 20px;
  cursor: pointer;
  user-select: none;
  background-color: rgba(24, 241, 149, 0.15);
  &.show {
    ${SanJiaoBox}{
      transform: rotate(0deg);
      transition: transform 0.3s;
    }
  }
  &.off {
    ${SanJiaoBox}{
      transform: rotate(180deg);
      transition: transform 0.3s;
    }
  }
 
  strong{
    font-size:12px;
    font-weight:400;
    color:#fff;
  }
  span {
    font-size: 12px;
    font-weight: 400;
    color: #18f195;
    background: linear-gradient(90deg, #18f195 0%, #1fb7ff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &:hover {
    background-color: #fff;
    -webkit-background-clip: text;
    background: linear-gradient(90deg, #18f195 0%, #1fb7ff 100%);

    span {
      font-size: 12px;
      font-weight: 400;
      color: #18f195;
      background: #000;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
`;

const LaunchAppListBox = styled.div<{ show: boolean }>`
  position:absolute;
  padding-top: 50px;
  top:0px ;
  left:-35px;
  ${props => !props.show ? 'display:none ;' : ''}
`

const LaunchAppBox = styled.li<{ show: boolean }>`
  position:relative;
 /* ${LaunchAppListBox}{
  display: none;
 }
 &.hover{
  ${LaunchAppListBox}{
    display:blo !important;
  }
 } */
`
const LaunchAppItem = styled.a`
  line-height:43px;
  display:block ;
  cursor: pointer;
  color:#fff;
  &:hover{
    color:#18F195 ;
    background-color:#252525;
  }
`
const LaunchAppLine = styled(Card)`
  width:140px;
  overflow:hidden ;
`

