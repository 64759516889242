import config, { ChainsItem } from "../config";
import { BigNumber, Contract, ethers, Overrides } from "ethers";
import ERC20 from "./ERC20";
import { getDefaultProvider } from "../utils/provider";
import { Bank } from "./poolsType";
import { Scalar } from "ffjavascript";
import Axios from "axios";
import { getGasPrice } from "../actions/onchain/utils";
import ERC721 from "./ERC721";
/**
 * An API module of bnb prediction contracts.
 * All contract-interacting domain logic should be defined in here.
 */
export class StarterCash {
  myAccount: string;
  provider: ethers.providers.Web3Provider;
  signer?: ethers.Signer;
  config: ChainsItem;
  contracts: { [name: string]: Contract };
  externalTokens: { [name: string]: ERC20 };
  tokens721: { [name: string]: ERC721 };
  newProvider: any;
  chainId:number;
  constructor(cfg: ChainsItem) {
    const { externalTokens, deployments, tokens721 } = cfg;
    const provider = getDefaultProvider(cfg.chainId);
    this.chainId = cfg.chainId;
    // loads contracts from deployments
    this.contracts = {};
    for (const [name, deployment] of Object.entries(deployments)) {
      this.contracts[name] = new Contract(
        deployment.address,
        deployment.abi,
        provider
      );
    }
    this.tokens721 = {};
    for (const [symbol, [address, decimal]] of Object.entries(tokens721)) {
      this.tokens721[symbol] = new ERC721(address, provider, symbol, decimal); // TODO: add decimal
    }
    this.externalTokens = {};
    for (const [symbol, [address, decimal]] of Object.entries(externalTokens)) {
      this.externalTokens[symbol] = new ERC20(
        address,
        provider,
        symbol,
        decimal
      );
    }

    this.config = cfg;
    this.provider = provider;
  }

  /**
   * @param provider From an unlocked wallet. (e.g. Metamask)
   * @param account An address of unlocked wallet account.
   */
  unlockWallet(provider: any, account: string) {
    const newProvider = new ethers.providers.Web3Provider(
      provider,
      this.config.chainId
    );

    this.signer = newProvider.getSigner(0);
    this.myAccount = account;
    for (const [name, contract] of Object.entries(this.contracts)) {
      this.contracts[name] = contract.connect(this.signer);
    }
    const tokens = [
      ...Object.values(this.externalTokens),
      ...Object.values(this.tokens721),
    ];
    for (const token of tokens) {
      token.connect(this.signer);
    }
    this.chainId === config.BSC.chainId &&
      console.log(`🔓 Wallet is unlocked. Welcome, ${account}!`);
  }

  get isUnlocked(): boolean {
    return !!this.myAccount;
  }

  async Withdraw(
    bank: Bank,
    numExitRoot,
    amount: any,
    siblings: any,
    babyPubKey: string[]
  ) {
    const pool = this.contracts[bank.contract];
    const provider = this.provider;
    const gasMultiplier = 1;

    const overrides = {
      gasLimit: 1000000,
      gasPrice: await getGasPrice(gasMultiplier, provider),
    };

    return await pool.withdraw(
      amount,
      numExitRoot,
      siblings,
      babyPubKey,
      bank.tokenId,
      overrides
    );
  }
  async DepositOnTop(
    bank: Bank,
    amount: any,
    ethAddress: string,
    babyPubKey: string[],
    rsaPubKey: string
  ) {
    const pool = this.contracts[bank.contract];
    const provider = this.provider;
    const gasMultiplier = 1;
    const feeOnchainTx = await pool.feeOnchainTx();

    const overrides = {
      gasLimit: 1000000,
      gasPrice: await getGasPrice(gasMultiplier, provider),
      value: Math.floor(Number(feeOnchainTx)*1.2).toString(),
    };
    // console.log({
    //   amount, sd:bank.tokenId, ethAddress, babyPubKey
    // })
    return await pool.depositOnTop(babyPubKey, amount, bank.tokenId, overrides);
  }

  async FaucetReceive(name:string,params: any) {
    const ShuiPool = this.contracts[name]
    console.log({ShuiPool,name})
    
    return name === 'ShuiPool' ? await ShuiPool.mintToken(params) : await ShuiPool.getToken(params)
  }
  async getPriceFun() {
    return fetch("https://api.binance.com/api/v3/ticker/price")
      .then((res) => res.json())
      .catch(() => {});
  }
  async getPrices() {
    const gasMultiplier = 1;
    const provider = this.provider;
    return (
      500000 *
      Number(
        ethers.utils.formatEther(await getGasPrice(gasMultiplier, provider))
      )
    );
  }

  async fetchNftId(account:any){
      const { NFTToken } = this.tokens721
      const balance = await NFTToken.balanceOf(account)
      if(balance.toNumber()){
        const num = (await NFTToken.tokenOfOwnerByIndex(account, 0)).toString()
        return num 
      }
      return null 
    
  }
  async NFTClaim(
    owner: string,
    msgHash: string,
    v: string,
    r: string,
    s: string
  ) {
    const { NFTToken } = this.tokens721;
    return NFTToken.userMintNFT(owner, msgHash, v, r, s, { gasLimit: 210000 });
  }
  async getDepositFee(bank: Bank){
    const pool = this.contracts[bank.contract]
    const feeDeposit = await pool.depositFee()
    return feeDeposit
  }
  async Deposit(
    bank: Bank,
    amount: any,
    ethAddress: string,
    babyPubKey: string[],
    rsaPubKey: string
  ) {
    const pool = this.contracts[bank.contract]
    const provider = this.provider
    const gasMultiplier = 1
    const feeOnchainTx = await pool.feeOnchainTx()
    const feeDeposit = await this.getDepositFee(bank)

    // console.log('--------in function deposit-------------');
    // console.log(rsaPubKey);
    let publicDer = Buffer.from(rsaPubKey, "hex");

    let buffer1 = Buffer.alloc(32);
    let buffer2 = Buffer.alloc(32);
    let buffer3 = Buffer.alloc(32);
    let buffer4 = Buffer.alloc(32);
    let buffer5 = Buffer.alloc(32);

    publicDer.copy(buffer1, 0, 0, 32);
    publicDer.copy(buffer2, 0, 32, 64);
    publicDer.copy(buffer3, 0, 64, 96);
    publicDer.copy(buffer4, 0, 96, 128);
    publicDer.copy(buffer5, 0, 128, 140);
    // console.log({
    //   amount, sd:bank.tokenId, ethAddress, babyPubKey,ss:[buffer1, buffer2, buffer3, buffer4, buffer5]
    // })
    const overrides = {
      gasLimit: 1000000,
      gasPrice: await getGasPrice(gasMultiplier, provider),
      value: `0x${(Math.floor(Number(Scalar.add(feeOnchainTx, feeDeposit)) * 1.2)).toString(16)}`
    };

    return pool.deposit(
      amount,
      bank.tokenId,
      ethAddress,
      babyPubKey,
      [buffer1, buffer2, buffer3, buffer4, buffer5],
      overrides
    );
  }
  async getErc20Balance(poolName: string): Promise<any> {
    // console.log("start");
    const pool = this.externalTokens[poolName];

    try {
      return pool.balanceOf(this.myAccount);
    } catch (e) {
      console.log(pool, poolName);
      console.error(e);
    }
  }
  gasOptions(gas: BigNumber): Overrides {
    const multiplied = Math.floor(
      gas.toNumber() * this.config.gasLimitMultiplier
    );
    console.log(`⛽️ Gas multiplied: ${gas} -> ${multiplied}`);
    return {
      gasLimit: BigNumber.from(multiplied),
    };
  }
}
