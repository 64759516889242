import React from "react";
import styled from "styled-components";
import LoadingIcon from "../Button/LoadingIcon";

export const Base = styled.div<{
  padding?: string;
  width?: string;
  borderRadius?: string;
  altDisabledStyle?: boolean;
}>`
  padding: ${({ padding }) => (padding ? padding : "9px 14px")};
  width: ${({ width }) => (width ? width : "100%")};
  border-radius: 4px;
  border-radius: ${({ borderRadius }) => borderRadius && borderRadius};
  > * {
    user-select: none;
  }
`;

interface CardHightProps {
  key?: any;
  onClick?:any;
  border?:boolean;
  bgcolor?:string;
  radius?:number;
  className?: string;
  children?:any;
}

const LoadPage: React.FC<CardHightProps> = ({ bgcolor,radius=11,className,onClick,border, children }) => {
  return (
    <LoadingBox>
      <LoadingIcon size={60} color="#fff"/>
    </LoadingBox>
  )
}

export default LoadPage

const LoadingBox=styled.div`
  display:flex;
  align-items:center;
  justify-content:center;
  height:380px;
`