import ERC20 from './ERC20';
export interface BankInfo {
  name: string;
  contract: ContractName;
  tokenId: number;
  depositTokenName: ContractName;
  earnTokenName: ContractName;
  sort: number;
  finished: boolean;
  fee:string;
  minVal:number,
  stepVal:number,
  display:boolean,
}

export interface Bank extends BankInfo {
  address: string;
  depositToken: ERC20;
  earnToken: ERC20;
  balance?: string;
}
export type Deployments = {
  [contractName: string]: {
    address: string;
    abi: any[];
  };
};

export type ContractName = string;

export interface BankInfo {
  name: string;
  contract: ContractName;
  depositTokenName: ContractName;
  earnTokenName: ContractName;
  sort: number;
  finished: boolean;
  version:VersionType
}


export enum VersionType {
  V1 = 'OLD',
  V2 = 'NEW'
}