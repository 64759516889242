import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
// import ReactGA from 'react-ga'
import MetamaskIcon from "../../assets/connect/Metamask@2x.png";
import WalletConnectIcon from "../../assets/connect/WalletConnect@2x.png";
import { injected, walletconnect } from "../../connectors";
import useStarterWallet, { LoginState } from "../../hooks/useStarterWallet";
import Input from "./components/Input";
import ShowPasswordIcon from "../../assets/connect/show_password@2x.png";
import ShowPasswordIcon2 from "../../assets/connect/show_password2@2x.png";
import OffPasswordIcon from "../../assets/connect/off_password@2x.png";
import OffPasswordIcon2 from "../../assets/connect/off_password2@2x.png";
import { ButtonPink } from "../../components/Button";
import { useHistory } from "react-router-dom";
import { CardHight } from "../../components/Card";
import { Spaced } from "../../components/Spaced";
import WalletBg from "../../assets/home/wallet_b.png";
import LoadPage from "../../components/LoadPage";
import Toast from "../../components/Toast";
import WelcomeTitle from "../Faucel/WelcomeTitle";
import { useTranslation } from "react-i18next";

// import { SUPPORTED_WALLETS } from "../../constants";
// import { injected,walletconnect } from "../../connectors";
export default function Index() {
  const { tryActivation, loadWallet, createKeystore, loginState } = useStarterWallet()
  const [inputType, setInputType] = useState<"password" | "text">("password")
  const [inputType1, setInputType1] = useState<"password" | "text">("password")
  // const [selectId, setSelectId] = useState<"Metamask" | "Wallet Connect">("Metamask");
  // const [walletView, setWalletView] = useState(WALLET_VIEWS.ACCOUNT)
  // const [pendingWallet, setPendingWallet] = useState<AbstractConnector | undefined>()
  // const [pendingError, setPendingError] = useState<boolean>()
  const [loading,setLoading] = useState(false)
  const [errorTip, setErrorTip] = useState("")
  const [errorTip1, setErrorTip1] = useState("")
  const history = useHistory()
  const [password, setPassword] = useState("")
  const [password1, setPassword1] = useState("")
  const {t} = useTranslation()
  const onChangeInput = (e: any) => {
    setErrorTip("")
    let { value } = e.target
    // const reg = /^-?\d*(\.\d*)?$/;
    // if ((!isNaN(value) && reg.test(value)) || value === '') {
    // if (value > Number(getDisplayBalance(pageobj.maxVal))) {
    //   value = Number(getDisplayBalance(pageobj.maxVal))
    // }
    setPassword(value)
    // }
  }

  const onChangeInput1 = (e: any) => {
    setErrorTip1("")
    let { value } = e.target
    // const reg = /^-?\d*(\.\d*)?$/;
    // if ((!isNaN(value) && reg.test(value)) || value === '') {
    // if (value > Number(getDisplayBalance(pageobj.maxVal))) {
    //   value = Number(getDisplayBalance(pageobj.maxVal))
    // }
    setPassword1(value)
    // }
  };

  const onBlur = () => {
    const [err0, err1] = ruleFn();
    setErrorTip(err0);
    setErrorTip1(err1);
  };

  const switchShowHide = () => {
    setInputType(inputType === "password" ? "text" : "password");
  };

  const switchShowHide1 = () => {
    setInputType1(inputType1 === "password" ? "text" : "password");
  };

  const ruleFn = () => {
    let err0 = ""
    let err1 = ""
    if (password.length < 8 || password.length > 20) {
      err0 = ("8~20 letters and numbers, case sensitive") 
    }
    if (!password1 || password1.length < 8 || password1.length > 20) {
      err1 = ("8~20 letters and numbers, case sensitive")
    }
    if (password && password1 && password !== password1) {
      err1 = ("Confirm password must match!")
    }
    return [err0, err1]
  }

  const startRegWallet = async () => {
    const [err0, err1] = ruleFn()
    setErrorTip(err0)
    setErrorTip1(err1)
    if(!err0 && !err1){
      setLoading(true)
      setTimeout(async () =>  (await createKeystore(password,setLoading)), 1000);
    }
  }

  const LoginWallet = () => {
    if(password){
      setLoading(true)
      setTimeout(() => loadWallet(password, loginSuccess, loginError,setLoading), 200);
    }
  }

  const onKeyDown = (e:any) => {
    if (e.keyCode === 13) {
      LoginWallet()
    }
  }

  const submitTryActivation = (item) => {
    // addPopup()
    tryActivation(item.connector)
  }

  const loginSuccess = () => {
    history.push("/wallet")
  }

  const loginError = (message: string) => {
    setErrorTip(message);
  }

  const selects = useMemo(() => {
    return [
      {
        connector: injected,
        title: "Metamask",
        icon: MetamaskIcon,
      },
      // {
      //   connector: walletconnect,
      //   title: "Wallet Connect",
      //   icon: WalletConnectIcon,
      // },
    ];
  }, [])

  useEffect(() => {
    setPassword("")
    setPassword1("")
    if(loginState === LoginState.loginSuccess){
      loginSuccess()
    }
  }, [loginState])
  
  // console.log({loginState,loading})
  
  return (
    <Wrapper>
       <ContainerWrapper>
        <WelcomeTitle/>
      {/* <PageTitleBox>Welcome to the Tusima Privacy Finance Network</PageTitleBox> */}
      <Spaced size="120" />
      {loginState === LoginState.loading?<LoadPage/>:loginState === LoginState.init ? (
       <PageInit  selects={selects} submitTryActivation={submitTryActivation}/> 
      ) : loginState === LoginState.noRegister ? (
        <PageNoRegister 
        errorTip={errorTip}
        onBlur={onBlur}
        inputType={inputType}
        switchShowHide={switchShowHide}
        password={password}
        password1={password1}
        onChangeInput={onChangeInput}
        onChangeInput1={onChangeInput1}
        errorTip1={errorTip1}
        inputType1={inputType1}
        switchShowHide1={switchShowHide1}
        loading={loading}
        startRegWallet={startRegWallet}
        />
      ) : (
       <PageLogIn 
       errorTip={errorTip}
       onKeyDown={onKeyDown}
       inputType={inputType}
       switchShowHide={switchShowHide}
       password={password}
       onChangeInput={onChangeInput}
       loading={loading}
       LoginWallet={LoginWallet}
       /> 
      )}
    </ContainerWrapper>
    </Wrapper>
  )
}

const PageInit:React.FC<{selects:any[],submitTryActivation:any}>=({selects,submitTryActivation})=>{
  const {t}= useTranslation()
  return <>
  <ButtonLists>
    {selects.map((item:any, key) => {
      return (
        <ButtonItem radius={20} onClick={() => submitTryActivation(item)} key={key}>
          <ButtonIcon src={item.icon} />
          <ButtonTit>{item.title}</ButtonTit>
        </ButtonItem>
      );
    })}
  </ButtonLists>
  <PageConnect>{t('Connect Layer1 Wallet to start')}</PageConnect>
</>
}
const PageNoRegister:React.FC<{
  errorTip:any,onBlur:any,inputType:any,
  switchShowHide:any,
  password:any,
  password1:any,
  onChangeInput:any,
  onChangeInput1:any,
  errorTip1:any,
  inputType1:any,
  switchShowHide1:any,
  loading:any,
  startRegWallet:any
}>=({
  errorTip,onBlur,
  inputType,switchShowHide,
  password,onChangeInput,errorTip1,
  inputType1,switchShowHide1,
  loading,password1,onChangeInput1,startRegWallet
})=>{
  const {t} = useTranslation()
  return <InterInputBox>
    <InterInputTit>{t('Set password')}</InterInputTit>
    <PasswordInput
      placeholder={t("Enter your password")}
      error={errorTip}
      onBlur={onBlur}
      endAdornment={
        <PasswordSwitchBox open={inputType === 'text'} onClick={switchShowHide}/>
      }
      value={password}
      type={inputType}
      onChange={onChangeInput}
    />
    <Spaced size="20"/>
    <PasswordInput
      placeholder={t("Confirm password")}
      error={errorTip1}
      onBlur={onBlur}
      endAdornment={
        <PasswordSwitchBox open={inputType1=== 'text'} onClick={switchShowHide1}/>
      }
      value={password1}
      type={inputType1}
      onChange={onChangeInput1}
    />
    <InterButton load={loading} onClick={startRegWallet}>{t('Enter')}</InterButton>
  </InterInputBox>
}

const PageLogIn:React.FC<{errorTip:any,
  onKeyDown:any,inputType:any,
  switchShowHide:any,password:any,
  onChangeInput:any,loading:any,
  LoginWallet:any
}>=({errorTip,onKeyDown,inputType,switchShowHide,password,onChangeInput,loading,LoginWallet})=>{
  const {t}=useTranslation()
  return <InterInputBox>
  <InterInputTit>{t('Login')}</InterInputTit>
  <PasswordInput
    error={errorTip}
    onKeyDown={onKeyDown}
    endAdornment={<PasswordSwitchBox open={inputType === 'text'} onClick={switchShowHide}/>}
    value={password}
    type={inputType}
    onChange={onChangeInput}
  />
  <InterButton load={loading} disabled={!password} onClick={LoginWallet}>{t('Unlock')}</InterButton>
</InterInputBox>
}
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-image:url(${WalletBg});
  background-size: cover;
  background-repeat:no-repeat;
`
const InterButton = styled(ButtonPink)`
  width: 180px;
  margin: 68px auto 0;
`;
const InterInputTit = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  line-height: 24px;
  margin-bottom: 27px;
`;
const InterInputBox = styled.div`
  width: 435px;
  margin: 0 auto;
`;
const PasswordSwitchBox = styled.div<{open:boolean}>`
  margin: 0 10px;
  cursor: pointer;
  width:24px;
  height:24px;
  background-size:100%;
  background-image: url(${props=>props.open ? ShowPasswordIcon:OffPasswordIcon});
  &:hover{
    background-image: url(${props=>props.open ? ShowPasswordIcon2:OffPasswordIcon2});
  }
`;
const PasswordSwitchImg = styled.img`
  width: 24px;
`;
const PasswordInput:React.FC<any>=(props)=>{
  return <PasswordInputSty bgcolor="rgba(255, 255, 255, 0.6)" {...props}/>
}

const PasswordInputSty = styled(Input)`
  padding-left: 20px;
`;
const ContainerWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 100px;
  color: #fff;
`;
const PageTitleBox = styled.div`
  font-size: 27px;
  font-family: Poppins;
  color: #ffffff;
  line-height: 52px;
  text-align: center;
  margin: 0;
`;
const PageConnect = styled.div`
  font-size: 18px;
  font-family: Poppins;
  color: #ffffff;
  line-height: 52px;
  text-align: center;
  margin-top:100px;
`;

const ButtonLists = styled.div`
  display: flex;
  justify-content: center;
  gap: 68px;
`;
const ButtonTit = styled.div`
  font-size: 11px;
`;
const ButtonIcon = styled.img`
  width: 38px;
`;
const ButtonItem = styled(CardHight)`
  gap: 24px;
  width: 137px;
  height: 141px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 20px;
  cursor: pointer;
  user-select: none;
  background-color: rgba(255, 255, 255, 0.1);
  &:hover {
    background-color: rgba(24, 241, 149, 0.15);
  }
`;
