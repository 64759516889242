import React, { useCallback, useEffect, useMemo, useState } from "react"

import styled from "styled-components"
import useStarterWallet, { LoginState } from "../../../hooks/useStarterWallet"
import { useHistory, useParams } from "react-router-dom"
import { CardHight } from "../../../components/Card"
import { SelectPrimary, SelectHighlights } from "../../../components/Select"
import { Spaced } from "../../../components/Spaced"
import Input from "../../Login/components/Input"
import { ButtonHighlights,ButtonPink } from "../../../components/Button"
import useBanks from "../../../hooks/useBanks"
import { Bank } from "../../../utils/poolsType"
import DownImg from "../../../assets/connect/down@2x.png"
import useSend from "../../../hooks/useSend"
import SuccessPage from "../../../components/SuccessPage"
import DiscordModel from "../../../components/DiscordModel"
import { number_format } from "../../../utils/utils"
import { TranscationStatus } from "../../../hooks/useHandleTransactionReceipt"
import Modal from "../../../components/Modal"
import WalletBg from "../../../assets/home/wallet_b.png"
import LoadPage from "../../../components/LoadPage"
import ValueSkeleton from "../../../components/ValueSkeleton"
import { postSbtReg } from "../../../utils/fetch/login"
import { Trans, useTranslation } from "react-i18next"

interface SubmitProps {
  bank: Bank;
  fee:string;
  setFee:any;
  amount: string;
  address: string;
  clearFn: any;
  onSuccess: any;
  disabled: boolean;
}

const SubmitButton: React.FC<SubmitProps> = ({
  bank,
  fee,
  setFee,
  amount,
  address,
  clearFn,
  onSuccess,
  disabled,
}) => {
  const { token } = useParams<any>()
  const [loading, setLoading] = useState(false)
  const [confirm,setConfirm] = useState(false)
  const [show, setShow] = useState(false)
  const { setAccountLoading,account,starterCash } = useStarterWallet()
  const {t}=useTranslation()
  const { onSend,getToEthAddr,fetchFeeVal,sendloading } = useSend(bank, (status: TranscationStatus, hash: any) => {
    if (TranscationStatus.confirm === status) {
      setConfirm(true)
    } else if (TranscationStatus.loading === status) {
      setLoading(true)
      setShow(false)
      setAccountLoading(true)
    } else if (TranscationStatus.success === status) {
      setLoading(false)
      clearFn()
      onSuccess(hash)
      const res = postSbtReg({
        addr: account,
        optype: 'transfer',
        txhash:hash,
        token:bank.tokenId
      })
      console.log('save...',res)
    } else if (TranscationStatus.error === status) {
      onSuccess(false)
      setConfirm(false)
    } else if (TranscationStatus.close === status) {
      setLoading(false)
      setConfirm(false)
    }
  })

  const onShow=async()=>{
    setLoading(true)
    const toAddr = await getToEthAddr(amount,address)
    if(toAddr){
      setLoading(false)
      setShow(true)
    }else{
      setLoading(false)
    }
  }

  const fetchUserFeeVal =async () => {
    const {feeval} = await fetchFeeVal(amount,address)
    setFee(feeval)
  }

  useEffect(()=>{
    fetchUserFeeVal().catch(console.error)
  },[address,amount])
  
  return (
    <>
      {show ? (
        <Modal onClose={() => setShow(false)}>
          <ModalBox onClick={(e) => e.stopPropagation()}>
            <ModalTitle>{t('Send')}</ModalTitle>
            <Spaced size="45" />
            <ModalValue>
            {amount} {bank.name}
            </ModalValue>
            <Spaced size="24" />
            <ModalDesc>
              <p>{t('To address')}:</p>
              <p>{address}</p>
            </ModalDesc>
            <SubmitButtonSty2
              disabled={confirm}
              load={loading}
              onClick={() =>{
                onSend(amount, address,fee)
              } }>
              {t('Confirm')}{confirm ? '...':''}
            </SubmitButtonSty2>
          </ModalBox>
        </Modal>
      ) :""}
      <SubmitButtonSty
        disabled={disabled || show || sendloading }
        load={loading}
        onClick={onShow}
      >
        {t(loading ? "Encrypted transaction sending" : "Send")}
      </SubmitButtonSty>
    </>
  );
};

const ContentBox: React.FC<{ onSuccess: any }> = ({ onSuccess }) => {
  const { allTokens, loginState, tokenComputeFee } = useStarterWallet()
  const { token } = useParams<any>()
  const { banks } = useBanks()
  const [address, setAddress] = useState("")
  // const [tokenId, setTokenId] = useState(token)
  const [tokenId, setTokenId] = useState(Number(token))
  const [amount, setAmount] = useState("")
  const [fee,setFee] = useState(null)

  const bank = useMemo(() => {
    // const item = banks.find((itm) => itm.depositToken.symbol === tokenId)
    const item = banks.find((itm) => itm.tokenId === tokenId)
    return item
  }, [tokenId,loginState])

  const { fetchFeeVal } = useSend(bank, ()=>{})
  const gasPrice = useMemo(() => {
    if(allTokens.length && bank){
      const item = allTokens.find((itm) => itm.tokenId === bank.tokenId)
      return item?.price || 0
    }
    return 0
  }, [amount, allTokens,bank])

  const onSetFee=(val: any)=>{
    console.log('update fee',val)
    setFee(val)
  }

  const gasNumber = useMemo(() => {
    if (amount && bank && fee) {
      const ss = tokenComputeFee(amount,bank)
      return ss
    }
    return '0.000001'
  }, [amount,address,bank,fee])

  const totalBalance = useMemo(() => {
    const number = allTokens.find((itm) => itm.tokenId === bank?.tokenId)?.number
    if(number === undefined)
      return null
    
    const num = number_format(number,6)
    return num
  }, [allTokens, bank,loginState])

  const tokensTotal = useMemo(() => {
    if (totalBalance && bank && fee) {
      const ss = tokenComputeFee(totalBalance, bank)
      return number_format(totalBalance - ss)
    }
    return totalBalance
  }, [allTokens, bank, totalBalance,fee])

  // const tokensTotal = useMemo(()=>{
  //   const total = allTokens.find(itm=>itm.tokenId === bank?.tokenId)?.number || 0
  //   return number_format(total,2)
  // },[allTokens,bank])

  const onChangeTokens = useCallback((id: any) => setTokenId(id),[tokenId])

  const onChangeInput = (e: any) => {
    let { value } = e.target
    const reg = /^-?\d*(\.\d*)?$/
    if ((!isNaN(value) && reg.test(value)) || value === "") {
      if (value > Number(tokensTotal)) {
        value = Number(tokensTotal)
      }
      const arr = String(value).split('.')
      if(arr.length === 2 && arr[1].length > 6){
        return
      }
      setAmount(value)
    }
  }

  const onChangeInputAds = (e: any) => {
    let { value } = e.target
    setAddress(value)
  }

  const onMax = useCallback(async () => {
    if (!totalBalance || !bank  || !address) {
      return
    }
    // const {feeval} = await fetchFeeVal(String(totalBalance),address)
    // console.log(feeval)
    // const ss = tokenComputeFee(totalBalance, feeval)
    // const max = number_format(totalBalance - ss)
    const max = tokensTotal
    onChangeInput({ target: { value: max } })
  }, [tokensTotal,address,totalBalance,bank])

  useEffect(()=>{
    const max = tokensTotal
    if(Number(max) < Number(amount)){
      onMax()
    }
  },[fee,tokensTotal,address,amount])
  
  
  const disabled = useMemo(() => !address || String(address).length !== 42 || !amount,[amount, address])
  const {t}=useTranslation()
  return (
    <>
      <CardBox bgcolor="#666">
        <CardLeft>
          <span>Tusima Layer 2</span>
          <Spaced size="21" />
          <SelectHighlights
            value={tokenId}
            onChange={onChangeTokens}
            options={banks.filter(itm=>itm.version === bank.version)}
          />
        </CardLeft>
        <CardRight>
          <UserInputLine>
            <CardInput
              noerr
              placeholder="0.00"
              onChange={onChangeInput}
              value={amount}
            />
          </UserInputLine>
          <Spaced size="11" />
          <UserInputLine>
            <BalanceSty>
              <span>{t('Balance')}: </span>
             {totalBalance === null ? <ValueSkeleton width={30}/> : <span>{totalBalance}</span>}
            </BalanceSty>
            <MaxBnt onClick={onMax}>{t('MAX')}</MaxBnt>
          </UserInputLine>
        </CardRight>
      </CardBox>
      <DownBox>
        <DownIcon src={DownImg} />
      </DownBox>
      {/* <Spaced size="45" /> */}
      <CardColumnBox className="row" bgcolor="#666">
        <CardLeft>
          <span>To</span>
        </CardLeft>
        <CardRight className="center">
          <InputAddress
            noerr
            placeholder="0x..."
            value={address}
            onChange={onChangeInputAds}
          />
        </CardRight>
      </CardColumnBox>
      <FeesBox>
        <div className="span" />
        {/* You can pay network fees in other tokens than {cnf.symbol}  */}
        <FeesRightBox>
          {bank ? (
            <FeesPrices>
              {t('Max fee')}: ~$
              {number_format(Number(gasPrice) * Number(gasNumber), 6)} |{" "}
              {number_format(String(gasNumber), 6)} {bank.name}
            </FeesPrices>
          ) : (
            ""
          )}
          
        </FeesRightBox>
      </FeesBox>
      {bank ? (
        <SubmitButton
          fee={fee}
          setFee={onSetFee}
          disabled={disabled}
          onSuccess={onSuccess}
          bank={bank}
          amount={amount}
          address={address}
          clearFn={() => {
            onChangeInput({ target: { value: "" } });
            onChangeInputAds({ target: { value: "" } });
          }}
        />
      ) : (
        ""
      )}
    </>
  )
}
const SendView = () => {
  const { loginState  } = useStarterWallet()
  const {t}=useTranslation()
  const [hash, setHash] = useState("");
  const history = useHistory()
  const toHref = useMemo(() => {
    return hash
  }, [hash])
  const onSuccess = (hash) => {
    setHash(hash)
  }
  useEffect(() => {
    loginState === LoginState.init && history.push("/login")
  }, [loginState])
  if(loginState === LoginState.loading){
    return <LoadPage/>
  }
  return (
    <Wrapper>
      <ContainerWrapper>
      <Spaced size="80"/>
        <DepositBox>
          <CardTitle>{t('Send')}</CardTitle>
          {hash ? (
            <SuccessPage nei to={toHref} hash={hash} />
          ) : (
            <ContentBox onSuccess={onSuccess} />
          )}
        </DepositBox>
        <Spaced size="100" />
        {/* <DiscordModel url="https://discord.com/channels/965918503070728203/1011838365110378617"></DiscordModel> */}
      </ContainerWrapper>
    </Wrapper>
  )
}
export default SendView
const Wrapper = styled.div`
  width: auto;
  height: 100%;
  background-image: url(${WalletBg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
`;

const ModalDesc = styled.div`
  font-size: 15px;
  font-weight: 400;
  color: #cccccc;
  line-height: 24px;
  text-align: center;
  p {
    padding: 0;
    margin: 0;
    line-height: 24px;
  }
`;
const ModalValue = styled.div`
  text-align: center;
  font-size: 27px;
  font-weight: 600;
  color: #ffffff;
  line-height: 27px;
`;
const ModalTitle = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  color: #cccccc;
  line-height: 18px;
`;
const ModalBox = styled.div``;
const InputAddress = styled(Input)`
  border: none;
  .input {
    font-size: 15px;
    font-weight: 500;
    color: #ffffff;
    line-height: 15px;
  }
`;
const FeesPrices = styled.div`
  margin-right: 12px;
`;
const FeesRightBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;
const DownBox = styled.div`
  text-align: center;
`;
const DownIcon = styled.img`
  width: 15px;
  margin: 15px;
`;
const CardTitle = styled.div`
  font-size: 15px;
  color: #ffffff;
  margin-bottom: 15px;
`;
const SubmitButtonSty2 = styled(ButtonPink)`
  width: 180px;
  font-size: 11px;
  font-weight: 600;
  color: #292929;
  margin: 45px auto 0;
`;
const SubmitButtonSty = styled(ButtonHighlights)`
  width: 508px;
  font-size: 11px;
  font-weight: 600;
  color: #292929;
  margin: 45px auto 0;
`;
const FeesBox = styled.div`
  margin: 0px 23px;
  margin-right: 11px;
  font-size: 10px;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  .span {
    width: 151px;
    height: 30px;
    font-size: 10px;
    font-family: Poppins-Regular, Poppins;
    font-weight: 400;
    color: #999999;
    line-height: 15px;
  }
`;
const MaxBnt = styled.div`
  margin-left: 10px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  color: #18f195;
  line-height: 12px;
  background: linear-gradient(90deg, #18f195 0%, #1fb7ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
const BalanceSty = styled.div`
  display: flex;
  align-items: center;
`;
const UserInputLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const CardInput = styled(Input)`
  border: none;
  .input {
    font-size: 27px;
    font-weight: 600;
    color: #ffffff;
    line-height: 27px;
    text-align: right;
  }
`;

const CardLeft = styled.div`
  display: flex;
  flex-direction: column;
  span {
    font-size: 10px;
    font-weight: 600;
    color: #fff;
  }
`;
const CardRight = styled.div`
  &.center {
    /* margin-top: 15px; */
    font-size: 15px;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
  }
`;
const CardBox = styled(CardHight)`
  display: flex;
  justify-content: space-between;
  padding: 28px;
  align-items: center;
  min-height: 81px;
`;
const CardColumnBox = styled(CardHight)`
  padding: 28px;
  align-items: center;
  min-height: 81px;
`;
const ContainerWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 56px;
  color: #fff;
`;
const DepositBox = styled.div`
  width: 510px;
  margin: 0 auto;
`;
