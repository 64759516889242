import Web3 from 'web3';

export function web3ProviderFrom(endpoint: string, ): any {

  const providerClass = endpoint.includes('wss')
    ? Web3.providers.WebsocketProvider
    : Web3.providers.HttpProvider;

  return new providerClass(endpoint, {
    timeout: 1000,
  });
}
