import {externalTokens as externalTokensBSC,deployments as deploymentsBSC,tokens721 as tokens721BSC ,bankDefinitions as bankDefinitionsBSC}from './utils/pools_BSC'
import {externalTokens as externalTokensETH,deployments as deploymentsETH,tokens721 as tokens721ETH,bankDefinitions as bankDefinitionsETH }from './utils/pools_ETH'
import {externalTokens as japan_externalTokensETH,deployments as japan_deploymentsETH,tokens721 as japan_tokens721ETH,bankDefinitions as japan_bankDefinitionsETH }from './utils/pools_ETH_japan'
import {externalTokens as local_externalTokensETH,deployments as local_deploymentsETH,tokens721 as local_tokens721ETH,bankDefinitions as local_bankDefinitionsETH }from './utils/pools_ETH_local'
import {BankInfo, Deployments} from './utils/poolsType'

export type ChainsItem = {
  chainId: any,
  symbol:string,
  smallChain:string,
  chainName:string,
  fullChainName:string,
  etherscanUrl: string,
  defaultProvider: string,
  operatorUrl:string,
  operatorUrl2:string,
  faucelUrl:string,
  deployments:Deployments,
  bankDefinitions:BankInfo[];
  externalTokens: {[contractName: string]: [string, number]};
  tokens721: {[contractName: string]: [string, number]};
  gasLimitMultiplier: number;
}
export type Configuration = {
  apiUrl:string,
  BSC:ChainsItem,
  ETH:ChainsItem,
  fee:string,
  baseLaunchDate: Date,
  refreshInterval: number;
};
const configurations: { [env: string]: Configuration } = {
  development: {
    apiUrl:'',
    BSC:{
      chainId: 3,
      symbol:"BNB",
      smallChain:"BSC",
      chainName:"Ropsten",//Ropsten
      fullChainName:"Ethereum Ropsten",//Ropsten
      etherscanUrl: 'https://ropsten.etherscan.io',
      defaultProvider: 'https://ropsten.infura.io/v3/db09ce2475bc4581bc02e96256352c32',
      operatorUrl:"http://148.153.184.139:9000",
      operatorUrl2:"http://148.153.184.139:9000",
      faucelUrl:'https://faucetlink.to/ropsten',
      externalTokens:externalTokensBSC,
      deployments:deploymentsBSC,
      tokens721:tokens721BSC,
      bankDefinitions:bankDefinitionsBSC,
      gasLimitMultiplier: 1.1,
    },
    ETH:{
      chainId: 5,
      symbol:"ETH",
      smallChain:"ETH",
      chainName:"Goerli",//Goerli
      fullChainName:"Goerli Chain",//Goerli
      etherscanUrl: 'https://goerli.etherscan.io',
      defaultProvider: 'https://eth-goerli.g.alchemy.com/v2/ahbi53pB-Nc_1JrxOA9cJRnwVi-GHbDd',
      operatorUrl:"http://164.52.53.164:9000",
      operatorUrl2:"http://164.52.53.164:9000",
      faucelUrl:'https://faucetlink.to/goerli',
      externalTokens:externalTokensETH,
      deployments:deploymentsETH,
      bankDefinitions:bankDefinitionsETH,
      tokens721:tokens721ETH,
      gasLimitMultiplier: 1.7,
    },
    fee:'0.001%',
    baseLaunchDate: new Date('2020-11-26T00:00:00Z'),
    refreshInterval: 10000,
  },
  production: {
    apiUrl:'',
    BSC:{
      chainId: 3,
      symbol:"BNB",
      smallChain:"BSC",
      chainName:"Ropsten",//Goerli
      fullChainName:"Ethereum Ropsten",//Goerli
      etherscanUrl: 'https://ropsten.etherscan.io',
      defaultProvider: 'https://ropsten.infura.io/v3/db09ce2475bc4581bc02e96256352c32',
      operatorUrl:"/operator-ropsten",
      operatorUrl2:"http://148.153.165.20:9000",
      faucelUrl:'https://faucetlink.to/ropsten',
      externalTokens:externalTokensBSC,
      deployments:deploymentsBSC,
      bankDefinitions:bankDefinitionsBSC,
      tokens721:tokens721BSC,
      gasLimitMultiplier: 1.7
    },
    ETH:{
      chainId: 5,
      symbol:"ETH",
      smallChain:"ETH",
      chainName:"Goerli",//Goerli
      fullChainName:"Ethereum Goerli",//Goerli
      etherscanUrl: 'https://goerli.etherscan.io',
      defaultProvider: 'https://eth-goerli.g.alchemy.com/v2/ahbi53pB-Nc_1JrxOA9cJRnwVi-GHbDd',
      operatorUrl:"/operator-goerli",
      operatorUrl2:"http://164.52.53.164:9000",
      faucelUrl:'https://faucetlink.to/goerli',
      externalTokens:externalTokensETH,
      deployments:deploymentsETH,
      bankDefinitions:bankDefinitionsETH,
      tokens721:tokens721ETH,
      gasLimitMultiplier: 1.7,
    },
    fee:'2%',
    baseLaunchDate: new Date('2020-11-26T00:00:00Z'),
    refreshInterval: 10000,
   
  },
  japan_production: {
    apiUrl:'',
    BSC:{
      chainId: 3,
      symbol:"BNB",
      smallChain:"BSC",
      chainName:"Ropsten",//Goerli
      fullChainName:"Ethereum Ropsten",//Goerli
      etherscanUrl: 'https://ropsten.etherscan.io',
      defaultProvider: 'https://ropsten.infura.io/v3/db09ce2475bc4581bc02e96256352c32',
      operatorUrl:"/operator-ropsten",
      operatorUrl2:"http://148.153.165.20:9000",
      faucelUrl:'https://faucetlink.to/ropsten',
      externalTokens:externalTokensBSC,
      deployments:deploymentsBSC,
      bankDefinitions:bankDefinitionsBSC,
      tokens721:tokens721BSC,
      gasLimitMultiplier: 1.7
    },
    ETH:{
      chainId: 5,
      symbol:"ETH",
      smallChain:"ETH",
      chainName:"Goerli",//Goerli
      fullChainName:"Ethereum Goerli",//Goerli
      etherscanUrl: 'https://goerli.etherscan.io',
      defaultProvider: 'https://eth-goerli.g.alchemy.com/v2/ahbi53pB-Nc_1JrxOA9cJRnwVi-GHbDd',
      operatorUrl:"/operator-goerli",
      operatorUrl2:"http://164.52.53.164:9000",
      faucelUrl:'https://faucetlink.to/goerli',
      externalTokens:japan_externalTokensETH,
      deployments:japan_deploymentsETH,
      bankDefinitions:japan_bankDefinitionsETH,
      tokens721:japan_tokens721ETH,
      gasLimitMultiplier: 1.7,
    },
    fee:'2%',
    baseLaunchDate: new Date('2020-11-26T00:00:00Z'),
    refreshInterval: 10000,
  },
  local_production: {
    apiUrl:'',
    BSC:{
      chainId: 3,
      symbol:"BNB",
      smallChain:"BSC",
      chainName:"Ropsten",//Goerli
      fullChainName:"Ethereum Ropsten",//Goerli
      etherscanUrl: 'https://ropsten.etherscan.io',
      defaultProvider: 'https://ropsten.infura.io/v3/db09ce2475bc4581bc02e96256352c32',
      operatorUrl:"/operator-ropsten",
      operatorUrl2:"http://148.153.165.20:9000",
      faucelUrl:'https://faucetlink.to/ropsten',
      externalTokens:externalTokensBSC,
      deployments:deploymentsBSC,
      bankDefinitions:bankDefinitionsBSC,
      tokens721:tokens721BSC,
      gasLimitMultiplier: 1.7
    },
    ETH:{
      chainId: 820444,
      symbol:"FSC",
      smallChain:"FSC",
      chainName:"Goerli",//Goerli
      fullChainName:"Ethereum Goerli",//Goerli
      etherscanUrl: 'http://101.251.205.214:8620/explorer',
      defaultProvider: '/testnet',
      operatorUrl:"/operator-goerli",
      operatorUrl2:"http://103.210.160.245:9000",
      faucelUrl:'https://faucetlink.to/goerli',
      externalTokens:local_externalTokensETH,
      deployments:local_deploymentsETH,
      bankDefinitions:local_bankDefinitionsETH,
      tokens721:local_tokens721ETH,
      gasLimitMultiplier: 1.7,
    },
    fee:'2%',
    baseLaunchDate: new Date('2020-11-26T00:00:00Z'),
    refreshInterval: 10000,
  }
};


export default configurations[process.env.REACT_APP_ENV || "development"];
