import en from './en.json'
import jp from './jp.json'
import cn from './cn.json'
const resources = {
    en: {
        translation: en
    },
    jp: {
        translation: jp
    },
    cn: {
        translation: cn
    }
}

export default resources;