import React from "react";
import styled from "styled-components";
import CircleStatus from "./circleStatus";
import { hideAddress, hideMount, number_format } from "../../../utils/utils";
import { dateFormat, formatListType } from "../../../utils/tools";
import {Trans} from  "react-i18next";

const mytransaction = [
  {
    title: "Hash",
    dataIndex: "hash",
    render: (record: any) => <>{hideAddress(record)}</>,
  },
  {
    title: "From",
    dataIndex: "fromEthAddr",
    render: (record: any) => <>{hideAddress(record)}</>,
  },
  {
    title: "To",
    dataIndex: "toEthAddr",
    render: (record: any) => <>{hideAddress(record)}</>,
  },
  {
    title: "Type",
    dataIndex: "type",
    render: (record: any) => <Trans>{formatListType(record)}</Trans>,
  },
  {
    title: "Amount",
    dataIndex: "amount",
    render: (record: any, params: any) => {
      return !params.isDecry  ? hideMount(record):`${number_format(record,6)} ${params?.coin}` 
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (record: any) => (
      <>
        <CircleStatus status={record}></CircleStatus>
        <Trans>{record}</Trans>
      </>
    ),
  },
  {
    title: "Executed At",
    dataIndex: "executed_at",
    render: (record: any) => dateFormat(record, "lists"),
  },
];

export default mytransaction;
// eslint-disable-next-line
const Block = styled.div`
  color: #18f195;
`;
