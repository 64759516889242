import i18next from 'i18next'
import {initReactI18next} from 'react-i18next'
import XHR from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import resources from './locales/resources'


let lang =(navigator.language || navigator.browserLanguage).toLowerCase();

i18next.use(XHR).use(LanguageDetector).use(initReactI18next).init({
    fallbackLng: window.localStorage.getItem('i18nextLng') || 'en',
    lng: window.localStorage.getItem('i18nextLng') || 'en',
    debug: false,
    resources: resources,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
    // backend: {
    //     loadPath:'/locales/{{lng}}.json'
    // },
    // react: {
    //     useSuspense: false
    // },
    // lng: lang.indexOf('zh')>=0 ? 'zh':'en',
    // preload: [
    //     'en','zh',
    // ],
    // keySeparator: false,
    // interpolation: {
    //     escapeValue: false
    // }
})

export default i18next