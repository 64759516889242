import { useCallback, useEffect, useMemo, useState } from "react";
import { parseUnits } from "ethers/lib/utils";
import useStarterWallet from "./useStarterWallet";
import { TranscationStatus } from "./useHandleTransactionReceipt";
import { Bank } from "../utils/poolsType";
import {
  getAccountencPubKey,
  sendTx,
  showAccounts,
  showStateAccount,
} from "../utils/cli-utils";
import { fee as feeTable } from "../js/constants";
import conversionJson from "../js/conversion.json";
import { ethers } from "ethers";
import Toast from "../components/Toast";
import { useTranslation } from "react-i18next";

const Scalar = require("ffjavascript").Scalar;
const utils = require("../js/utils");

const useSend = (bank: Bank, setLoading: any) => {
  const {
    starterCash,
    wallet,
    setAccountLoading,
    account: ethAddress,
    rollupWallet,
    getSignStr,
    operatorUrl,
    ethPrice,
  } = useStarterWallet();
  // const [ethPrice, setEthPrice] = useState(null)
  const [addressCache, setAddressCache] = useState({})
  const [depositFee, setDepositFee] = useState(null)
  const [recipient, setRecipient] = useState(null)
  const {t}=useTranslation()
  const loading = useMemo(() => !depositFee || !ethPrice, [depositFee, ethPrice])

  const getToEthAddr = async (amount: string, toEthAddr: string) => {
    if (!amount || !toEthAddr || depositFee === null || ethPrice === null) {
      return
    }
    try {
      const { feeval, recipient } = await fetchFeeVal(amount, toEthAddr)
      if (feeval === null) {
        const minVal = getMinVal()
        Toast.pending(`${t('Minimum Send amount')}:${minVal} ${bank.name}`, 10000)
        return
      }
      setRecipient(recipient)
      return true;
    } catch (e) {
      Toast.pending(e.message, 10000)
      return false
    }
  };

  const fetchAddress = async (toEthAddr: string) => {
    try {
      const str = addressCache[String(toEthAddr).toLocaleLowerCase()]
      if (str) {
        return str
      }
      const filters = { ethAddr: toEthAddr }
      const [leaf] = await showAccounts(operatorUrl, filters)
      const recipient = leaf.rollupAddress
      const pubkeyres = await getAccountencPubKey(operatorUrl, recipient).catch(() => { })
      if (!pubkeyres) {
        throw new Error(t("The address has not yet deposited"))
        // throw new Error('This address is not registered for Tusima account')
      }
      const coinres = await showStateAccount(operatorUrl, bank.tokenId, leaf.ax, leaf.ay).catch(console.error)
      addressCache[String(toEthAddr).toLocaleLowerCase()] = [!!coinres, recipient]
      setAddressCache(addressCache)
      return [!!coinres, recipient]
    } catch (e) {
      // throw new Error('This address is not registered for Tusima account')
      throw new Error(t("The address has not yet deposited"))
    }
  }

  const fetchFeeVal = async (amount: string, toEthAddr: string) => {
    if (!amount || !toEthAddr || depositFee === null || ethPrice === null || !bank) {
      return
    }
    let feeval = null
    let recipient = null
    if (toEthAddr.length === 42) {
      // try {
      const [coinres, recipient] = await fetchAddress(toEthAddr)
      if (!coinres) {
         const amountBn = parseUnits(String(amount), bank.depositToken.decimal)
        throw new Error(t("The address has not yet deposited"))
        //注释掉已注册未激活地址功能
        feeval = getFeeVal({ amount: amountBn, coin: bank.tokenId })
      } else {
        feeval = bank.fee
      }
      return {
        feeval,
        recipient
      }
      // } catch (e) {

      // }
    }
    return {
      feeval,
      recipient
    }

  }

  const handleSend = useCallback(async (amount: string, toEthAddr: string, fee: string) => {
    if (!amount || !toEthAddr || recipient === null) {
      return;
    }
    setLoading(TranscationStatus.confirm)
    const [signStr] = await getSignStr()
    if (!signStr) {
      setLoading(TranscationStatus.error)
      return;
    }
    setLoading(TranscationStatus.loading)
    const amountBn = parseUnits(String(amount), bank.depositToken.decimal)
    const res = await sendTx(
      operatorUrl,
      recipient,
      amountBn,
      rollupWallet,
      bank.tokenId,
      feeTable[fee],
      toEthAddr,
      ethAddress
    );
    console.log(res,`Status: 200, Nonce: ${res?.nonce}, txHash: ${res?.txHash}`);
    if (res?.txHash) {
      setAccountLoading(true);
      setLoading(TranscationStatus.success, res.txHash);
    } else {
      setLoading(TranscationStatus.close);
    }
  },
    [
      starterCash,
      ethAddress,
      rollupWallet,
      wallet,
      operatorUrl,
      depositFee,
      recipient,
    ]
  );

  const isConversion = (feeTx: any, convRate: { price: number; decimals: any }) => {
    const num = Scalar.mul(feeTx, Math.floor(convRate.price * 2 ** 64))
    const den = Scalar.pow(10, convRate.decimals)
    const normalizeFeeTx = Number(Scalar.div(num, den)) / 2 ** 64
    if (normalizeFeeTx > (depositFee *Number(ethPrice) )) {
      return {
        isOk: true,
        normalizeFeeTx,
      }
    }
    return {
      isOk: false,
      normalizeFeeTx
    }
  }

  const getMinVal = () => {
    if (depositFee === null || ethPrice === null) return ""
    let minVal = bank.minVal

    for (let index = 0; index < 100; index++) {
      const amountBn = parseUnits(String(minVal), bank.depositToken.decimal)
      const feeval = getFeeVal({ amount: amountBn, coin: bank.tokenId })
      if (feeval) {
        return minVal
      }
      minVal += bank.stepVal
    }
    return null
  }

  const getFeeVal = (tx: { amount: any; coin: string | number }) => {
    if (depositFee === null || ethPrice === null || !tx.amount) return ""
    // console.log(Object.entries(feeTable))
    for (const [name, value] of Object.entries(feeTable)) {
      const feeTx = utils.computeFee(tx.amount, value)
      const convRate = conversionJson[tx.coin]
      const { isOk, normalizeFeeTx } = isConversion(feeTx, convRate)
      if (isOk) {
        if(['wBNB','wETH'].includes(bank.name)){
          if(value >=6){
            return name
          }
        }else{
          return name
        }
      }
    }
    return null
  };

  const fetchDepositFee = async () => {
    const fee = ethers.utils.formatEther(await starterCash.getDepositFee(bank))
    setDepositFee(fee)
  }

  useEffect(() => {
    bank && starterCash && fetchDepositFee().catch(console.error)
  }, [bank, starterCash])

  // useEffect(() => {
  //   fetchEthPrice().catch(console.error)
  // }, [])

  return {
    onSend: handleSend,
    getToEthAddr,
    fetchFeeVal,
    sendloading: loading,
  }
}

export default useSend;
