import React, { useMemo } from "react"

import styled from "styled-components"
import PageLeftIcon from '../../assets/tdao/page_left@2x.png'
import PageRightIcon from '../../assets/tdao/page_right@2x.png'

const PointsListsPagesItem: React.FC<{ page: number, active: boolean, onClick?: any }> = ({ onClick, page, active }) => {
	return <PointsListsPagesItemBox className={active ? 'active' : ''} onClick={onClick}>
		{page}
	</PointsListsPagesItemBox>
}

const PointsListsPagesIcon: React.FC<{ Icon: any, onClick?: any }> = ({ Icon, onClick }) => {
	return <PointsListsPagesIconBox onClick={onClick}>
		<PointsListsPagesIconImg src={Icon} />
	</PointsListsPagesIconBox>
}

const Pagination: React.FC<{ page: number, total: number, onChange?: any, pageSize?: number }> = ({
	page, total, onChange, pageSize = 10
}) => {
	const totalPage = useMemo(()=>{
		const totalPage = Math.ceil(total / pageSize)
		return totalPage
	},[total,pageSize])
	const listArr = useMemo(() => {
		let pageArr = []
		const groupCount = 5;
		const currentPage = page
		
		let startPage = currentPage - 2 > 0 ? currentPage - 2 : currentPage
		if (totalPage <= groupCount) {
			for (let i = 1; i <= totalPage; i++) {
				pageArr.push(i)
			}
		} else {
			pageArr.push(1)
			let pageLength = 0;
			if (groupCount + startPage >= totalPage) {
				pageLength = totalPage;
			} else {
				pageLength = groupCount + startPage;

			}
			if (currentPage >= groupCount) {
				pageArr.push('...')
			}
			for (let i = startPage; i < pageLength; i++) {
				if (i <= totalPage - 1 && i > 1) {
					pageArr.push(i)
				}
			}
			if (totalPage - startPage >= groupCount + 1) {
				pageArr.push('...')
			}
		}
		return pageArr
	}, [total, page,totalPage])

	return <PointsListsPagesBox>
		{listArr.length > 1 && page > 1 ? <PointsListsPagesIcon onClick={() => onChange(page - 1)} Icon={PageLeftIcon} /> : ''}
		<PointsListsPagesConBox>
			{listArr.map((i, k) => <PointsListsPagesItem onClick={() => i === '...' ? '' : onChange(i)} key={k} page={i} active={i === page} />)}
		</PointsListsPagesConBox>
		{listArr.length > 1 && page < totalPage ? <PointsListsPagesIcon onClick={() => onChange(page + 1)} Icon={PageRightIcon} /> : ''}
	</PointsListsPagesBox>
}

const PointsListsPagesConBox = styled.div`
display: flex;
background: rgba(255,255,255,0.1);
margin: 0 20px;
border-radius: 12px;
`
const PointsListsPagesIconImg = styled.img`
  width: 18px;
`
const PointsListsPagesItemBox = styled.div`
user-select: none;
  margin: 0 2px;
  min-width: 40px;
  height: 40px;
  padding:0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  &.active{
    background: #18F195;
    color: #000;
  }
`
const PointsListsPagesIconBox = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(255,255,255,0.1) ;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: .78;
  cursor: pointer;
  &:hover{
    opacity: 1;
  }
`
const PointsListsPagesBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export default Pagination





