import httpUrl from '../axios'
export function getSbtScoreLog(params: any,baseURL?:string):any { 
  return httpUrl.get("/api/v1/sbt/score_log", params,baseURL)
}
export function getSdtScoreAllLog(params: any,baseURL?:string):any { 
  return httpUrl.get("/api/v1/sbt/score_all_log", params,baseURL)
}
export function getDiscordCallbackUrl(params: any,baseURL?:string):any { 
  return httpUrl.post("/api/v1/discord/callback", params,baseURL)
}
export function getTwitterCallbackUrl(params: any,baseURL?:string):any { 
  return httpUrl.post("/api/v1/twitter/callback", params,baseURL)
}
export function getTwitterAuthUrl(params: any,baseURL?:string):any { 
  return httpUrl.get("/api/v1/twitter/auth_url", params,baseURL)
}
export function getDiscordAuthUrl(params: any,baseURL?:string):any { 
  return httpUrl.get("/api/v1/discord/login_url", params,baseURL)
}
export function getDiscordInfo(params: any,baseURL?:string):any { 
  return httpUrl.get("/api/v1/discord/get_info", params,baseURL)
}
export function getServerStatus(params: any):any { 
  return httpUrl.get("/api/v1/status", params)
}

export function getNonce(params: any):any { 
  return httpUrl.get("/api/v1/wallet/get_nonce", params)
}
export function getSbtmintCheck(account:string):any{
  //register
  return httpUrl.get(`/api/v1/sbtmint_check?addr=${account}`)
}
export function postBbtMint(data: any):any{
  //register
  return httpUrl.post("/api/v1/sbt_mint",data)
}
export function getSbtcontractsign(ethAddress: any):any{
  //register
  return httpUrl.get(`/api/v1/sbtcontract_sign?addr=${ethAddress}`)
}
export function postSbtReg(data: any):any{
  //register
  return httpUrl.post('/api/v1/sbt_reg',data)
}
export function getSbtProfile(nftId:string):any{
  //register
  return httpUrl.get(`/api/v1/sbt_profile?token=${nftId}`)
}
export function getTokensPrices(data:any):any{
  //register
  return httpUrl.get("/api/v1/price",data)
}
export function signMsg(data:any):any{
  //register
  return httpUrl.post("/api/v1/wallet/authentication",data)
}

export function regKeystore(data:any):any{
  //register
  return httpUrl.post("/api/v1/wallet/register",data)
}

export function getBatchIds(params:any):any{
  return httpUrl.get("/api/v1/wallet/get_batchId",params)
}

export function postBatchIds(params:any){
  return httpUrl.post("/api/v1/wallet/save_batchId",params)
}

export function login(data:any){
  //login
  return httpUrl.post("/api/login/",data)
}

export function logout(){
   //logout
  return httpUrl.get("/api/logout/")
}



export function getLogin(data: any) {
  return httpUrl.post("/api/oauth/login/", data)
}

export function getBastet(params: any) { 
  return httpUrl.get("/user/get-comps", params)
}