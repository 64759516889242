import React from "react";
import styled from "styled-components";

const ContainerWrapper = (props: any) => {
  return <Wrapper padding={props.padding}>{props.children}</Wrapper>;
};

export default ContainerWrapper;

const Wrapper = styled.div<{ padding?: string }>`
  max-width: 1200px;
  margin: 0 auto;
  padding: ${({ padding }) => (padding ? padding : "60px 0 100px")};
  color: #fff;
  background-color: #000;
  /* height: 100%; */
  .ant-spin {
    position: absolute;
    top: 40%;
    left: 50%;
    svg {
      color: #18f195 !important;
    }
  }
`;
