import { useCallback, useMemo } from "react";
import useStarterWallet from "./useStarterWallet";
import useHandleTransactionReceipt, { TranscationStatus } from "./useHandleTransactionReceipt";
import { WebFetch } from "../utils/tools";
import Toast from "../components/Toast";
import { getSbtcontractsign } from "../utils/fetch/login";

const useNFTCliam = (setLoading:any) => {
  const {
    starterCash,
    allTokens,
    wallet,
    account: ethAddress,
    rollupWallet,
    getSignStr
  } = useStarterWallet()
  const handleTransactionReceipt = useHandleTransactionReceipt();

  const handleCliam = useCallback(async () => {
      setLoading(TranscationStatus.loading)
      // const [signStr,msgHash] = await getSignStr()
      // if(!signStr){
      //   setLoading(TranscationStatus.error)
      //   return
      // }
      // const {code,data} =await WebFetch(`/api/v1/sbtcontract_sign?addr=${ethAddress}`)
      const {code,data} = await getSbtcontractsign(ethAddress)
      if(code === 200){
        const {msg_hash,v,s,r} = data
        // const msgHash = '0xede1e42d3704dbf050edb4f7722fdb111bfe66ac963abad1ab671b0c24ecdd0d'
        // const v = '0x1b'
        // const s = '0x02d7a3dc9e95648b5fdd3050238ab0114dc3c67d094182634f3cf359306fb183'
        // const r = '0x7058a9c0492c90fae08a5cfe598858c749aaa0485d96137800a17bd5a9227f33'
        handleTransactionReceipt(
            starterCash.NFTClaim(ethAddress,msg_hash,v,r,s),
            setLoading
        )
      }else{
        setLoading(TranscationStatus.error) 
        Toast.error('error')
      }
      
    },
    [starterCash, ethAddress, rollupWallet, wallet]
  );
  return { onNFTCliam: handleCliam };
};

export default useNFTCliam;
