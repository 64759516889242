import Toast from '../components/Toast';
import config from '../config';

/* eslint-disable no-console */
const { send } = require('../actions/offchain/send');
const { deposit } = require('../actions/onchain/deposit.js');
const { depositOnTop } = require('../actions/onchain/deposit-on-top');
const { withdraw } = require('../actions/onchain/withdraw.js');
const { forceWithdraw } = require('../actions/onchain/force-withdraw.js');
const { transfer } = require('../actions/onchain/transfer.js');
const { depositAndTransfer } = require('../actions/onchain/deposit-and-transfer.js');
const {CliExternalOperator} = require('../js/cliExternalOperator');
const { Wallet } = require('./wallet').default;
const { hexToPoint } = require('./helpers/utils');
const Constants = require('../js/constants');

export async function sendTx(urlOperator, babyjubCompressed, amount, walletRollup, tokenId, fee, toEthAddr,ethAddress,cnf,nonce, nonceObject) {
    let babyjubTo
    const filters = {}
    // if (babyjubCompressed === 'norecipient') {
    //    try{
    //     filters.ethAddr = toEthAddr
    //     const leaf = await showAccounts(urlOperator, filters)
    //     const rollupAddress = leaf[0].rollupAddress
    //     console.log({leaf})
    //     babyjubTo = hexToPoint(rollupAddress)
    //     const res = await getAccountencPubKey(urlOperator,rollupAddress)
    //     if(!res){
    //         Toast.pending('The address has not yet deposited',10000)
    //         return
    //     } 
    //    }catch(e){
    //     Toast.pending('The address has not yet deposited',10000)
    //     return
    //    } 
    // } else {
        babyjubTo = hexToPoint(babyjubCompressed)
    // }
    
    // console.log({babyjubTo})
    // const apiOperator = new CliExternalOperator(urlOperator);
    // const tokeninfo = await apiOperator.getStateAccount(
    //         tokenId,
    //         `${babyjubTo[0]}`,
    //         `${babyjubTo[1]}`
    //       ).catch(() => {})
    // if(!tokeninfo){
    //     Toast.pending('The address has not yet deposited',10000)
    //     return
    // }
    // console.log({babyjubTo,babyjubCompressed})
    return send(urlOperator, babyjubTo, amount, walletRollup, tokenId, fee, ethAddress,nonce, nonceObject,toEthAddr)
}

export async function withdrawOffChainTx(urlOperator, amount, walletRollup, tokenId, fee,ethAddress, nonce, nonceObject) {
    // const walletRollup = await Wallet.fromEncryptedJson(walletJson, passphrase);
    return send(urlOperator, [Constants.exitAx, Constants.exitAy], amount, walletRollup, tokenId, fee,ethAddress, nonce, nonceObject);
}

export async function crossChainTx(urlOperator, chainAccountIdx, amount, walletJson, passphrase, tokenId, fee, nonce, nonceObject) {
    const {ax, ay, ethAddr} = Constants.getMultiChainAccount(chainAccountIdx);
    const walletRollup = await Wallet.fromEncryptedJson(walletJson, passphrase);
    return send(urlOperator, [ax, ay], amount, walletRollup, tokenId, fee, nonce, nonceObject, ethAddr);
}

export async function depositTx(nodeEth, addressSC, loadAmount, tokenId, walletJson, passphrase, ethAddress, abi, gasLimit, gasMultiplier) {
    const walletRollup = await Wallet.fromEncryptedJson(walletJson, passphrase);
    // here need to get rsa public key
    // console.log('walletJson.rsaKey.public');
    // console.log(walletJson.rsaKey.public);
    return deposit(nodeEth, addressSC, loadAmount, tokenId, walletRollup, ethAddress, walletJson.rsaKey.public, abi, gasLimit, gasMultiplier);
}

export async function depositOnTopTx(nodeEth, addressSC, loadAmount, tokenId, babyjubCompressed,
    walletJson, passphrase, abi, gasLimit, gasMultiplier) {
    const walletRollup = await Wallet.fromEncryptedJson(walletJson, passphrase);
    const babyjubTo = hexToPoint(babyjubCompressed);
    return depositOnTop(nodeEth, addressSC, loadAmount, tokenId, babyjubTo, walletRollup, abi, gasLimit, gasMultiplier);
}

export async function withdrawTx(nodeEth, addressSC, tokenId, walletJson, passphrase, abi, urlOperator, numExitRoot, gasLimit, gasMultiplier) {
    const walletRollup = await Wallet.fromEncryptedJson(walletJson, passphrase);
    return withdraw(nodeEth, addressSC, tokenId, walletRollup, abi, urlOperator, numExitRoot, gasLimit, gasMultiplier);
}

export async function forceWithdrawTx(nodeEth, addressSC, tokenId, amount, walletJson, passphrase, abi, gasLimit, gasMultiplier) {
    const walletRollup = await Wallet.fromEncryptedJson(walletJson, passphrase);
    return forceWithdraw(nodeEth, addressSC, tokenId, amount, walletRollup, abi, gasLimit, gasMultiplier);
}

export async function transferTx(nodeEth, addressSC, amount, tokenId, babyjubCompressed, walletJson, passphrase, abi, gasLimit, gasMultiplier) {
    const walletRollup = await Wallet.fromEncryptedJson(walletJson, passphrase);
    const babyjubTo = hexToPoint(babyjubCompressed);
    return transfer(nodeEth, addressSC, amount, tokenId, babyjubTo, walletRollup, abi, gasLimit, gasMultiplier);
}

export async function depositAndTransferTx(nodeEth, addressSC, loadAmount, amount, tokenId,
    babyjubCompressed, walletJson, passphrase, ethAddress, abi, gasLimit, gasMultiplier) {
    const walletRollup = await Wallet.fromEncryptedJson(walletJson, passphrase);
    const babyjubTo = hexToPoint(babyjubCompressed);
    return depositAndTransfer(nodeEth, addressSC, loadAmount, amount, tokenId, babyjubTo, walletRollup,
        ethAddress, abi, gasLimit, gasMultiplier);
}

export async function showAccounts(urlOperator, filters) {
    const apiOperator = new CliExternalOperator(urlOperator);
    return apiOperator.getAccounts(filters);
}
export async function getAccountencPubKey(urlOperator, address) {
    const apiOperator = new CliExternalOperator(urlOperator);
    return apiOperator.getAccountencPubKey(address);
}
export async function showStateAccount(urlOperator, coin, ax, ay) {
    const apiOperator = new CliExternalOperator(urlOperator);
    return apiOperator.getStateAccount(coin, ax, ay);
}

export async function showExitsBatch(urlOperator, coin, ax, ay) {
    const apiOperator = new CliExternalOperator(urlOperator);
    return apiOperator.getExits(coin, ax, ay);
}

// async function approveTx(nodeEth, addressTokens, amount, spender, walletJson, passphrase, abi, gasLimit, gasMultiplier) {
//     const walletRollup = await Wallet.fromEncryptedJson(walletJson, passphrase);
//     return approve(nodeEth, addressTokens, amount, spender, walletRollup, abi, gasLimit, gasMultiplier);
// }


// module.exports = {
//     sendTx,
//     depositTx,
//     depositOnTopTx,
//     withdrawTx,
//     forceWithdrawTx,
//     showAccounts,
//     showStateAccount,
//     transferTx,
//     depositAndTransferTx,
//     showExitsBatch,
//     // approveTx,
//     withdrawOffChainTx,
//     crossChainTx
// };
