import React from 'react';
import ApplicationUpdater from './application/updater'

import ApplicationUpdaterRollup from './application/updaterRollup'
// import TransactionUpdater from './transactions/updater'

const Updaters = () => (
  <>
    <ApplicationUpdater />
    {/* <ApplicationUpdaterRollup/> */}
    {/* <TransactionUpdater /> */}
  </>
);

export default Updaters;
