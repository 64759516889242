import { useCallback } from "react";
import { TransactionResponse } from "@ethersproject/providers";
// import { useTransactionAdder } from "../state/transactions/hooks";
// import { useAddPopup, useRemovePopup } from "../state/application/hooks";
export enum TranscationStatus {
  loading,
  pengding,
  await,
  success,
  error,
  close,
  confirm,
}

function useHandleTransactionReceipt(): (
  promise: Promise<TransactionResponse>,
  callbackLoading?: any
) => void {
  // const addTransaction = useTransactionAdder();
  // const addPopup = useAddPopup();
  /*
  callback //成功或失败时会调用。
  */
  return useCallback(
    async (promise: Promise<TransactionResponse>, callbackLoading?: any) => {
      try {
        const response = await promise;
        callbackLoading && callbackLoading(TranscationStatus.pengding,response.hash);
        if (response) {
          callbackLoading && callbackLoading(TranscationStatus.await,response.hash);
          const { wait } = response;
          const res = await wait();
          callbackLoading && callbackLoading(TranscationStatus.success,response.hash);
        } else {
          callbackLoading && callbackLoading(TranscationStatus.error,response.hash);
        }
      } catch (err) {
        console.log(err)
        if (err?.message && err?.message.includes("User denied")) {
          return;
        }
        callbackLoading && callbackLoading(TranscationStatus.error);
      }
    },
    []
  );
}

export default useHandleTransactionReceipt;
