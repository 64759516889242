import React from "react";
import CircleStatus from "../../BlcokDetails/columns/circleStatus";
import { hideAddress, hideMount } from "../../../utils/utils";
const transactionColumns = [
  {
    title: "Hash",
    dataIndex: "hash",
    render: (record: any) => <>{hideAddress(record)}</>,
  },
  {
    title: "From",
    dataIndex: "fromEthAddr",
    render: (record: any) => <>{hideAddress(record)}</>,
  },
  {
    title: "To",
    dataIndex: "toEthAddr",
    render: (record: any) => <>{hideAddress(record)}</>,
  },
  {
    title: "Type",
    dataIndex: "type",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    render: (record: any,params:any) => (
      <>
        {!params.isDecry
          ? hideAddress(record)
          : Math.floor(record / 1000000000000000000)}
      </>
    ),
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (record: any) => (
      <>
        <CircleStatus status={record}></CircleStatus>
        {record}
      </>
    ),
  },
  {
    title: "Executed At",
    dataIndex: "executed_at",
  },
];

export default transactionColumns;
