import { BigNumber, ethers } from 'ethers';
import { useCallback, useMemo } from 'react';
import { useHasPendingApproval, useTransactionAdder } from '../state/transactions/hooks';
import useAllowance from './useAllowance';
import ERC20 from '../utils/ERC20';
import useStarterWallet from './useStarterWallet';
import { parseUnits } from 'ethers/lib/utils';
const APPROVE_AMOUNT = ethers.constants.MaxUint256;
const APPROVE_BASE_AMOUNT = BigNumber.from('1');

export enum ApprovalState {
  UNKNOWN,
  NOT_APPROVED,
  PENDING,
  APPROVED,
}

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
function useApprove(token: ERC20, spender: string,num:any, callbackLoading?: any): [ApprovalState, () => Promise<void>] {
  const amount = String(num)
  const pendingApproval = useHasPendingApproval(token.address, spender);
  const currentAllowance = useAllowance(token, spender, pendingApproval,amount)
  const {setAccountLoading} = useStarterWallet()
  const USER_AMOUNT = parseUnits(amount || '1',token.decimal); 
  // check the current approval status
  const approvalState: ApprovalState = useMemo(() => {
    // we might not have enough data to know whether or not we need to approve
    if (!currentAllowance) return ApprovalState.UNKNOWN;

    // amountToApprove will be defined if currentAllowance is
    // console.log(currentAllowance.lt(USER_AMOUNT)
    // ? pendingApproval
    //   ? ApprovalState.PENDING
    //   : ApprovalState.NOT_APPROVED
    // : ApprovalState.APPROVED)
    return currentAllowance.lt(USER_AMOUNT)
      ? pendingApproval
        ? ApprovalState.PENDING
        : ApprovalState.NOT_APPROVED
      : ApprovalState.APPROVED;
  }, [currentAllowance, pendingApproval,amount])

  const addTransaction = useTransactionAdder()

  const approve = useCallback(async (): Promise<void> => {

    // if (approvalState !== ApprovalState.NOT_APPROVED) {
    //   console.error('approve was called unnecessarily');
    //   return;
    // }

    try {
      callbackLoading && callbackLoading(true)
      setAccountLoading(true)
      const response = await token.approve(spender, APPROVE_AMOUNT)
      const { wait } = response
      addTransaction(response, {
        summary: `Approve ${token.symbol}`,
        approval: {
          tokenAddress: token.address,
          spender: spender,
        }
      })
      const res = await wait()
      setAccountLoading(false)
      if(res && res.status && callbackLoading){
        callbackLoading(false)
      }
    } catch (e) {
      setAccountLoading(false)
      // console.error('点击授权',e)
      if (callbackLoading) {
        callbackLoading(false)
      }
      // console.table(e)
    }

  }, [approvalState, token, spender, addTransaction]);

  return [approvalState, approve];
}

export default useApprove;