import { ethers } from 'ethers';
import config from '../config';
import { web3ProviderFrom } from './ether-utils';
export function getDefaultProvider(chainId:number): ethers.providers.Web3Provider {
  // const provider = new ethers.providers.JsonRpcProvider(node); 

  const defaultProvider = config.BSC.chainId === chainId ? config.BSC.defaultProvider : config.ETH.defaultProvider
  return new ethers.providers.Web3Provider(
    web3ProviderFrom(defaultProvider),
    chainId
  )
}

