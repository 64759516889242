import React, { useEffect, useMemo, useState } from "react";
import { hideAddress, hide85 } from "../../../utils/utils";

const token = {
  1: "wETH",
  0: "TSM",
  2: "USDT",
  3: "USDC",
  4: "wBNB",
};

// const price = useMemo((params)=>{
//   return allTokens.find(itm=>String(itm.tokenId) == String(params.id))?.price || 0
// },[allTokens,params])

const balanceColumns = [
  {
    title: "token",
    dataIndex: "token",
    render: (record: any) => <>{token[record]}</>,
  },
  {
    title: "Amount",
    dataIndex: "amount",
    render: (record: any) => (
      <>
        {sessionStorage.getItem("decryItem") === "false"
          ? hideAddress(record)
          : Math.floor(record / 1000000000000000000)}
      </>
    ),
  },
  {
    title: "Value",
    dataIndex: "value",
  },
];

export default balanceColumns;
