import { useCallback, useMemo } from "react";
import { parseUnits } from "ethers/lib/utils";
import useStarterWallet from "./useStarterWallet";
import useHandleTransactionReceipt, { TranscationStatus } from "./useHandleTransactionReceipt";
import { Bank } from "../utils/poolsType";

const useDeposit = (bank: Bank,setLoading:any) => {
  const {
    starterCash,
    allTokens,
    wallet,
    account: ethAddress,
    rollupWallet,
  } = useStarterWallet()
  const handleTransactionReceipt = useHandleTransactionReceipt();

  const isRegister = useMemo(()=>{
    if(allTokens.length){
      return allTokens.find(itm=>itm.tokenId === bank.tokenId).number !== undefined
    }
  },[bank,allTokens])
  const handleStake = useCallback(
    (amount: string) => {
      if(!amount){
        return
      }
      setLoading(TranscationStatus.loading)
      const amountBn = parseUnits(amount, bank.depositToken.decimal)
      
      const { ax } = wallet.babyjubWallet.public
      const { ay } = wallet.babyjubWallet.public

      const pubKeyBabyjub = [`0x${ax}`,`0x${ay}`]
      
      handleTransactionReceipt(
        isRegister
          ? starterCash.DepositOnTop(bank, amountBn, ethAddress, pubKeyBabyjub,wallet.rsaKey.public)
          : starterCash.Deposit(bank, amountBn, ethAddress, pubKeyBabyjub,wallet.rsaKey.public),
          setLoading
      );
    },
    [starterCash, ethAddress, rollupWallet, wallet,isRegister]
  );
  return { onDeposit: handleStake };
};

export default useDeposit;
