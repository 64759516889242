import React, { useCallback, useEffect, useMemo, useState } from "react";

import styled from "styled-components";
import useStarterWallet, { LoginState } from "../../../hooks/useStarterWallet";
import { useHistory, useParams } from "react-router-dom";
import { CardHight } from "../../../components/Card";
import { SelectPrimary, SelectHighlights } from "../../../components/Select";
import { Spaced } from "../../../components/Spaced";
import Input from "../../Login/components/Input";
import { ButtonHighlights,ButtonPink } from "../../../components/Button";
import useBanks from "../../../hooks/useBanks";
import ValueSkeleton from "../../../components/ValueSkeleton";
import { Bank } from "../../../utils/poolsType";
import DownImg from "../../../assets/connect/down@2x.png";
import useWithdraw from "../../../hooks/useWithdraw";
import DiscordModel from "../../../components/DiscordModel";
import { number_format } from "../../../utils/utils";
import Toast from "../../../components/Toast";
import { TranscationStatus } from "../../../hooks/useHandleTransactionReceipt";
import SuccessPage from "../../../components/SuccessPage";
import config from "../../../config";
import Modal from "../../../components/Modal";
import WalletBg from "../../../assets/home/wallet_b.png";
import LoadPage from "../../../components/LoadPage";
import { postSbtReg } from "../../../utils/fetch/login";
import { Trans, useTranslation } from "react-i18next";
import { getChainLng } from "../../../utils/tools";

const SubmitButton: React.FC<{
  bank: Bank;
  amount: string;
  onSuccess: any;
  disabled: boolean;
}> = ({ bank, amount, onSuccess, disabled }) => {
  const [confirm,setConfirm] = useState(false)
  const { setAccountLoading, account, chainId } = useStarterWallet();
  const [show, setShow] = useState(false);
  const { token } = useParams<any>();
  const [loading, setLoading] = useState(false);
  const {t}=useTranslation()
  const cnf = useMemo(() => {
    return config[getChainLng(chainId)];
  }, [chainId]);
  
  const { onWithdraw, batchId, withdrawstar, onChainTx } = useWithdraw(bank,(Status: TranscationStatus, hash: any) => {
      if (TranscationStatus.confirm === Status) {
        setConfirm(true)
      }else if (TranscationStatus.loading === Status) {
        setShow(false)
        setLoading(true)
        setAccountLoading(true)
      } else if (TranscationStatus.success === Status) {
        Toast.success(t("Succeeded"),null,null,hash ? `${cnf.etherscanUrl}/tx/${hash}`:null)
        onSuccess(hash)
        setLoading(false)
        setAccountLoading(false)
        const res = postSbtReg({
          addr: account,
          optype: 'withdraw',
          txhash:hash,
          token:bank.tokenId
        })
        console.log('save...',res)
      } else if ([TranscationStatus.await, TranscationStatus.pengding].includes(Status)) {
        setAccountLoading(true)
        Toast.pending(`${t('Pending')}...`,null,null,hash ? `${cnf.etherscanUrl}/tx/${hash}`:null)
      } else if (TranscationStatus.error === Status) {
        Toast.error(t("Failed"))
        setConfirm(false)
        setLoading(false)
      } else if (TranscationStatus.close === Status) {
        setLoading(false)
        setConfirm(false)
      }
    }
  );
  const id = useMemo(() => {
    return batchId;
  }, [batchId]);
  return (
    <>
      {show ? (
        <Modal onClose={() => setShow(false)}>
          <ModalBox onClick={(e) => e.stopPropagation()}>
            <ModalTitle>
              {t('Withdraw XXX from Tusima to Ethereum Goerli',{amount,token:bank.name,chain:cnf.fullChainName})}
              {/* Withdraw {amount} {token} from Tusima to {cnf.fullChainName} */}
            </ModalTitle>
            <Spaced size="45" />
            <ModalDesc>
              <p>{t('Estimated Received')}:</p>
            </ModalDesc>
            <Spaced size="12" />
            <ModalValue>
              {amount} {bank.name}
            </ModalValue>
            <Spaced size="24" />
            <ModalDesc>
              <p>{t('To address')}:</p>
              <p>{account}</p>
            </ModalDesc>
              <SubmitButtonSty2
                disabled={confirm}
                load={loading}
                onClick={() => onWithdraw(amount)}>
                {t("Confirm")}{confirm?'...':''}
              </SubmitButtonSty2>
          </ModalBox>
        </Modal>
      ) : (
        ""
      )}
      {batchId > 0 || withdrawstar ? (
              <SubmitButtonSty load={loading} onClick={() => onChainTx(id)}>
                {t('Confirm transaction in wallet')} (2/2)
              </SubmitButtonSty>
            ) : (
              <SubmitButtonSty
                disabled={disabled}
                load={loading}
                onClick={() => setShow(true)}>
                {loading ? `${t('Confirm transaction in wallet')} (1/2)` : t("Withdraw")}
              </SubmitButtonSty>
            )}
    </>
  );
};

const ContentPage = ({ onSuccess }) => {
  const {
    ethPrice,
    gasPrice:gasETHPrice,
    starterCash,
    allTokens,
    chainId,
    onChangeChain,
    account,
    tokenComputeFee,
  } = useStarterWallet();
  const { token } = useParams<any>();
  const { banks } = useBanks();
  
  const [tokenId, setTokenId] = useState(Number(token));
  const [amount, setAmount] = useState("");

  const bank = useMemo(() => {
    const item = banks.find((itm) => itm.tokenId === tokenId);
    return item;
  }, [tokenId,banks]);

  const gasPrice = useMemo(() => {
    const item = allTokens.find((itm) => itm.tokenId === bank.tokenId);
    return item?.price || 0
  }, [amount, allTokens])

  const gasNumber = useMemo(() => {
    if (amount && bank) {
      console.log(bank)
      const ss = tokenComputeFee(amount, bank);
      return ss;
    }
    return '0.000001';
  }, [amount,bank]);

  const totalBalance = useMemo(() => {
    const number = allTokens.find((itm) => itm.tokenId === bank?.tokenId)?.number
    if(number === undefined){
      return null
    }
    const num = number_format(number,6);
    return num;
  }, [allTokens, bank]);

  const tokensTotal = useMemo(() => {
    if (totalBalance && bank) {
      console.log({bank,totalBalance})
      const ss = tokenComputeFee(totalBalance, bank);
      return number_format(totalBalance - ss);
    }
    return totalBalance;
  }, [allTokens, bank, totalBalance]);

  const onChangeTokens = useCallback(
    (id: any) => {
      console.log({ id });
      setTokenId(id);
    },
    [tokenId]
  );

  const onChangeInput = (e: any) => {
    let { value } = e.target;
    const reg = /^-?\d*(\.\d*)?$/
    if ((!isNaN(value) && reg.test(value)) || value === "") {
      if (value > Number(tokensTotal)) {
        value = Number(tokensTotal);
      }
      const arr = String(value).split('.')
      if(arr.length === 2 && arr[1].length > 6){
        return
      }
      setAmount(value)
    }
  }

  const cnf = useMemo(()=>{
    return config[getChainLng(chainId)]
  },[chainId])

  const UserChainsLists = useMemo(() => {
    return [
      {
        title: cnf.fullChainName,
        value: cnf.smallChain,
      },
      // {
      //   title: config.ETH.fullChainName,
      //   value: config.BSC.symbol,
      // },
    ]
  }, [starterCash,cnf])

  const ChainsSmall = useMemo(() => {
    return [
      {
        title: config.BSC.chainName,
        symbol: config.BSC.symbol,
        value: config.BSC.smallChain,
      },
      // {
      //   title: config.ETH.chainName,
      //   symbol: config.ETH.symbol,
      //   value: config.ETH.symbol,
      // },
    ];
  }, [])

  const onMax = useCallback(() => {
    const max = tokensTotal
    onChangeInput({ target: { value:String(max)  } })
  }, [tokensTotal])
  const disabled = useMemo(() => !amount, [amount])
  const {t}=useTranslation()
  return (
    <>
      <CardBox bgcolor="#666">
        <CardLeft>
          <span>Tusima Layer 2</span>
          <Spaced size="21" />
          <SelectHighlights
            value={tokenId}
            onChange={onChangeTokens}
            options={banks.filter(itm=>itm.version === bank.version)}
          />
        </CardLeft>
        <CardRight>
          <UserInputLine>
            <CardInput
              noerr
              placeholder="0.00"
              onChange={onChangeInput}
              value={amount}
            />
          </UserInputLine>
          <Spaced size="11" />
          <UserInputLine>
            <BalanceSty>
              <span>{t('Balance')}: </span>
              {totalBalance !== null ? (
                <span>{totalBalance}</span>
              ) : (<ValueSkeleton width={60} />)}
            </BalanceSty>
            <MaxBnt onClick={onMax}>{t('MAX')}</MaxBnt>
          </UserInputLine>
        </CardRight>
      </CardBox>
      <DownBox>
        <DownIcon src={DownImg} />
      </DownBox>
      {/* <Spaced size="45" /> */}
      <CardColumnBox className="row" bgcolor="#666">
        <CardLeft>
          <BottonSelectPrimary
            value={getChainLng(chainId)}
            onChange={onChangeChain}
            options={UserChainsLists}/>
        </CardLeft>
        <CardRight className="center">{account}</CardRight>
      </CardColumnBox>
      <FeesBox>
        <div className="span" />
        {/* You can pay network fees in other tokens than ETH */}
        <FeesRightBox>
            {bank ? (
             <>
              <FeesPrices>
                Layer 1 fee: ~${number_format(Number(gasETHPrice) * Number(ethPrice), 6)} |{" "}{number_format(gasETHPrice, 6)} ETH
              </FeesPrices>
              <FeesPrices>
                Layer 2 fee: ~${number_format(Number(gasPrice) * Number(gasNumber), 6)} |{" "}{number_format(String(gasNumber), 6)} {bank.name}
              </FeesPrices>
             </> 
            ) : ("")}
        </FeesRightBox>
      </FeesBox>
      {bank ? (
        <SubmitButton
          disabled={disabled}
          bank={bank}
          onSuccess={onSuccess}
          amount={amount}
        />
      ) : (
        ""
      )}
    </>
  );
};
const WithdrawView = () => {
  const [hash, setHash] = useState("");
  const { chainId ,loginState} = useStarterWallet();
  const history = useHistory()
  const cnf = useMemo(() => {
    return config[getChainLng(chainId)]
  }, [chainId, config])
  const toHref = useMemo(() => {
    return `${cnf.etherscanUrl}/tx/${hash}`
  }, [hash])
  useEffect(() => {
    loginState === LoginState.init && history.push("/login");
  }, [loginState]);
  const onSuccess = (hash: string) => {
    setHash(hash);
  };
  return (
    <Wrapper>
      <ContainerWrapper>
      <Spaced size="80"/>
        <DepositBox>
          <CardTitle><Trans>Withdraw</Trans></CardTitle>
          {loginState === LoginState.loading?<LoadPage/>:hash ? (
            <SuccessPage to={toHref} hash={hash} />
          ) : (
            <ContentPage onSuccess={onSuccess} />
          )}
        </DepositBox>
        <Spaced size="100" />
        {/* <DiscordModel url="https://discord.com/channels/965918503070728203/1011838365110378617"></DiscordModel> */}
      </ContainerWrapper>
    </Wrapper>
  );
};
export default WithdrawView;
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-image:url(${WalletBg});
  background-size: 100% 100%;
  background-repeat:no-repeat;
`

const ModalDesc = styled.div`
  font-size: 15px;
  font-weight: 400;
  color: #cccccc;
  line-height: 24px;
  text-align: center;
  p {
    padding: 0;
    margin: 0;
    line-height: 24px;
  }
`;
const ModalValue = styled.div`
  text-align: center;
  font-size: 27px;
  font-weight: 600;
  color: #ffffff;
  line-height: 27px;
`;
const ModalTitle = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  color: #cccccc;
  line-height: 18px;
`;
const ModalBox = styled.div``;
const BottonSelectPrimary = styled(SelectPrimary)`
  width: 160px;
`;
const FeesPrices = styled.div`
  margin-right: 12px;
  &:first-child{
    margin-top:10px ;
  }
`;
const FeesRightBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;
const DownBox = styled.div`
  text-align: center;
`;
const DownIcon = styled.img`
  width: 15px;
  margin: 15px;
`;
const CardTitle = styled.div`
  font-size: 15px;
  color: #ffffff;
  margin-bottom: 15px;
`;
const SubmitButtonSty2 = styled(ButtonPink)`
  width: 180px;
  font-size: 11px;
  font-weight: 600;
  color: #292929;
  margin: 45px auto 0;
`;
const SubmitButtonSty = styled(ButtonHighlights)`
  width: 508px;
  font-size: 11px;
  font-weight: 600;
  color: #292929;
  margin: 45px auto 0;
`;
const FeesBox = styled.div`
  margin: 0px 23px;
  margin-right: 11px;
  font-size: 10px;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  .span {
    width: 151px;
    height: 30px;
    font-size: 10px;
    font-family: Poppins-Regular, Poppins;
    font-weight: 400;
    color: #999999;
    line-height: 15px;
  }
`;
const MaxBnt = styled.div`
  margin-left: 10px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  color: #18f195;
  line-height: 12px;
  background: linear-gradient(90deg, #18f195 0%, #1fb7ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
const BalanceSty = styled.div`
  display: flex;
  align-items: center;
`;
const UserInputLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const CardInput = styled(Input)`
  border: none;
  .input {
    font-size: 27px;
    font-weight: 600;
    color: #ffffff;
    line-height: 27px;
    text-align: right;
  }
`;

const CardLeft = styled.div`
  display: flex;
  flex-direction: column;
`;
const CardRight = styled.div`
  &.center {
    margin-top: 15px;
    font-size: 15px;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
  }
`;
const CardBox = styled(CardHight)`
  display: flex;
  justify-content: space-between;
  padding: 23px;
  align-items: center;
  min-height: 81px;
`;
const CardColumnBox = styled(CardHight)`
  padding: 23px;
  align-items: center;
  min-height: 81px;
`;
const ContainerWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 56px;
  color: #fff;
`;
const DepositBox = styled.div`
  width: 510px;
  margin: 0 auto;
`;
