import abiRollup from './abiRollup.json';
import abiShuiPool from './abifaucel.json';
import { BankInfo, Deployments, VersionType } from './poolsType';


export const externalTokens: { [contractName: string]: [string, number] } = {
  wETH: ['0x47a4357315e1d3AB3294c161c3a52e8b0d3A4109', 18],
  USDT: ['0xa41Ed18AdF3fe3B2f4aF72B4c1df1FCA3e97B3f9', 18],
  USDC: ['0xAe8012B09CDE1D5D77bE7EC87E95D52E241489df', 18],
  wBNB: ['0x708415d42f977E252bf787D1e9D71b1fD0F88171', 18],
  TSM: ['0x5995D54A4bD0b79C4164086d24c70C3807CFEFF9', 18],
  testJPY:['0x3c5FE6001dD6f5E85Af75a4e69fFB7785DeFd230',8]
}

export const deployments: Deployments = {
  ETHPool:{
    'address':'0x2935F0e38D1e5BC9F05d2a7a9Fcd52F24E15Bf20',
    'abi':abiRollup
  },
  USDTPool:{
    'address':'0x2935F0e38D1e5BC9F05d2a7a9Fcd52F24E15Bf20',
    'abi':abiRollup
  },
  BNBPool:{
    'address':'0x2935F0e38D1e5BC9F05d2a7a9Fcd52F24E15Bf20',
    'abi':abiRollup
  },
  USDCPool:{
    'address':'0x2935F0e38D1e5BC9F05d2a7a9Fcd52F24E15Bf20',
    'abi':abiRollup
  },
  TSMPool:{
    'address':'0x2935F0e38D1e5BC9F05d2a7a9Fcd52F24E15Bf20',
    'abi':abiRollup
  },
  testJPYPool:{
    'address':'0x2935F0e38D1e5BC9F05d2a7a9Fcd52F24E15Bf20',
    'abi':abiRollup
  },
  ShuiPool:{
    'address':'0xB076B1D870381027b8165E106C7e7fF4Ef52bC18',
    'abi':abiShuiPool
  },
  testJPYFaucet:{
    'address':'0x12BBB2232A2Bc206871A570243F4a984C3bDA747',
    'abi':abiShuiPool
  }
}

export const tokens721: { [contractName: string]: [string, number] } = {
  'NFTToken':['0xAc4825343EFE2AD9d352f84e4A7b426428f87991',18] 
}

export const bankDefinitions: BankInfo[] = [
  {
    name: 'wETH',
    contract: 'ETHPool',
    tokenId:1,
    depositTokenName: 'wETH',
    earnTokenName: 'wETH',
    finished: false,
    fee:'0.001%',
    sort: 1,
    minVal:0.001,
    stepVal:0.001,
    display:true,
    version:VersionType.V1
  },
  {
    name: 'TSM',
    contract: 'TSMPool',
    tokenId:1,
    depositTokenName: 'TSM',
    earnTokenName: 'TSM',
    finished: true,
    fee:'0.005%',
    sort: 2,
    minVal:1,
    stepVal:0.5,
    display:true,
    version:VersionType.V1
  },
  {
    name: 'USDT',
    contract: 'USDTPool',
    tokenId:2,
    depositTokenName: 'USDT',
    earnTokenName: 'USDT',
    finished: true,
    fee:'0.005%',
    sort: 3,
    minVal:1,
    stepVal:0.5,
    display:true,
    version:VersionType.V1
  },
  {
    name: 'USDC',
    contract: 'USDCPool',
    tokenId:3,
    depositTokenName: 'USDC',
    earnTokenName: 'USDC',
    finished: true,
    fee:'0.005%',
    sort: 4,
    minVal:1,
    stepVal:0.5,
    display:true,
    version:VersionType.V1
  },
  {
    name: 'wBNB',
    contract: 'BNBPool',
    tokenId:4,
    depositTokenName: 'wBNB',
    earnTokenName: 'wBNB',
    finished: true,
    fee:'0.002%',
    sort: 5,
    minVal:0.01,
    stepVal:0.01,
    display:true,
    version:VersionType.V1
  },
  {
    name: 'testJPY',
    contract: 'testJPYPool',
    tokenId:6,
    depositTokenName: 'testJPY',
    earnTokenName: 'testJPY',
    finished: false,
    fee:'0.002%',
    sort: 6,
    minVal:1,
    stepVal:20,
    display:false,
    version:VersionType.V1
  },
].filter(itm=>!itm.finished)