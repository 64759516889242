import React from 'react'
import ReactDOM from 'react-dom'
import Toast from './NewToast'
import './toast.css'

function createNotification() {
    const div = document.createElement('div')
    document.body.appendChild(div)
    const notification = ReactDOM.render(< Toast style={{ fontSize: 16 }} />, div)
    return {
        addNotice(notice) {
            return notification.addNotice(notice)
        },
        destroy() {
            ReactDOM.unmountComponentAtNode(div)
            document.body.removeChild(div)
        }
    }
}

let notification;
const notice = (type, content, duration = 2000, onClose,href) => {
    if (!notification) notification = createNotification()
    return notification.addNotice({
        type,
        content,
        duration:duration || 10000,
        onClose,
        href
    })
}

// eslint-disable-next-line
export default {
    info(content, duration, onClose,href) {
        return notice('info', content, duration, onClose,href)
    },
    success(content = '操作成功', duration, onClose,href) {
        return notice('success', content, duration, onClose,href)
    },
    error(content, duration, onClose,href) {
        return notice('error', content, duration, onClose,href)
    },
    pending(content, duration, onClose,href) {
        return notice('pending', content, duration, onClose,href)
    },
    loading(content = '加载中...', duration = 0, onClose,href) {
        return notice('loading', content, duration, onClose,href)
    }
}
