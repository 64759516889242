import { BigNumber } from "ethers";
import config from "../config";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const timeToEng = function (month: string) {
  return months[month];
};
const hourToStr = function (Hour) {
  let str;
  if (Hour > 12) {
    Hour -= 12;
    str = " PM";
  } else {
    str = " AM";
  }
  return [String(Hour), str + " +UTC"];
};
export function dateFormat(date: string, local: "lists" | "detail" = "detail") {
  if (!date) {
    return;
  }
  try {
    return baseDate("m0-d0-y0 h0:i0:s0", new Date(date));
  } catch (e) {
    return "—— ——";
  }
}
export const getDisplayBalance = (balance: BigNumber, decimals = 18, fractionDigits = 6) => {
  const number = getBalance(balance, decimals - fractionDigits);
  return (number / 10 ** fractionDigits).toFixed(fractionDigits);
};

export const getFullDisplayBalance = (balance: BigNumber, decimals = 18) => {
  return getDisplayBalance(balance, decimals);
};

export function getBalance(balance: BigNumber, decimals = 18) : number {
  return balance.div(BigNumber.from(10).pow(decimals)).toNumber();
}
export function formatListType(str: string) {
  if (!str) {
    return "";
  }
  if (str === "transfer") {
    return "Send";
  }
  return firstToUpper(str);
}
export function baseDate(fmt: string, date: Date) {
  let ret;
  const year = date.getFullYear().toString();
  const month = date.getMonth().toString();
  const day = date.getDate().toString();
  const minute = date.getMinutes().toString();
  const second = date.getSeconds().toString();
  const hour = date.getHours().toString();
  const opt: any = {
    y0: year, // 年
    //     "m+": (date.getMonth() + 1).toString(), // 月
    m0: timeToEng(month), // 月
    d0: day, // 日
    h0: hour, // 时
    i0: minute, // 分
    s0: second, // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(
        ret[1],
        ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
      );
    }
  }
  return fmt + " UTC";
}

export const fromatNumber = (num: string) => {
  if (Number(num) * 1 === 0) {
    return "0";
  }
  const arr1 = String(num).split("");
  let arr2 = [];
  let ji = 0;
  for (let index = 0; index < arr1.length; index++) {
    const nn = arr1[index];
    if (Number(nn) > 0) {
      ji++;
    }
    arr2.push(nn);
    if (ji >= 2) {
      break;
    } else if (ji === 1 && String(arr1[index + 1]) === "0") {
      break;
    }
  }
  return arr2.join("");
};
export const WebFetch = async (
  api: string,
  params?: any,
  method: "get" | "post" = "get"
) => {
  const url = `${config.apiUrl}${api}`;
  return fetch(url, {
    method: method,
    body: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .catch(() => {});
};
export function firstToUpper(str: string) {
  const newStr = str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase();
  return newStr;
}
export function getChainLng(chainId:number):'BSC' | 'ETH'{
  return config.BSC.chainId === chainId ? 'BSC':"ETH"
}

export function getOperatorUrl(chainId:number):string{
  return config[getChainLng(chainId)].operatorUrl
}