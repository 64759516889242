import { useEffect, useMemo, useState } from 'react'
import config from '../config'
import { getSbtmintCheck, getSbtProfile } from '../utils/fetch/login'
import useStarterWallet from './useStarterWallet'

export const CheckoutOptions = [
  {
    title: "Layer1 deposit to Layer2",
    type: "deposit",
    width: 160,
  },
  {
    title: "Withdraw from Layer2 to Layer1",
    type: "withdraw",
    width: 226,
  },
  {
    title: "Layer2 privacy transfer",
    type: "transfer",
    width: 160,
  },
  {
    title: "Decrypt transaction data in Explorer",
    type: "decrypt",
    width: 226,
  }
]

export default function useTdao(authType: string, authTypeArr: any[]) {
  const { account, starterCash } = useStarterWallet()
  const [isError,setIsError] = useState(false)
  const [showCard, setShowCard] = useState(false)
  const [nftId, setNftId] = useState(null)


  const [profile, setProfile] = useState({
    name: "Tusima DAO",
    role: "Development",
    score: 0,
    img: "",
    address: ""
  })

  const [tasks, setTasks] = useState(null)

  const fetchTaskLists = async () => {
    const { code, data } = await getSbtmintCheck(account)
    if (code === 200) {
      setTasks(data)
    }
  }
  const initData = async () => {
    const { code, data: profile } = await getSbtProfile(nftId)
    if (code === 200) {
      setProfile(profile)
      setShowCard(true)
    }
  }

  const fetchNftId = async () => {
    try {
      const nftid = await starterCash.fetchNftId(account)
      if (nftid === null) {
        await fetchTaskLists()
      } else {
        setNftId(nftid)
      }
    } catch (e) {
      setIsError(true)
    }
  }

  const clearError=()=>{
    setIsError(false)
  }
  const isOkMin = useMemo(() => {
    if (!tasks) return null
    const arr1 = CheckoutOptions.filter((itm) => tasks[itm.type])
    return arr1.length === CheckoutOptions.length
  }, [profile, tasks, CheckoutOptions])



  useEffect(() => {
    if (account && !isError) {
      fetchNftId().catch(console.error)
      const timeId = setInterval(
        () => fetchNftId().catch(console.error),
        config.refreshInterval
      )
      return () => clearTimeout(timeId)
    }
  }, [account,isError, authType])

  useEffect(() => {
    if (account && nftId !== null && !authTypeArr.includes(authType)) {
      initData().catch(console.error)
      const timeId = setInterval(
        () => initData().catch(console.error),
        config.refreshInterval
      )
      return () => clearTimeout(timeId)
    }
  }, [account, nftId, authType])





  return { profile, showCard, nftId, isOkMin, initData, tasks,isError,clearError }
}
