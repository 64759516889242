import React, { useEffect, useMemo, useState } from "react"

import styled from "styled-components"
import useStarterWallet, { LoginState } from "../../../hooks/useStarterWallet"
import { useHistory, useParams } from "react-router-dom"
import { Spaced } from "../../../components/Spaced"
import { ButtonHighlights } from "../../../components/Button"
import DiscordModel from "../../../components/DiscordModel"
import { TranscationStatus } from "../../../hooks/useHandleTransactionReceipt"
import WalletBg from "../../../assets/home/wallet_b.png"
import ValueSkeleton from "../../../components/ValueSkeleton"
import NftbgIcon from "../../../assets/connect/nftbg@2x.png"
import RateTopIcon from "../../../assets/connect/rate-top@2x.png"
import NftImg from "../../../assets/connect/nft_img@2x.png"
import Checkout from "../../../components/Checkout"
import useNFTCliam from "../../../hooks/useNFTCliam"
import config from "../../../config"
import Toast from "../../../components/Toast"
import ConnectIcon from "../../../assets/image/connect2x.png"
import Nodata from '../../../assets/home/noDataTable.png'
import NoDataIcon from "../../../components/NoDataIcon"
import {
  getDiscordInfo,
  getSbtmintCheck,
  getSbtProfile,
  postBbtMint,
  getTwitterAuthUrl,
  getDiscordAuthUrl,
  getDiscordCallbackUrl,
  getTwitterCallbackUrl,
} from "../../../utils/fetch/login"
import useTdao,{CheckoutOptions} from "../../../hooks/useTdao"
import useTdaoLastInfo from "../../../hooks/useTdaoLastInfo"
import { useTranslation } from "react-i18next"
import { getChainLng } from "../../../utils/tools"
import { CardHight } from "../../../components/Card"

const apiUrlAuth = {
  "discord": getDiscordAuthUrl,
  "twitter": getTwitterAuthUrl,
}
const apiUrlBind = {
  "discord": getDiscordCallbackUrl,
  "twitter": getTwitterCallbackUrl,
}
const authTypeArr=[
  'discord','twitter'
]
export const TestApiUrl =null 

const ConnectItem: React.FC<{
  type: string
  name: any
  account: string
}> = ({ type, name, account }) => {
  const [jumpUrl, setJumpUrl] = useState(null)
  const {t} = useTranslation()
  const initJumpUrl = async () => {
    const { code, data: result } = await apiUrlAuth[type]({ addr: account },TestApiUrl)
    if (code === 200) {
      setJumpUrl(result)
    }
  }
  // console.log({userinfo})
  useEffect(() => {
    if (!name)
      initJumpUrl()
  }, [name])

  // console.log({name,jumpUrl})
  // return <div></div>
  if ((!name && jumpUrl === null)) {
    return <ValueSkeleton width={50} />
  } else if (name && Object.keys(name).length >0) {
    return <span>{name}</span>
  }
  return (
    
      <ButtonConnect radius={20} border >
        <a href={jumpUrl}>
       <span>{t('Connect')}</span> 
        </a>
        </ButtonConnect>
  )
}
const CardInfo: React.FC<{
  nftId: string
  profile: {
    name: string
    role: string
    img: string
    score: number
    address: string
  }
}> = ({ profile, nftId }) => {
  const { account } = useStarterWallet()
  const [userinfo, setUserInfo] = useState({ discord: null, twitter: null })
  const initProfile = async () => {
    const { code, data: result } = await getDiscordInfo({ addr: account },TestApiUrl)
    if (code === 200) {
      setUserInfo(result)
    }
  }
  useEffect(() => {
    initProfile()
  }, [account])
  const {t}=useTranslation()
  return (
    <CardBox>
      <Spaced size="36" />
      <CardLine>
        <CardTit>{t('TSBT ID')}：</CardTit>
        <CardVal>{nftId === null ? <ValueSkeleton /> : nftId}</CardVal>
      </CardLine>
      <Spaced size="24" />
      <CardLine>
        <CardTit>{t('Discord ID')}：</CardTit>
        <CardVal>
          {userinfo === null ? (
            <ValueSkeleton />
          ) : (
            <ConnectItem
              account={account}
              name={userinfo?.discord ?userinfo.discord:null}
              type="discord"
            />
          )}
        </CardVal>
      </CardLine>
      <Spaced size="24" />
      <CardLine>
        <CardTit>{t('Role')}：</CardTit>
        <CardVal>{t(`${profile.role}`)}</CardVal>
      </CardLine>
      <Spaced size="24" />
      <CardLine>
        <CardTit>{t('TSBT Points')}：</CardTit>
        <CardVal>{profile.score}</CardVal>
      </CardLine>
      <Spaced size="24" />
      <CardLine>
        <CardTit>{t('Twitter')}：</CardTit>
        <CardVal>
          {userinfo === null ? (
            <ValueSkeleton />
          ) : (
            <ConnectItem
              account={account}
              name={userinfo?.twitter?.screen_name?`@${userinfo.twitter?.screen_name}`:null}
              type="twitter"
            />
          )}
        </CardVal>
      </CardLine>
    </CardBox>
  )
}
interface SubmitProps {
  onSuccess?: any
  disabled: boolean
}

const SubmitButton: React.FC<SubmitProps> = ({ disabled, onSuccess }) => {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const { setAccountLoading, account, chainId } = useStarterWallet()
  const cnf = useMemo(() => {
    return config[getChainLng(chainId)]
  }, [chainId])
  const { onNFTCliam } = useNFTCliam(
    async (status: TranscationStatus, hash: any) => {
      if (TranscationStatus.loading === status) {
        setLoading(true)
        setAccountLoading(true)
        postBbtMint({ txhash: hash, addr: account })
      } else if ([TranscationStatus.await, TranscationStatus.pengding].includes(status)) {
        Toast.pending(
          `${t('Pending')}...`,
          null,
          null,
          hash ? `${cnf.etherscanUrl}/tx/${hash}` : null
        )
      } else if (TranscationStatus.success === status) {
        // setLoading(false);
        setAccountLoading(false)
        const res = await postBbtMint({ txhash: hash, addr: account })
        Toast.success(t("Succeeded"),null, null,hash ? `${cnf.etherscanUrl}/tx/${hash}` : null)
        onSuccess()
      } else if (TranscationStatus.error === status) {
        setLoading(false)
      } else if (TranscationStatus.close === status) {
        setLoading(false)
      }
    }
  )
  return (
    <>
      <SubmitButtonSty
        disabled={disabled}
        load={loading}
        onClick={() => onNFTCliam()}>
        {t(!disabled ? "Claim your TSBT" : "Task Not Completed")}
      </SubmitButtonSty>
    </>
  )
}

const TdaoView = () => {
  const history = useHistory() 
  const { authType } = useParams<any>()
  const {profile,showCard,nftId,isOkMin,initData,tasks,isError,clearError}=useTdao(authType,authTypeArr)
  const { loginState } = useStarterWallet()
  const {onMore,lists,isOver} = useTdaoLastInfo(20,TestApiUrl)

  const bindContact = async () => {
    const result = new URLSearchParams(window.location.search)
    const oauth_token = result.get("oauth_token")
    const oauth_verifier = result.get("oauth_verifier")
    const code = result.get("code")
    const state = result.get("state")

    const params = {
      oauth_token,oauth_verifier,code,state 
    }
    try {
      const {code} = await apiUrlBind[authType](params,TestApiUrl)
      if(code === 404){
        Toast.error(t('This account already exists'))
      }
      history.push("/tdao")
    } catch (e) {
      history.push("/tdao")
    }
  }

  useEffect(() => {
    loginState === LoginState.init && history.push("/login")
  }, [loginState])

  useEffect(() => {
    if (authTypeArr.includes(authType)) {
      bindContact()
    }
  }, [])

  const {t,i18n}=useTranslation()
  // console.log({isOkMin,isError,showCard},isOkMin !== null)
  return (
    <Wrapper>
      <Spaced size="72" />
      <ContainerWrapper>
        <ContainerLeft>
          <PageTitle> {t('What are Tusima points?')}</PageTitle>
          <Spaced size="30" />
          <PageContxt>
          {t('Tusima points are used to measure the contribution of community members to the construction of the Tusima network. The more points a member has, the greater their contribution and activity. Points hold high community equity value.')} 
          </PageContxt>
          <Spaced size="60" />
          <PageTitle>{t('How do points work?')}</PageTitle>
          <Spaced size="30" />
          <PageContxt>
            {/* <span>{t('Earn points')}</span> */}
            <Spaced size="30" />
            <p>
            {t('Points serve as proof of community contribution. After the mainnet launch, points can be exchanged for Tusima community governance tokens, as well as freely exchanged for community derivatives, NFTs, USDT, or other tokens. The specific rules for this need to be voted on by the community for discussion in the future.')}
            </p>
            {/* <Spaced size="30" /> */}
            {/* <span>{t('Point value')}</span> */}
            <Spaced size="30" />
            <p>
            {t("We aim to fairly reward community members who contribute to Tusima's ecological development and construction by measuring their contribution points. Therefore, a member's free exchange privileges increase as their points total increases.")}
            </p>
          </PageContxt>
        </ContainerLeft>
        <ContainerRight>
          {
            <NFTCardBox
              style={{ backgroundImage: `url(${profile.img || NftbgIcon})` }}>
              {profile.img ? "" : <RateTopImg src={RateTopIcon} />}
              {profile.img ? "" : <NFTCardTit>{profile.name}</NFTCardTit>}
              <Spaced size="48" />
              {profile.img ? "" : <NFTCardIcon src={profile.img || NftImg} />}
              <Spaced size="35" />
              {profile.img ? "" : <NFTCardTxt>{t(profile.role)}</NFTCardTxt>}
            </NFTCardBox>
          }
          {showCard ? (
            <CardInfo nftId={nftId} profile={profile} />
          ) : (
            <>
              {isOkMin !== null ? (
                <SubmitButton onSuccess={initData} disabled={!isOkMin} />
              ) : (
               isError ? <SubmitButtonSty onClick={clearError}>{t('Click to reload')}</SubmitButtonSty>:
               <SubmitButtonSty load={true}>Loading...</SubmitButtonSty> 
              )}
              <CheckoutLine>
                {tasks === null ? (
                 isError ? '':<ValueSkeleton height={54} width={415} /> 
                ) : (
                  CheckoutOptions.map((itm, key) => {
                    return (
                      <CheckoutItem key={key} style={{ width: itm.width }}>
                        <Checkout
                          title={t(itm.title)}
                          checkout={tasks[itm.type]}
                        />
                      </CheckoutItem>
                    )
                  })
                )}
              </CheckoutLine>
            </>
          )}
        </ContainerRight>
      </ContainerWrapper>
      <ContainerLastBox>
      <InformationTit>{t('Latest Information')}</InformationTit>
      <Spaced size="30"/>
      <InformationXian/>
     {lists === null || lists.length === 0 ? <NodataBox lists={lists}/>:lists.map((item:any,key:number)=>{
      const [cn,en,jp]=String(item.description).split('||||')
      const obj={cn,en,jp}
      const val = Number(item.after) - Number(item.before)
      return <div key={key}>
        <InformationLine>
          <InformationVal className={val >=0?`add`:'sub'}>
          {val >=0?`+`:''}{val}
          </InformationVal> 
          <InformationItem dangerouslySetInnerHTML={{__html:obj[i18n.language]}} /> 
        </InformationLine>
       <InformationXian/>
      </div>
     })}
     <Spaced size="50"/>
    {
      isOver ? '':<MoreBtn onClick={onMore}>
      <span>{t('More')}</span>
     {/* <MoreImg src={}/>  */}
     </MoreBtn>
    } 
      </ContainerLastBox>
      <Spaced size="200" />
      {/* <DiscordModel url="https://discord.com/channels/965918503070728203/1011838365110378617"></DiscordModel> */}
    </Wrapper>
  )
}
export default TdaoView

const InformationLine=styled.div`
  display:flex ;
  align-items:center ;
  line-height:55px;
  font-size: 16px;
  font-family: Poppins-Regular, Poppins;
  font-weight: 400;
  color: #FFFFFF;
`
const InformationVal=styled.div`
min-width:120px;
padding-left:30px;
&.add{
    color:#18F195;
  }
  &.sub{
    color:#FFB200;
  }
`

const NodataBox:React.FC<{lists:any[]}>=({lists})=>{
  return <NodataSty>
    {lists === null ?<ValueSkeleton width={100}/> :<NoDataIcon />}
  </NodataSty>
}

const NodataIcon=styled.img`
  width:200px ;
  margin-top:100px;
`
const NodataSty = styled.div`
min-height:200px;
  display:flex ;
  align-items:center ;
  justify-content:center ;
`
const MoreImg=styled.img`

`
const MoreBtn=styled.div`
  cursor: pointer;
  margin:0 auto;
  width:60px;
  display:flex;
  align-items:center;
  justify-content:center ;
  user-select:none;
  span{
    font-size: 16px;
    font-family: Poppins-SemiBold, Poppins;
    font-weight: 600;
    color: #18F195;
  }
`
const ContainerLastBox=styled.div`
  width:1200px;
  margin:100px auto 0;
`
const InformationXian=styled.div`
  height:1px;
  background-color:#666666;
`
const InformationItem=styled.div`
`
const InformationTit=styled.div`
  font-size: 24px;
  font-family: Poppins;
  color: #FFFFFF;
  line-height: 24px;
`
const CardBox = styled.div`
  padding: 0 24px;
`
const CardLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const CardTit = styled.div`
  font-size: 16px;
  font-family: Poppins-Medium, Poppins;
  font-weight: 500;
  color: #cccccc;
  line-height: 24px;
`
const CardVal = styled.div`
  font-size: 16px;
  font-family: Poppins-SemiBold, Poppins;
  font-weight: 600;
  color: #ffffff;
  line-height: 24px;
`
const ConnectBtn = styled.img`
  width: 88px;
  height: 28px;
  cursor: pointer;
`
const CheckoutLine = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 26px;
  margin-top: 32px;
`
const CheckoutItem = styled.div``

const NFTCardTxt = styled.div`
  font-size: 16px;
  font-family: Poppins-Medium, Poppins;
  font-weight: 500;
  color: #333333;
  line-height: 24px;
`
const NFTCardIcon = styled.img`
  width: 240px;
  margin: 0 auto;
`
const NFTCardTit = styled.div`
  font-size: 24px;
  font-family: Poppins;
  color: #0d0d0d;
  line-height: 24px;
`
const RateTopImg = styled.img`
  position: absolute;
  width: 80px;
  top: 0;
  right: 0;
`
const NFTCardBox = styled.div`
  padding: 50px;
  position: relative;
  width: 415px;
  height: 490px;
  /* background-image: url(${NftbgIcon}); */
  background-size: 100% 100%;
  text-align: center;
`
const PageContxt = styled.div`
  width: 705px;
  height: 192px;
  font-size: 16px;
  font-family: Poppins-Medium, Poppins;
  font-weight: 500;
  color: #cccccc;
  line-height: 32px;
  span {
    font-size: 18px;
    color: #fff;
  }
  p {
    margin: 0;
    padding: 0;
    line-height: 32px;
    /* text-indent: 42px; */
  }
`
const PageTitle = styled.div`
  font-size: 24px;
  font-family: Poppins;
  color: #ffffff;
  /* line-height: 24px; */
`
const ContainerLeft = styled.div``
const ContainerRight = styled.div`
  width: 415px;
`
const Wrapper = styled.div`
  width: auto;
  height: 100%;
  background-image: url(${WalletBg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
`

const SubmitButtonSty = styled(ButtonHighlights)`
  width: 415px;
  font-size: 11px;
  font-weight: 600;
  color: #292929;
  margin-top: 60px;
`

const ContainerWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 56px;
  color: #fff;
  display: flex;
  justify-content: space-between;
`

const ButtonConnect = styled(CardHight)`
  width: 110px;
  height: 22px;
  position:relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-direction: column; */
  border-radius: 20px;
  cursor: pointer;
  user-select: none;
  background-color: rgba(24, 241, 149, 0.15);
 
  strong{
    font-size:12px;
    font-weight:400;
    color:#fff;
  }
  span {
    font-size: 12px;
    font-weight: 400;
    color: #18f195;
    background: linear-gradient(90deg, #18f195 0%, #1fb7ff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &:hover {
    background-color: #fff;
    -webkit-background-clip: text;
    background: linear-gradient(90deg, #18f195 0%, #1fb7ff 100%);

    span {
      font-size: 12px;
      font-weight: 400;
      color: #18f195;
      background: #000;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
`;