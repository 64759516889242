import { useCallback } from "react";
import useStarterWallet from "./useStarterWallet";
import useHandleTransactionReceipt, { TranscationStatus } from "./useHandleTransactionReceipt";

const useFaucel = (faucelName:string,setLoading:any) => {
  const {
    starterCash,
    wallet,
    account: ethAddress,
    rollupWallet,
  } = useStarterWallet()
  const handleTransactionReceipt = useHandleTransactionReceipt();
  
  const handleFaucel = useCallback(
    () => {
      if(starterCash){
        setLoading(TranscationStatus.loading)
        console.log({starterCash})
        handleTransactionReceipt(starterCash.FaucetReceive(faucelName,ethAddress),setLoading)
      }
    },
    [starterCash, ethAddress, rollupWallet, wallet]
  )
  return { onFaucel: handleFaucel}
};

export default useFaucel;
