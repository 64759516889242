import React from "react";
import styled from "styled-components";
import None404 from "../assets/404.png";

const Maintain: React.FC = () => {
  return (
    <MainBox>
      <img width="1200" src={None404} alt="404" />
    </MainBox>
  )
}

export default Maintain;

const MainBox = styled.div`
  min-height: 100vh;
  width: 100vw;
  background-color: #1b1b1b;
  display: flex;
  align-items: center;
  justify-content: center;
`;
