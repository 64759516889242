import { ethers } from "ethers";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import config from "../../config";
import useStarterWallet from "../../hooks/useStarterWallet";
import { Bank } from "../../utils/poolsType";
import { getChainLng } from "../../utils/tools";

import Context from "./context";

const Banks: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [banksBSC, setBanksBSC] = useState<Bank[]>([]);
  const [banksETH, setBanksETH] = useState<Bank[]>([]);
  const { starterCashBSC,starterCashETH, account, chainId,loginState } = useStarterWallet();

  const fetchPoolsBSC = useCallback(async () => {
    const banks: Bank[] = [];
    for (const bankInfo of Object.values(config.BSC.bankDefinitions)) {
      banks.push({
        ...bankInfo,
        address: config['BSC'].deployments[bankInfo.contract].address,
        depositToken: starterCashBSC.externalTokens[bankInfo.depositTokenName],
        earnToken: starterCashBSC.externalTokens[bankInfo.earnTokenName],
      });
    }
    banks.sort((a, b) => (a.sort > b.sort ? 1 : -1));
    setBanksBSC(banks);
  }, [starterCashBSC, starterCashBSC?.isUnlocked, setBanksBSC]);


  const fetchPoolsETH = useCallback(async () => {
    const banks: Bank[] = [];
    for (const bankInfo of Object.values(config.ETH.bankDefinitions)) {
      banks.push({
        ...bankInfo,
        address: config['ETH'].deployments[bankInfo.contract].address,
        depositToken: starterCashETH.externalTokens[bankInfo.depositTokenName],
        earnToken: starterCashETH.externalTokens[bankInfo.earnTokenName],
      });
    }
    banks.sort((a, b) => (a.sort > b.sort ? 1 : -1));
    setBanksETH(banks);
  }, [starterCashETH, starterCashETH?.isUnlocked, setBanksETH]);
  useEffect(() => {
    if (starterCashETH) {
      fetchPoolsETH().catch((err) =>
        console.error(`Failed to fetch pools: ${err.stack}`)
      );
    }
  }, [starterCashETH, starterCashETH?.isUnlocked, fetchPoolsETH]);

  useEffect(() => {
    if (starterCashBSC) {
      fetchPoolsBSC().catch((err) =>
        console.error(`Failed to fetch pools: ${err.stack}`)
      );
    }
  }, [starterCashBSC, starterCashBSC?.isUnlocked, fetchPoolsBSC]);

  const banks = useMemo(()=>{
    return getChainLng(chainId) === 'BSC' ? banksBSC :banksETH 
  },[chainId,banksBSC,banksETH,loginState])
  return <Context.Provider value={{ banks }}>{children}</Context.Provider>;
};

export default Banks;
