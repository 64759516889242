export const readFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = function (event) {
      resolve(JSON.parse(event.target.result))
    }
  })
}
export function number_format(number, decimals = 6, dec_point, thousands_sep) {
  /*
   * 参数说明：
   * number：要格式化的数字
   * decimals：保留几位小数
   * dec_point：小数点符号
   * thousands_sep：千分位符号
   * */
  return Number(Number(number || 0).toFixed(decimals))
  number = (number + "").replace(/[^0-9+-Ee.]/g, "")
  var n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = typeof thousands_sep === "undefined" ? "" : thousands_sep,
    dec = typeof dec_point === "undefined" ? "." : dec_point,
    s = [],
    toFixedFix = function (n, prec) {
      var k = Math.pow(10, prec)
      // return String(Math.floor(n * k) / k)

      console.log({k})
      return String(Math.floor(n * k) / k)
    }

  s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".")

  console.log(s,sep,dec)
  if (sep) {
    var re = /(-?\d+)(\d{3})/
    while (re.test(s[0])) {
      s[0] = s[0].replace(re, "$1" + sep + "$2")
    }
  }

  if ((s[1] || "").length < prec) {
    s[1] = s[1] || ""
    s[1] += new Array(prec - s[1].length + 1).join("0")
  }
  return Number(s.join(dec))
}
export function hidehash(str, len = 4) {
  return str ? `${str.slice(0, len)}....${str.slice(-len)}` : ""
}
export function hideAddress(str) {
  return str && str.length > 11
    ? `${str.slice(0, 5)}....${str.slice(-6)}`
    : str
}
export function hide45(str) {
  return str ? `${str.slice(0, 4)}....${str.slice(-5)}` : ""
}
export function hide85(str) {
  return str ? `${str.slice(0, 8)}....${str.slice(-5)}` : ""
}
export function hide90(str) {
  return str ? `${str.slice(0, 45)}${str.slice(-45)}` : ""
}
export function hide42(str) {
  return str ? `${str.slice(0, 21)}${str.slice(-21)}` : ""
}
export function hide10(str) {
  return str ? `${str.slice(0, 5)}${str.slice(-5)}` : ""
}


export function hideMount(str) {
  return str ? `${JSON.stringify(str).slice(1, 12)}....` : ""
}



export function reserveNum(num) {
  const nums = num ? parseInt(num) : 0
  return nums ? `${nums.toFixed(2)}` : ""
}

export function toNonExponential(num) {
  if(!num){
    return 0
  }
  var m = num.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/)
  return num.toFixed(Math.max(0, (m[1] || "").length - m[2]))
}

