import React,{ useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import NoDataImg from '../../assets/home/nodata@2x.png'
import { Spaced } from "../Spaced";


const NoDataIcon = ({size=196,title="No Data here..."}) => {
  const {t}=useTranslation()
  return (
      <EmptyWrapper>
        <Spaced size={String(size/2)}/>
        <img width={size} src={NoDataImg} alt="" />
       <EmptyTitle>{t(title)}</EmptyTitle> 
       <Spaced size={String(size/3)}/>
      </EmptyWrapper>

  );
};


export default NoDataIcon

const EmptyTitle=styled.div`
  font-size:17px;
  color:#fff ;
`
const EmptyWrapper = styled.div`
      text-align: center;
      /* img{
        width: 260px;
        height:266px;
      } */
`

