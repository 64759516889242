import React from "react";

import styled from "styled-components";
import { hideAddress, number_format } from "../../../utils/utils";
import { formatListType, fromatNumber } from "../../../utils/tools";
import {Trans} from  "react-i18next";
const myhistorylists = [
  {
    title: "Type",
    dataIndex: "type",
    render: (record: any) => <Trans>{formatListType(record)}</Trans>,
  },
  {
    title: "Hash",
    dataIndex: "hash",
    render: (record: any) => <>{hideAddress(record)}</>,
  },
  {
    title: "Amount",
    dataIndex: "amount",
    render: (record: any, item: any) => {
      if (!isNaN(record)) {
        return <>{number_format(record,6)} {item.coin}</>;
      }
    },
  },
  {
    title: "From",
    dataIndex: "fromEthAddr",
    render: (record: any) => <>{hideAddress(record)}</>,
  },
  {
    title: "To",
    dataIndex: "toEthAddr",
    render: (record: any) => <>{hideAddress(record)}</>,
  },

  {
    title: "Fee",
    dataIndex: "fee",
    render: (record: any, item: any) => {
      if (!isNaN(record)) {
        return (
          <>
            {fromatNumber(record)} {item.coin}
          </>
        );
        // return <>{ethers.utils.formatEther(BigNumber.from(record))} {item.coin}</>
      }
    },
  },
  {
    title: "Network",
    dataIndex: "type",
    render: (record: any,item:any) => <>{formatListType(record) === 'Send' ? 'Tusima':item.chainName}</>,
  },
];

export default myhistorylists;
// eslint-disable-next-line
const Block = styled.div`
  color: #18f195;
`;
