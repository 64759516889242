import lastblock from './lastblock';
import lasttransaction from './lasttransaction';
import mytransaction from './mytransaction';
import myhistorylists from './myhistorylists';
export const block = {
  lastblock,
  lasttransaction,
  mytransaction,
  myhistorylists
};
