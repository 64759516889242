import styled from "styled-components"
import { DownOutlined } from "@ant-design/icons"
import GlobalIcon from "../../../assets/home/global@2x.png"
import { useTranslation } from "react-i18next"
import React, { useState } from "react"

export default function LangIcon() {
  const { i18n } = useTranslation()
  const [show,setShow] = useState(false)
  const onClick = (v: string) => {
    i18n.changeLanguage(v)
    setShow(false)
  }
  const onChange=(val)=>{
setShow(val)
  }
  const langeArr=[
    {
      title:'English',
      lng:'en'
    },
    {
      title:'日本語',
      lng:'jp'
    }
  ]
  return (
    <LangBox>
      <li className={`kong ${show ? 'show':''}`} onMouseLeave={(e)=>{
        onChange(false) 
      }} onMouseEnter={(e)=>{
       onChange(true) 
      }}>
        <div className="menu-list-paper">
          <GlobalImgBox src={GlobalIcon} />
          <DownOutlined className="icon" />
          <div className="middleArrow"></div>
        </div>
        <div className="subList vs">
          {langeArr.map((item,key)=><div key={key} onClick={() => onClick(item.lng)} className={`subItem vc ${i18n.language === item.lng?'active':''}`}>
            <span>{item.title}</span>
          </div>)}
        </div>
      </li>
    </LangBox>
  )
}

const LangBox = styled.ul`
  li {
    position: relative;
    &.kong {
      padding: 0;
      /* margin-left: 30px; */
      height: 32px;
      display: flex;
      align-items: center;
    }
    :hover {
      .icon {
        margin-left: 10px;
        transition: all 0.5s;
        transform: rotate(-180deg);
      }
    }
    .menu-list-paper {
      display: flex;
      align-items: end;
      color: #fff;
      .icon {
        margin-left: 10px;
        transition: all 0.5s;
        margin-bottom:5px;
      }
      .middleArrow {
        display: none;
        width: 150px;
        height: 45px;
        background-color: none;
        position: absolute;
        top: 29px;
        left: 50%;
        margin-left: -75px;
        span {
          background: #1c2129;
          width: 15px;
          height: 15px;
          position: absolute;
          bottom: 0;
          left: 50%;
          margin-left: -8px;
          transform: rotate(45deg);
        }
      }
    }
    &.show {
      .subList {
        display: block;
      }
      .middleArrow {
        display: block;
      }
    }
    .subList {
      width: 360px;
      height: 380px;
      position: absolute;
      top: 36px;
      left: -40px;
      background: #000000;
      display: none;
      z-index: 11;
      color: #fff;
      padding: 15px;
      border-radius: 12px;
      border: 1px solid #181818;
      backdrop-filter: blur(9px);
     
      .vc {
        width: 100%;
        line-height: 30px;
        cursor: pointer;
        user-select: none;

        &.active{
        color: #18f195;
      }
        &:hover {
          color: #18f195;
        }
      }
      &.vs {
        width: 120px;
        left: -66px;
        top: 45px;
        height: auto;
        background: #000000;
        border-radius: 6px;
        border: 1px solid rgba(255, 255, 255, 0.2);
      }
    }
  }
`
const GlobalImgBox = styled.img`
  width: 24px;
`
