import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { Provider } from "react-redux";
import "./App.css";
import { createWeb3ReactRoot, Web3ReactProvider } from "@web3-react/core";

import { getServerStatus } from "./utils/fetch/login";
import getLibrary from "./utils/getLibrary";
import DepositView from "./views/deposit-view";
import WithdrawView from "./views/withdraw-view";
import WalletView from "./views/wallet-view";
import SendView from "./views/send-view";
import HomePage from "./views/homePage";
import BlockDetails from "./views/BlcokDetails";
import LoginPage from "./views/Login";
import FaucelPage from "./views/Faucel";
import TDAOPage from "./views/tdao-view";
import TDAOALLPage from "./views/tdao-all-view";
import TransactionDetails from "./views/TransactionDetails";
import AccountDetails from "./views/AccountDetails";
import { NetworkContextName } from "./constants";
import StarterProvider from "./contexts/StarterProvider";
import BanksProvider from "./contexts/BanksProvider";
import NodataPage from "./views/Nodata";
import Updaters from './state/Updaters';
import Popups from "./components/Popups";
import store from "./state";
import NavHeader from "../src/components/Header/NavHeader.tsx";
import config from "./config";
import Maintain from "./components/Maintain";
import './i18n'

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName);

console['error'] = ()=>{}
const App =()=> {

  const [siteOff,setSiteOff] = useState(false)
  const serverStatus=async()=>{
    const {data} = await getServerStatus({})
     if(data){
      setSiteOff(data)
    //   Toast.error(`<div>
    //   <div>So hot! ! !</div>
    // <div>Service is starting. . .</div>
    // </div>`)
     }else if(siteOff && !data){
      setSiteOff(data)
     }
  }

  useEffect(()=>{
      // serverStatus().catch(console.error)
      // const timeId = setInterval(() => serverStatus().catch(console.error), config.refreshInterval)
      // return () => clearTimeout(timeId)
  },[siteOff])
  if(siteOff){
    return <Maintain/>
  }
 return (
  <>
    <Provider store={store}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Web3ProviderNetwork getLibrary={getLibrary}>
          <StarterProvider>
            <BanksProvider>
              <Updaters />
              <Popups />
              <NavHeader></NavHeader>
              <Route exact path="/" render={() => <TDAOALLPage />} />
               {/* <Route exact path="/" render={() => <HomePage />} /> */}
              <Route exact path="/explorer" render={() => <HomePage />} />
              <Route
                exact
                path="/deposits/:token"
                render={() => <DepositView />}/>
              <Route
                exact
                path="/withdraw/:token"
                render={() => <WithdrawView />}/>
              <Route
                exact
                path="/send/:token"
                render={() => <SendView />}/>
              <Route exact path="/wallet" render={() => <WalletView />} />

              <Route
                exact
                path="/explore/blockdetail/:id"
                render={() => <BlockDetails />}/>
              <Route
                exact
                path="/explore/transactiondetail/:id"
                render={() => <TransactionDetails />}/>
              <Route
                exact
                path="/explore/accountdetail/:id/:chain"
                render={() => <AccountDetails />}/>
                <Route
                exact
                path="/points"
                render={() => <TDAOALLPage />}/>
              <Route
                exact
                path="/tdao/:authType"
                render={() => <TDAOPage />}/>
              <Route
                exact
                path="/tdao"
                render={() => <TDAOPage />}/>
              <Route
                exact
                path="/explore/nodata"
                render={() => <NodataPage />}/>
              <Route exact path="/login" render={() => <LoginPage />} />
              <Route exact path="/faucet" render={() => <FaucelPage />} />
            </BanksProvider>
          </StarterProvider>
        </Web3ProviderNetwork>
      </Web3ReactProvider>
    </Provider>
  </>
); 
}

export default App;
