import React from 'react';

import styled from 'styled-components'

import BNBLogo from '../../assets/symbol/BNB@2x.png';
import ETHLogo from '../../assets/symbol/ETH@2x.png';
import USDTLogo from '../../assets/symbol/USDT@2x.png';
import TSMLogo from '../../assets/symbol/TSM@2x.png'
import USDCLogo from '../../assets/symbol/USDC@2x.png'
import testJPY from '../../assets/symbol/testJPY@2x.png'

export const logosBySymbol: {[title: string]: string} = {
  "wBNB":BNBLogo,
  "wETH":ETHLogo,
  "ETH":ETHLogo,
  "FSC":ETHLogo,
  "USDT":USDTLogo,
  "TSM":TSMLogo,
  "USDC":USDCLogo,
  "testJPY":testJPY,

  "wBNB_V2":BNBLogo,
  "wETH_V2":ETHLogo,
  "ETH_V2":ETHLogo,
  "FSC_V2":ETHLogo,
  "USDT_V2":USDTLogo,
  "TSM_V2":TSMLogo,
  "USDC_V2":USDCLogo,
  "testJPY_V2":testJPY,
}

type StarterLogoProps = {
  symbol: string;
  size?: number;
  msize?:number;
}

export const getTokenSymbol=(symbol:string)=>{
  if (!logosBySymbol[symbol]) {
    // throw new Error(`Invalid RabbitLogo symbol: ${symbol}`);
    return symbol
  } 
  return logosBySymbol[symbol]
}
const TokenSymbol: React.FC<StarterLogoProps> = ({ symbol, size = 50,msize=size }) => {
 const icon = getTokenSymbol(symbol) 
  return (
    <ImgBox
      src={icon}
      alt={`${symbol} Logo`}
      size={size}
      msize={msize}
    />
  )
};

export default TokenSymbol;
const ImgBox=styled.img<{size:number,msize:number}>`
  width:${props=>props.size}px;
  height:${props=>props.size}px;
  user-select:none;
  @media (max-width: 1000px) {
      width:${props=>props.msize}px;
      height:${props=>props.msize}px;
  }
`