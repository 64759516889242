import React, { useCallback, useEffect, useMemo, useState } from "react";

import styled from "styled-components";
import useStarterWallet, { LoginState } from "../../../hooks/useStarterWallet";
import { useHistory, useParams } from "react-router-dom";
import { CardHight } from "../../../components/Card";
import { SelectPrimary, SelectHighlights } from "../../../components/Select";
import { Spaced } from "../../../components/Spaced";
import Input from "../../Login/components/Input";
import { ButtonHighlights } from "../../../components/Button";
import useBanks from "../../../hooks/useBanks";
import ValueSkeleton from "../../../components/ValueSkeleton";
import useApprove, { ApprovalState } from "../../../hooks/useApprove";
import { Bank } from "../../../utils/poolsType";
import useDeposit from "../../../hooks/useDeposit";
import DownImg from "../../../assets/connect/down@2x.png";
import DiscordModel from "../../../components/DiscordModel";
import { number_format } from "../../../utils/utils";
import { TranscationStatus } from "../../../hooks/useHandleTransactionReceipt";
import config from "../../../config";
import { ethers } from "ethers";
import SuccessPage from "../../../components/SuccessPage";
import Toast from "../../../components/Toast";
import WalletBg from "../../../assets/home/wallet_b.png";
import LoadPage from "../../../components/LoadPage";
import { postSbtReg } from "../../../utils/fetch/login";
import { getChainLng, getDisplayBalance } from "../../../utils/tools";
import { useTranslation } from "react-i18next";

const SubmitButton: React.FC<{
  bank: Bank;
  amount: string;
  onSuccess: any;
  disabled: boolean;
}> = ({ bank, amount, onSuccess, disabled }) => {
  const {t}=useTranslation()
  const [loading, setLoading] = useState(false)
  const [hash, setHash] = useState("")
  const { chainId } = useStarterWallet()
  const { setAccountLoading,account } = useStarterWallet()
  const [approveStatus, approve] = useApprove(
    bank.depositToken,
    bank.address,
    amount,
    setLoading
  )
  const cnf = useMemo(() => {
    return config[getChainLng(chainId)]
  }, [chainId, config])
  
  const { onDeposit } = useDeposit(
    bank,
    async (status: TranscationStatus, hash: string) => {
      if (TranscationStatus.loading === status) {
        setLoading(true)
        setAccountLoading(true)
      } else if (
        [TranscationStatus.pengding, TranscationStatus.await].includes(status)
      ) {
        setAccountLoading(true)
        setHash(hash)
        Toast.pending(`${t('Pending')}...`,10000,null,`${cnf.etherscanUrl}/tx/${hash}`)
      } else if (TranscationStatus.success === status) {
        setLoading(false)
        setAccountLoading(false)
        onSuccess(hash)
        const res = await postSbtReg({
          addr: account,
          optype: 'deposit',
          txhash: hash,
          token:bank.tokenId
        })
        // console.log('save...',res,{
        //   addr: account,
        //   optype: 'deposit',
        //   txhash: hash
        // })
        Toast.success(t("Succeeded"),10000,null,`${cnf.etherscanUrl}/tx/${hash}`)
      } else if (TranscationStatus.error === status) {
        // Toast.error("Failed")
        // Toast.pending("Pending...",10000,null,`${cnf.etherscanUrl}/tx/${hash}`)
        // Toast.success("Failed",10000,null,`${cnf.etherscanUrl}/tx/${hash}`)
        setLoading(false)
        setAccountLoading(false)
      }
    }
  )
  

  

  // console.log({approveStatus})

  if (approveStatus === ApprovalState.UNKNOWN) {
    return <SubmitButtonSty load={true}>{t('Loading')}</SubmitButtonSty>;
  } else if (
    [ApprovalState.NOT_APPROVED, ApprovalState.PENDING].includes(approveStatus)
  ) {
    return (
      <>
        <SubmitButtonSty disabled={disabled} load={loading} onClick={approve}>
         {t('Approve')} 
        </SubmitButtonSty>
      </>
    );
  }
  return (
    <SubmitButtonSty
      disabled={disabled}
      load={loading}
      onClick={async () => {
        try{
          onDeposit(amount)
        }catch(e){
          console.error(e)
        }
      }}
    >
      {t('Deposit')}
    </SubmitButtonSty>
  );
};

const ContentPage = ({ onSuccess }) => {
  const {
    loginState,
    starterCash,
    chainId,
    onChangeChain,
    ethPrice,
    gasPrice,
    allTokens,
    account,
  } = useStarterWallet()
  const { token } = useParams<any>()
  const { banks } = useBanks()
  
  const [tokenId, setTokenId] = useState(Number(token))
  const [amount, setAmount] = useState("")
  const [balance, setBalance] = useState(null)

  const disabled = useMemo(() => !amount, [amount])

  const bank = useMemo(() => {
    const item = banks.find((itm) => itm.tokenId === tokenId)
    return item
  }, [tokenId,loginState])

  const tokensTotal = useMemo(() => {
    return allTokens.find((itm) => itm.tokenId === bank?.tokenId)?.number || 0
  }, [allTokens, bank,loginState])

  const onChangeTokens = useCallback((id: any) => {
      setTokenId(id)
    },[tokenId])

  const onChangeInput = (e: any) => {
    let { value } = e.target
    const reg = /^-?\d*(\.\d*)?$/
    if ((!isNaN(value) && reg.test(value)) || value === "") {
      if (value > Number(balance)) {
        value = Number(balance)
      }
      const arr = String(value).split('.')
      if(arr.length === 2 && arr[1].length > 6){
        return
      }
      setAmount(value)
    }
  }

  const cnf = useMemo(()=>{
    return config[getChainLng(chainId)]
  },[chainId])

  const UserChainsLists = useMemo(() => {
    return [
      {
        title: cnf.fullChainName,
        value: cnf.smallChain,
      },
      // {
      //   title: "Ethereum Ropsten",
      //   value: 56,
      // },
    ];
  }, [starterCash,cnf]);

  const updateBalance = async () => {
    if (bank) {
      const balance = await bank.depositToken.balanceOf(account)
      
      setBalance(number_format(getDisplayBalance(balance,bank.depositToken.decimal), 6))
    }
  };

  useEffect(() => {
    updateBalance().catch(console.error)
    
    const timeId = setInterval(() => {
      updateBalance().catch(console.error)
    }, config.refreshInterval)

    return () => {
      clearTimeout(timeId)
    }
  }, [bank, account,loginState])

  const onMax = useCallback(() => {
    const max = String(balance)
    onChangeInput({ target: { value: max } })
  }, [balance])

  

  const {t}=useTranslation()
  return (
    <>
      <CardBox bgcolor="#666">
        <CardLeft>
          <SelectPrimary
            width={160}
            value={getChainLng(chainId)}
            onChange={onChangeChain}
            options={UserChainsLists}
          />
          <Spaced size="21" />
          <SelectHighlights
          deposit
            value={tokenId}
            onChange={onChangeTokens}
            options={banks.filter(itm=>itm.version === bank.version)}
          />
        </CardLeft>
        <CardRight>
          <UserInputLine>
            <CardInput
              noerr
              placeholder="0.00"
              onChange={onChangeInput}
              value={amount}
            />
          </UserInputLine>
          <Spaced size="11" />
          <UserInputLine>
            <BalanceSty>
              <span>{t('Balance')}: </span>
              {balance !== null ? (
                <span>{balance}</span>
              ) : (
                <ValueSkeleton width={60} />
              )}
            </BalanceSty>
            <MaxBnt onClick={onMax}>{t('MAX')}</MaxBnt>
          </UserInputLine>
        </CardRight>
      </CardBox>
      <DownBox>
        <DownIcon src={DownImg} />
      </DownBox>
      {/* <Spaced size="45" /> */}
      <CardBox bgcolor="#666">
        <CardLeft>
          <span>Tusima Layer 2</span>
        </CardLeft>
        <CardRight>
          <BalanceSty>
            <span>{t('Balance')}: </span>
            <span>{number_format(tokensTotal, 6)}</span>
          </BalanceSty>
        </CardRight>
      </CardBox>
      <FeesBox>
        {t('Max fee')}: ~${number_format(Number(gasPrice) * Number(ethPrice), 6)} |{" "}
        {number_format(gasPrice, 6)} ETH
      </FeesBox>
      {bank ? (
        <SubmitButton
          disabled={disabled}
          onSuccess={onSuccess}
          bank={bank}
          amount={amount}
        />
      ) : (
        ""
      )}
    </>
  );
};
const DepositViewView = () => {
  const [hash, setHash] = useState("")
  const {t} = useTranslation()
  const { chainId,loginState } = useStarterWallet()
  const history = useHistory()
  const cnf = useMemo(() => {
    return config[getChainLng(chainId)]
  }, [chainId, config])

  const toHref = useMemo(() => {
    return `${cnf.etherscanUrl}/tx/${hash}`
  }, [hash])

  const onSuccess = (hash: string) => {
    setHash(hash)
  }
  useEffect(() => {
    loginState === LoginState.init && history.push("/login")
  }, [loginState])
  return (
    <Wrapper>
      <ContainerWrapper>
      <Spaced size="80"/>
        <DepositBox>
          <CardTitle>{t('Deposit')}</CardTitle>
          {loginState === LoginState.loading ? <LoadPage/> : hash ? (
            <SuccessPage hash={hash} to={toHref} />
          ) : (
            <ContentPage onSuccess={onSuccess} />
          )}
        </DepositBox>
        {/* <DiscordModel url="https://discord.com/channels/965918503070728203/1011838365110378617"></DiscordModel> */}
      </ContainerWrapper>
    </Wrapper>
  );
};
export default DepositViewView;
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${WalletBg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
`;

const DownBox = styled.div`
  text-align: center;
`;
const DownIcon = styled.img`
  width: 15px;
  margin: 15px;
`;
const CardTitle = styled.div`
  font-size: 15px;
  color: #ffffff;
  margin-bottom: 15px;
`;
const SubmitButtonSty = styled(ButtonHighlights)`
  width: 508px;
  font-size: 11px;
  font-weight: 600;
  color: #292929;
  margin: 45px auto 0;
`;
const FeesBox = styled.div`
  margin-right: 23px;
  font-size: 10px;
  font-weight: 400;
  color: #ffffff;
  line-height: 10px;
  text-align: right;
  margin-top: 15px;
`;
const MaxBnt = styled.div`
  margin-left: 10px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  color: #18f195;
  line-height: 12px;
  background: linear-gradient(90deg, #18f195 0%, #1fb7ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
const BalanceSty = styled.div`
  display: flex;
  align-items: center;
`;
const UserInputLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const CardInput = styled(Input)`
  border: none;
  .input {
    font-size: 27px;
    font-weight: 600;
    color: #ffffff;
    line-height: 27px;
    text-align: right;
  }
`;
const CardBox = styled(CardHight)`
  display: flex;
  justify-content: space-between;
  padding: 28px;
  align-items: center;
  min-height: 81px;
`;
const CardLeft = styled.div``;
const CardRight = styled.div``;
const ContainerWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 56px;
  color: #fff;
`;
const DepositBox = styled.div`
  width: 510px;
  margin: 0 auto;
`;
