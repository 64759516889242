import ReactEchars from "echarts-for-react";
import React, { useMemo } from "react";
import * as echarts from "echarts";

export default function EchartsBar({
  yData,
  type,
  data,
  xData,
}: {
  yData?: any;
  type?: string;
  data?: any;
  xData?: any;
}) {
  const reverseArray = (arr) => {
    const newArr = [];
    arr.forEach((element) => {
      newArr.unshift(element);
    });
    return newArr;
  };

  const option = useMemo(() => {
    const optionDefault = {
      backgroundColor: "#000",
      tooltip: {
        trigger: "axis",
        backgroundColor: "#18F195",
        borderRadius: "4",
        borderWidth: "1",
        borderColor: "#18F195",
        color: "#000",
        textStyle: {
          textAlign: "left",
          color: "#000",
        },
        axisPointer: {
          lineStyle: {
            color: "#57617B",
          },
        },
        formatter: function (params: any) {
          var tip = "";
          if (params != null && params.length > 0) {
            tip +=
              `&bull; ${params[0].data}` +
              "<br />" +
              `&bull; ${params[0].axisValue}`;
            for (var i = 0; i < params.length - 1; i++) {
              tip +=
                `&bull; ${params[i].data}` +
                "<br />" +
                `&bull; ${params[i].axisValue}`;
            }
          }
          return tip;
        },
      },
      grid: {
        left: "0",
        top: "40px",
        bottom: "0",
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          inverse:true,
          boundaryGap: false,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            margin: 18,
            textStyle: {
              fontSize: 12,
            },
          },
          data: yData,
        },
        {
         
          axisTick: {
            show: false,
          },
          axisPointer: {
            show: false,
          },
          axisLine: {
            show: false,
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            margin: 10,
            textStyle: {
              fontSize: 12,
            },
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: "rgba(255,255,255,.26)",
            },
          },
        },
      ],
      series: [
        {
          type: "line",
          smooth: true,
          symbol: "circle",
          symbolSize: 10,
          showSymbol: false,
          lineStyle: {
            normal: {
              width: 2,
            },
          },
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: "rgba(24,241,149,0.16)",
                  },
                  {
                    offset: 1,
                    color: "rgba(24,241,149,0)",
                  },
                ],
                false
              ),
              shadowColor: "rgba(0, 0, 0, 0.1)",
              shadowBlur: 10,
            },
          },
          itemStyle: {
            normal: {
              color: "#18F195",
              borderColor: "#fff",
              borderWidth: 1,
            },
          },
          data: xData,
        },
      ],
    };
    return optionDefault;
    // eslint-disable-next-line
  }, [data, xData, type, yData]);

  return <ReactEchars option={option} notMerge={true} />;
}
