import React, { useEffect, useRef } from "react";
import { Input, Button } from "antd";
import searchIcon from "../../assets/home/search.png";
import toright from "../../assets/home/toright.png";
import styled from "styled-components";
import { getSearch } from "../../utils/fetch/homepage";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CommonInput = (props: any) => {
  const { width } = props;
  const inputRef = useRef<any>();
  const history = useHistory();
  const btnRef = useRef();
  const serchFun = () => {
    const params = { keyword: inputRef.current?.state?.value };
    getSearch(params).then((res: any) => {
      // console.log("chengong");

      const { code, data } = res || {};
      if (code === 200) {
        switch (data?.type) {
          case "block":
            history.push(`/explore/blockdetail/${data?.id}`);
            break;
          case "transaction":
            history.push(`/explore/transactiondetail/${data?.id}`);
            break;
          case "address":
            history.push(`/explore/accountdetail/${data?.id}`);
            break;
        }
      } else {
        history.push(`/explore/nodata`);
      }
    });
  };
  const keyUp = (e) => {
    if (e.keyCode === 13) {
      // alert(`按了回车键，msg值为：${e.target.value}`);
    }
  };
  useEffect(() => {
    // console.log(btnRef.current);
  }, []);

  const {t}=useTranslation()
  return (
    <CommonSearch inputwidth={width}>
      <div className="common-input">
        <Input
          placeholder={t('Search by Block/Tx Hash')}
          ref={inputRef}
        />
        <img src={searchIcon} alt="" />
      </div>
      <div className="common-btn">
        <Button onClick={serchFun} onKeyUp={keyUp}>
          <div className="common-toright">
            <img src={toright} alt="" />
          </div>
        </Button>
      </div>
    </CommonSearch>
  );
};

export default CommonInput;

const CommonSearch = styled.div<{ inputwidth?: string }>`
  display: flex;
  margin-right: 63px;
  justify-content: center;
  margin-top:-20px;
  .common-input {
    min-width: ${({ inputwidth }) => (inputwidth ? inputwidth : "747px")};
    position: relative;
    z-index: 10;
    .ant-input {
      width: 100%;
      height: 60px;
      border-radius: 8px;
      background: #000;
      color: #fff;
      padding-left: 49px;
      border: 1px solid rgba(48, 248, 191, 1);
      border-right: 0px;
      &::placeholder {
        color: rgba(255, 255, 255, 0.4) !important;
      }
    }
    img {
      position: absolute;
      left: 21px;
      top: 22px;
      width: 16px;
      height: 16px;
    }
  }
  .common-btn {
    position: relative;
    z-index: 8;
    .ant-btn {
      width: 75px;
      height: 100%;
      position: absolute;
      right: -63px;
      border: transparent;
      border-radius: 8px;
      background: linear-gradient(to left, #4742de 0%, #30f8bf 70%);
    }
    .common-toright {
      width: 24px;
      height: 17px;
      margin-left: 15px;
      img {
        width: 100%;
      }
    }
  }
`;
