import abiRollup from './abiRollup.json';
import abiShuiPool from './abifaucel.json';
import { BankInfo, Deployments, VersionType } from './poolsType';


export const externalTokens: { [contractName: string]: [string, number] } = {
  wETH: ['0x30C0DEA3eA77b8Ef4Cf4D800596dec6aFa91C0DD', 18],
  TSM: ['0xdcE744847D3713f78d854Da5eC2BDD35b3FF28Cd', 18],
  USDT: ['0x8CB4e969668E792E5F429C2735404e27f0d087Ca', 18],
  USDC: ['0x49D3f361Ed09443EcD60612b9877f9B565C74bec', 18],
  wBNB: ['0x50D05EDf3aB8a10106B8833a62ecDfa2bbB07296', 18],
  testJPY:['0xbb65aFe7121B417348DA1bB7fbC7c0c946516Cce',8],

  wETH_V2: ['0x30C0DEA3eA77b8Ef4Cf4D800596dec6aFa91C0DD', 18],
  TSM_V2: ['0xdcE744847D3713f78d854Da5eC2BDD35b3FF28Cd', 18],
  USDT_V2: ['0x8CB4e969668E792E5F429C2735404e27f0d087Ca', 18],
  USDC_V2: ['0x49D3f361Ed09443EcD60612b9877f9B565C74bec', 18],
  wBNB_V2: ['0x50D05EDf3aB8a10106B8833a62ecDfa2bbB07296', 18],
  testJPY_V2:['0xbb65aFe7121B417348DA1bB7fbC7c0c946516Cce',8]
}

export const deployments: Deployments = {
  ETHPool:{
    'address':'0xeB2Ed9840Fb05C463E74CFf77663d0bf16d507FB',
    'abi':abiRollup
  },
  USDTPool:{
    'address':'0xeB2Ed9840Fb05C463E74CFf77663d0bf16d507FB',
    'abi':abiRollup
  },
  BNBPool:{
    'address':'0xeB2Ed9840Fb05C463E74CFf77663d0bf16d507FB',
    'abi':abiRollup
  },
  USDCPool:{
    'address':'0xeB2Ed9840Fb05C463E74CFf77663d0bf16d507FB',
    'abi':abiRollup
  },
  TSMPool:{
    'address':'0xeB2Ed9840Fb05C463E74CFf77663d0bf16d507FB',
    'abi':abiRollup
  },
  testJPYPool:{
    'address':'0xeB2Ed9840Fb05C463E74CFf77663d0bf16d507FB',
    'abi':abiRollup
  },
  ShuiPool:{
    'address':'0x88E14c726Ee6b2E8aa044ed1a3E0e1E118E7cd94',
    'abi':abiShuiPool
  },
  testJPYFaucet:{
    'address':'0x934BA07E42F32739308B3BB0a54289cDcf1917F8',
    'abi':abiShuiPool
  }
}

export const tokens721: { [contractName: string]: [string, number] } = {
  'NFTToken':['0x2960bABBB5D5427A425331DB29B1Ed4EAefc3Cf7',18] 
}

export const bankDefinitions: BankInfo[] = [
  {
    name: 'wETH',
    contract: 'ETHPool',
    tokenId:0,
    depositTokenName: 'wETH',
    earnTokenName: 'wETH',
    finished: false,
    fee:'0.001%',
    sort: 1,
    minVal:0.001,
    stepVal:0.001,
    display:true,
    version:VersionType.V1
  },
  {
    name: 'TSM',
    contract: 'TSMPool',
    tokenId:1,
    depositTokenName: 'TSM',
    earnTokenName: 'TSM',
    finished: false,
    fee:'0.005%',
    sort: 2,
    minVal:1,
    stepVal:0.5,
    display:true,
    version:VersionType.V1
  },
  {
    name: 'USDT',
    contract: 'USDTPool',
    tokenId:2,
    depositTokenName: 'USDT',
    earnTokenName: 'USDT',
    finished: false,
    fee:'0.005%',
    sort: 3,
    minVal:1,
    stepVal:0.5,
    display:true,
    version:VersionType.V1
  },
  {
    name: 'USDC',
    contract: 'USDCPool',
    tokenId:3,
    depositTokenName: 'USDC',
    earnTokenName: 'USDC',
    finished: false,
    fee:'0.005%',
    sort: 4,
    minVal:1,
    stepVal:0.5,
    display:true,
    version:VersionType.V1
  },
  {
    name: 'wBNB',
    contract: 'BNBPool',
    tokenId:4,
    depositTokenName: 'wBNB',
    earnTokenName: 'wBNB',
    finished: false,
    fee:'0.002%',
    sort: 5,
    minVal:0.01,
    stepVal:0.01,
    display:true,
    version:VersionType.V1
  },
  {
    name: 'testJPY',
    contract: 'testJPYPool',
    tokenId:6,
    depositTokenName: 'testJPY',
    earnTokenName: 'testJPY',
    finished: false,
    fee:'0.002%',
    sort: 6,
    minVal:1,
    stepVal:20,
    display:false,
    version:VersionType.V1
  },

  {
    name: 'wETH',
    contract: 'ETHPool',
    tokenId:0,
    depositTokenName: 'wETH_V2',
    earnTokenName: 'wETH',
    finished: false,
    fee:'0.001%',
    sort: 1,
    minVal:0.001,
    stepVal:0.001,
    display:true,
    version:VersionType.V1
  },
  {
    name: 'TSM',
    contract: 'TSMPool',
    tokenId:1,
    depositTokenName: 'TSM_V2',
    earnTokenName: 'TSM',
    finished: false,
    fee:'0.005%',
    sort: 2,
    minVal:1,
    stepVal:0.5,
    display:true,
    version:VersionType.V1
  },
  {
    name: 'USDT',
    contract: 'USDTPool',
    tokenId:2,
    depositTokenName: 'USDT_V2',
    earnTokenName: 'USDT',
    finished: false,
    fee:'0.005%',
    sort: 3,
    minVal:1,
    stepVal:0.5,
    display:true,
    version:VersionType.V1
  },
  {
    name: 'USDC',
    contract: 'USDCPool',
    tokenId:3,
    depositTokenName: 'USDC_V2',
    earnTokenName: 'USDC',
    finished: false,
    fee:'0.005%',
    sort: 4,
    minVal:1,
    stepVal:0.5,
    display:true,
    version:VersionType.V1
  },
  {
    name: 'wBNB',
    contract: 'BNBPool',
    tokenId:4,
    depositTokenName: 'wBNB_V2',
    earnTokenName: 'wBNB',
    finished: false,
    fee:'0.002%',
    sort: 5,
    minVal:0.01,
    stepVal:0.01,
    display:true,
    version:VersionType.V1
  },
  {
    name: 'testJPY',
    contract: 'testJPYPool',
    tokenId:6,
    depositTokenName: 'testJPY_V2',
    earnTokenName: 'testJPY',
    finished: false,
    fee:'0.002%',
    sort: 6,
    minVal:1,
    stepVal:20,
    display:false,
    version:VersionType.V1
  },
].filter(itm=>!itm.finished)