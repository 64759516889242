import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import useStarterWallet, { LoginState } from "../../../hooks/useStarterWallet";
import { Link, useHistory } from "react-router-dom";
import { Spaced } from "../../../components/Spaced";
import useBanks from "../../../hooks/useBanks";
import TokenSymbol from "../../../components/TokenSymbol";
import WebOpenIcon from "../../../assets/connect/webopen@2x.png";
import WebOpenIcon2 from "../../../assets/connect/webopen2@2x.png";
import QRCode from "qrcode.react";
import { Select } from "antd";
import useCopyClipboard from "../../../hooks/useCopyClipboard";
import { hidehash, number_format } from "../../../utils/utils";
import { Row, Col, Pagination } from "antd";
import DiscordModel from "../../../components/DiscordModel";
import CustomTable from "../../../components/CustomTable";
import { block } from "../../homePage/columns";
import ValueSkeleton from "../../../components/ValueSkeleton";
import config from "../../../config";
import WalletBg from "../../../assets/home/wallet_b.png";
import { tableList } from "../../../utils/fetch/homepage";
import Nodata from "../../../assets/home/noDataTable.png";
import LoadingIcon from "../../../components/Button/LoadingIcon";
import LoadPage from "../../../components/LoadPage";
import Copide from "../../../components/Copide";
import { formatListType } from "../../../utils/tools";
import { useTranslation } from "react-i18next";
import SelectV2 from "../../../components/SelectV2/SelectV2";
import { VersionType } from "../../../utils/poolsType";
const TokenItem = ({ item }) => {
  const { allTokens } = useStarterWallet()
  const tokens = useMemo(() => {
    return allTokens.find((itm) => itm.tokenId === item.tokenId)
  }, [item, allTokens])

  const { t } = useTranslation()
  return (
    <TokenTableLine>
      <TokenTableItem span={6}>
        <TokenSymbol size={28} symbol={item.depositToken.symbol} />
        <span>{item.name}</span>
      </TokenTableItem>
      <TokenTableItem span={6} className="column">
        <TokenTableVal weight={500}>
          {!tokens ? (
            <ValueSkeleton width={20} />
          ) : (
            number_format(tokens?.number || 0, 6)
          )}
        </TokenTableVal>
        <Spaced size="8" />
        <TokenTablePrice>
          {!tokens ? (
            <ValueSkeleton width={20} />
          ) : (
            `~$${number_format(tokens?.number * tokens?.price, 2)}`
          )}
        </TokenTablePrice>
      </TokenTableItem>
      <TokenTableItem span={6}>
        <TokenTableVal style={{ fontSize: 14 }}>{hidehash(item.earnToken.address, 10)}</TokenTableVal>
        {/* <WebOpenLink
          to={`${cnf.etherscanUrl}/token/${item.depositToken.address}`}
        /> */}
      </TokenTableItem>
      <TokenTableItem span={6} className="opt">
        <TokenTableButton to={`/send/${item.tokenId}`}>
          {t('Send')}
        </TokenTableButton>
        <TokenTableButton to={`/deposits/${item.tokenId}`}>
          {t('Deposit')}
        </TokenTableButton>
        <TokenTableButton style={{ flex: 1.8 }} to={`/withdraw/${item.tokenId}`}>
          {t('Withdraw')}
        </TokenTableButton>
      </TokenTableItem>
    </TokenTableLine>
  );
};
const WalletView = () => {
  const [isCopied, staticCopy] = useCopyClipboard();
  const { account, tokensTotal, wallet, loginState } = useStarterWallet();
  const { banks } = useBanks();
  const history = useHistory();
  const accountSecond = wallet?.babyjubWallet?.publicCompressed;
  const [version, serVersion] = useState(VersionType.V2)
  const totalNum = useMemo(() => {
    return Number(tokensTotal);
  }, [tokensTotal]);

  useEffect(() => {
    loginState === LoginState.init && history.push("/login");
  }, [loginState]);
  const { t, i18n } = useTranslation()
  const myhistorylists = useMemo(() => block.myhistorylists.map(((itm: any) => ({
    ...itm, title: t(itm.title)
  }))), [i18n?.language])
  const onChange = (v) => serVersion(v)
  const banklist=useMemo(()=>banks.filter(itm=>itm.version === version),[banks,version])
  return (
    <Wrapper>
      <ContainerWrapper>
        {loginState === LoginState.loading ? (
          <LoadPage />
        ) : (
          loginState === LoginState.loginSuccess && (
            <>
              <DepositBox>
                <TokensInfoBox>
                  <TokensInfoLeft>
                    <TokensInfoTotalTit>
                      $ {number_format(totalNum, 2)}
                    </TokensInfoTotalTit>

                    <Spaced size="16" />
                    <TokensInfoTotalDesc>{t('Tusima Layer2')}</TokensInfoTotalDesc>
                    <Spaced size="24" />
                    <SelectV2Box
                      showSearch
                      optionFilterProp="children"
                      onChange={onChange}
                      value={version}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <SelectV2Box.Option value={VersionType.V2}>{VersionType.V2}</SelectV2Box.Option>
                      <SelectV2Box.Option value={VersionType.V1}>{VersionType.V1}</SelectV2Box.Option>
                    </SelectV2Box>
                    {/* <Spaced size="200"/>  */}
                  </TokensInfoLeft>
                  <TokensInfoRight>
                    <QRCode
                      style={{ background: "#fff", padding: "3px" }}
                      id="bill_qr_code_url"
                      value={account} //value参数为生成二维码的链接 我这里是由后端返回
                      size={88} //二维码的宽高尺寸
                      fgColor="#000000" //二维码的颜色
                    />
                    <Spaced size="25" />
                    <TokenInfoAddress>
                      <span>{hidehash(account, 10)}</span>
                      <Copide text={account} />
                    </TokenInfoAddress>
                  </TokensInfoRight>
                </TokensInfoBox>
                <Spaced size="18" />
                <TokenTableHead>
                  <TokenTableItem span={6}>{t('Assets')}</TokenTableItem>
                  <TokenTableItem span={6}>{t('Balance')}</TokenTableItem>
                  <TokenTableItem span={6}>{t('Token Address')}</TokenTableItem>
                </TokenTableHead>
                {banklist.map((item, key) => (
                  <TokenItem item={item} key={key} />
                ))}
                <Spaced size="35" />
                <TitleBox>{t('Transaction History')}</TitleBox>
                <Spaced size="18" />
                {/* <CustomTables addr={accountSecond} /> */}
                <CustomLists>
                  <CustomTable
                    type={'wallet'}
                    columns={myhistorylists}
                    pagination
                    params={{ addr: accountSecond, is_decryption: false }}
                    onRow={(params) => {
                      // sessionStorage.setItem("tranHistory", "true");
                      params?.id && history.push(`/explore/transactiondetail/${params.id}`);
                    }}
                    url={`/api/v1/home/my_transaction/${accountSecond}`}
                  />
                </CustomLists>
              </DepositBox>
              <Spaced size="100" />
            </>
          )
        )}
      </ContainerWrapper>
      {/* <DiscordModel url="https://discord.com/channels/965918503070728203/1011838365110378617"></DiscordModel> */}
    </Wrapper>
  );
};

export default WalletView;

const SelectV2Box = styled(Select)`
  width: 92px;
  .ant-select-selection {
    background-color: rgba(255, 255, 255, 0) !important;
    /* width: 346px; */
    height: 36px;
    line-height: 36px;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
  .ant-select-selection--single .ant-select-selection__rendered {
    height: 100%;
  }
  .ant-select-selection-selected-value {
    color: #fff;
    width: 100%;
    height:36px;
    line-height:36px;
    text-align: left;
  }
  .ant-select-selection {
    background-color: rgba(255, 255, 255, 0) !important;
  }
  .ant-select-arrow {
    color: #fff;
  }
`
const TokenTableFooter = styled.div`
  height: 50px;
  text-align :center ;
`;
const TokenTableLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 380px;
`;
const TokenTableEmpty = styled.div`
  flex: 1;
  height: 200px;
  background-image: url(${Nodata});
  background-repeat: no-repeat;
  background-size: 192px auto;
  background-position-x: center;
  svg {
    display: none;
  }
`;
const CustomLists = styled.div`
  font-family: Poppins-Regular, Poppins;

  .ant-pagination {
    margin: 24px 0;
  }

  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    background: rgba(255, 255, 255, 0.1);
    border: none;
  }
  .ant-pagination-prev a,
  .ant-pagination-next a {
    color: #fff;
    border-radius: 50%;
    &:hover {
      color: #18f195;
    }
  }
  .ant-pagination-item {
    background: rgba(255, 255, 255, 0.1);
    border: none;
  }
  .ant-pagination-item-active {
    background: #18f195;
    border-radius: 4px;
  }
  .ant-pagination-item a {
    color: #fff;
  }
  .ant-pagination-item-active a {
    color: #000000;
  }
  .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis,
  .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis {
    color: #fff;
  }
`;
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  line-height: 1;
  background-image: url(${WalletBg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
`;

const WebOpenBox = styled.a`
  .img1 {
    display: none;
  }
  .img2 {
    display: inline;
  }
  &:hover {
    background-color: #18f19510;
    .img1 {
      display: inline;
    }
    .img2 {
      display: none;
    }
  }
`;
const TitleBox = styled.div`
  font-size: 18px;
  font-family: Poppins;
  color: #ffffff;
  line-height: 18px;
`;

const WebOpenImg = styled.img`
  width: 12px;
  height: 12px;
`;
const TokenTablePrice = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  flex: 1;
`;
const TokenTableVal = styled.div<{ weight?: number }>`
  font-size: 16px;
  font-weight: ${(props) => props.weight || "200"};
  color: #ffffff;
`;
const TokenTableButton = styled(Link)`
  font-size: 14px;
  font-weight: 400;
  color: #18f195;
  /* line-height: 11px; */
  background: linear-gradient(90deg, #18f195 0%, #1fb7ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
  /* width: 33%; */
  flex:1;
  display: block;
  &:hover {
    font-size: 16px;
  }
`;
const TokenTableItem = styled(Col)`
  display: flex;
  align-items: center;
  gap: 5px;
  &.last{
    padding-left:15px;
  }
  &.opt {
    flex: 1;
    justify-content: center;
    gap: 18px;
  }
  &.column {
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
  }
  align-items: center;
  span {
    margin-left: 8px;
  }
`;

const TokenTableLine = styled.div`
  display: flex;
  align-items: center;
  height: 75px;
  padding: 0px 36px;
  border-bottom: solid 1px rgba(255, 255, 255, 0.2);
  ${TokenTableItem} {
    font-size: 16px;
    color: #ffffff;
  }
  &:hover {
    background: rgba(24, 241, 149, 0.1);
  }
`;

const TokenTableHead = styled(Row)`
  /* width: 900px; */
  height: 56px;
  background: #1d1d1d;
  border-radius: 9px;
  display: flex;
  align-items: center;
  padding: 0px 36px;
  ${TokenTableItem} {
    font-size: 16px;
    font-weight: 600;
    color: #999999;
  }
`;

const TokensInfoTotalTit = styled.div`
  font-size: 36px;
  font-weight: 600;
  color: #ffffff;
`;

const TokensInfoTotalDesc = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #999999;
`;
const TokensInfoLeft = styled.div`
  border-radius: 24px;
  width: 834px;
  height: 196px;
  background: linear-gradient(to top right, #18f19518, #18f19503);
  padding: 40px 56px;
`;
const TokensInfoRight = styled.div`
  border-radius: 24px;
  width: 330px;
  height: 200px;
  background: linear-gradient(to top right, #18f19518, #18f19503);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const TokenInfoAddress = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
`;
const TokensInfoBox = styled.div`
  display: flex;
  justify-content: space-between;
`;
const ContainerWrapper = styled.div`
  /* max-width: 1200px; */
  margin: 0 auto;
  padding-top: 56px;
  color: #fff;
`;
const DepositBox = styled.div`
  width: 1200px;
  margin: 0 auto;
`;

const TokenTableBody = styled(Link)`
  height: 56px;
  border-radius: 12px;
  margin-top: 12px;
  display: flex;
  align-items: center;
  padding: 0px 36px;
  background: #151515;
  cursor: pointer;
  color:#fff;
  font-weight:200;
  &:hover {
    color:#fff;
    font-size:16px;
    font-weight:500;
    background: rgba(24, 241, 149, 0.1);
  }
`;
