import React, { useState } from "react";
import styled from "styled-components";
import CloseIcon from "../../assets/connect/close@2x.png";
import CloseIcon2 from "../../assets/connect/close2@2x.png";



export const CloseBox = styled.div`
  position: absolute;
  width: 11px;
  height: 11px;
  
  cursor: pointer;
  background-image:url(${CloseIcon}) ;
  background-size:100%;
  &:hover{
    background-image:url(${CloseIcon2}) ;
  }
`;

