import React from "react";
import styled from "styled-components";
export default function circleStatus({ status }: { status?: any }) {
  return (
    <Content>
      {status === "Pending" && <span style={{ background: "#FF6F00" }}></span>}
      {status === "Verified" && <span style={{ background: "#2070FF" }}></span>}
      {status === "Committed" && (
        <span style={{ background: "#FF00D8" }}></span>
      )}
      {status === "Executed" && <span style={{ background: "#18F195" }}></span>}
      {status === "Failed" && <span style={{ background: "#FF3700" }}></span>}
      {/* {}
    {}
    {} */}
    </Content>
  );
}
const Content = styled.div`
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-right: 7px;
  span {
    display: inline-block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    margin-bottom:2px;
  }
`;
