import React, { useEffect, useMemo, useState } from "react"

import styled from "styled-components"
import { Spaced } from "../../../components/Spaced"
import DiscordModel from "../../../components/DiscordModel"
import WalletBg from "../../../assets/home/wallet_b.png"
import { useTranslation } from "react-i18next"
import PageLeftIcon from '../../../assets/tdao/page_left@2x.png'
import PageRightIcon from '../../../assets/tdao/page_right@2x.png'
import { getSdtScoreAllLog } from "../../../utils/fetch/login"
import useStarterWallet from "../../../hooks/useStarterWallet"
import { hidehash } from "../../../utils/utils"
import UserheadImg1 from '../../../assets/discord/1.png'
import UserheadImg2 from '../../../assets/discord/2.png'
import UserheadImg3 from '../../../assets/discord/3.png'
import UserheadImg4 from '../../../assets/discord/4.png'
import UserheadImg5 from '../../../assets/discord/5.png'
import SelectImg from '../../../assets/discord/select.png'
import JianTimg from '../../../assets/discord/jiant.png'
import LoadPage from "../../../components/LoadPage"
import NoDataIcon from "../../../components/NoDataIcon/NoDataIcon"
import Toast from "../../../components/Toast"
import SelectBtnImg from './SelectIcon'
import Pagination from "../../../components/Pagination/Pagination"


export const TestApiUrl = null


const PointsListsPagesItem: React.FC<{ page: number, active: boolean, onClick?: any }> = ({ onClick, page, active }) => {
  return <PointsListsPagesItemBox className={active ? 'active' : ''} onClick={onClick}>
    {page}
  </PointsListsPagesItemBox>
}

const PointsListsPagesIcon: React.FC<{ Icon: any, onClick?: any }> = ({ Icon, onClick }) => {
  return <PointsListsPagesIconBox onClick={onClick}>
    <PointsListsPagesIconImg src={Icon} />
  </PointsListsPagesIconBox>
}

const PageableUi: React.FC<{ page: number, total: number, onChange?: any, pageSize?: number }> = ({
  page, total, onChange, pageSize = 10
}) => {

  const listArr = useMemo(() => {
    let pageArr = []
    const groupCount = 5;
    const currentPage = page
    const totalPage = Math.ceil(total / pageSize)
    let startPage = currentPage - 2 > 0 ? currentPage - 2 : currentPage
    if (totalPage <= groupCount) {
      for (let i = 1; i <= totalPage; i++) {
        pageArr.push(i)
      }
    } else {
      pageArr.push(1)
      let pageLength = 0;
      if (groupCount + startPage >= totalPage) {
        pageLength = totalPage;
      } else {
        pageLength = groupCount + startPage;

      }
      if (currentPage >= groupCount) {
        pageArr.push('...')
      }
      for (let i = startPage; i < pageLength; i++) {
        if (i <= totalPage - 1 && i > 1) {
          pageArr.push(i)
        }
      }
      if (totalPage - startPage >= groupCount + 1) {
        pageArr.push('...')
      }
    }
    return pageArr
  }, [total, page])

  return <PointsListsPagesBox>
    {listArr.length > 1 && page > 1 ? <PointsListsPagesIcon onClick={() => onChange(page - 1)} Icon={PageLeftIcon} /> : ''}
    <PointsListsPagesConBox>
      {listArr.map((i, k) => <PointsListsPagesItem onClick={() => i === '...' ? '' : onChange(i)} key={k} page={i} active={i === page} />)}
    </PointsListsPagesConBox>
    {listArr.length > 1 && page < listArr.length ? <PointsListsPagesIcon onClick={() => onChange(page + 1)} Icon={PageRightIcon} /> : ''}
  </PointsListsPagesBox>
}

const PointsListsPagesConBox = styled.div`

display: flex;
background: rgba(255,255,255,0.1);
margin: 0 20px;
border-radius: 12px;
`
const PointsListsPagesIconImg = styled.img`
  width: 18px;
`
const PointsListsPagesItemBox = styled.div`
user-select: none;
  margin: 0 2px;
  min-width: 40px;
  height: 40px;
  padding:0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  &.active{
    background: #18F195;
    color: #000;
  }
`
const PointsListsPagesIconBox = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(255,255,255,0.1) ;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: .78;
  cursor: pointer;
  &:hover{
    opacity: 1;
  }
`
const PointsListsPagesBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const PointsListsLine: React.FC<{ item: any, headImg: any, self?: boolean }> = ({ item, headImg, self }) => {
  const username = useMemo(()=>{

    try{
      const json = JSON.parse(item.info.replace(/'/g,'"'))
      return json.username
    }catch(e){
      
      return item.name
    }
  },[item])
  return <PointsListsLineBox className={`item ${self ? 'active' : ''}`}>
    <PointsListsItem className="w20">
      <span className="r15">{item.rank}</span>
      <UserHeadIcon src={headImg} />
      {username}
    </PointsListsItem>
    <PointsListsItem className="w20">
      {item.id}
    </PointsListsItem>
    <PointsListsItem className="w30">
      {hidehash(item.addr, 12)}
    </PointsListsItem>
    <PointsListsItem className="all">
      <span>{item.score || 0}</span>
    </PointsListsItem>
  </PointsListsLineBox>
}
const PointsLists: React.FC = () => {
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const { account } = useStarterWallet()
  const [lists, setLists] = useState(null)
  const [selfData, setSelfData] = useState(null)
  const [selectData, setSelectData] = useState(null)
  const [isSelect, setIsSelect] = useState(false)
  const [isEmpty, setEmpty] = useState(false)
  const {t} = useTranslation()
  const onSelect = async (addr: string) => {
    setIsSelect(!!addr)
    setSelectData(null)
    const { data } = await getSdtScoreAllLog({ addr: addr || account })
    setSelectData(data)
    setEmpty(!data)
  }

  const fetchSdtScoreSelfLog = async () => {
    const { data, code } = await getSdtScoreAllLog({ addr: account, page: 1, size: 10 })
    if (code === 200) {
      setSelfData(data)
    }
  }

  const fetchSdtScoreAllLog = async () => {
    const { data } = await getSdtScoreAllLog({ addr: undefined, page: page, size: 10 })
    setTotal(data.total)
    setLists(data.list?.filter(itm=>itm.score>=0))
    setEmpty(!data.list || !data.list.length)
  }

  useEffect(() => {
    account && fetchSdtScoreSelfLog().catch(()=>{
      // setEmpty(true)
    })
  }, [account])

  useEffect(() => {
    fetchSdtScoreAllLog().catch(console.error)
  }, [page])

  return <PointsListsBox>
    <TableHeadBox>
      <TableTitleBox>{t('Points Board')}</TableTitleBox>
      <TableSelectInputs onSelect={onSelect} />
    </TableHeadBox>
    <Spaced size="20" />
    <PointsListsLineBox className="head">
      <PointsListsItem className="w20">
        {t('Discord Name')}
      </PointsListsItem>
      <PointsListsItem className="w20">
        {t('TSBT ID')}
      </PointsListsItem>
      <PointsListsItem className="w30">
        {t('Address')}
      </PointsListsItem>
      <PointsListsItem className="all">
        {t('Points')}
      </PointsListsItem>
    </PointsListsLineBox>
    <PageLists
    isEmpty={isEmpty}
      page={page}
      total={total}
      setPage={setPage}
      selfData={selfData}
      isSelect={isSelect}
      selectData={selectData}
      lists={lists}
    />
  </PointsListsBox>
}
const PageLists: React.FC<{ isSelect: any, selectData: any,isEmpty:any, selfData: any, lists: any, page: any, setPage: any, total: any }> = ({
  isSelect, selectData, selfData, lists, page, setPage, total,isEmpty
}) => {
  const UserHeadArr = [
    UserheadImg1,
    UserheadImg2,
    UserheadImg3,
    UserheadImg4,
    UserheadImg5,
  ]
  if(isEmpty){
    return <NoDataIcon/>
  }else if (isSelect) {
    return selectData === null ? <div /> : <PointsListsLine self={false} headImg={UserHeadArr[1]} item={selectData} />
  }
  return <>
    {selfData === null ? '' : <PointsListsLine self={true} headImg={UserHeadArr[1]} item={selfData} />}
    {lists === null ? <LoadPage /> : lists.map((item: any, key: React.Key) => <PointsListsLine headImg={UserHeadArr[Number(key) % 4]} item={item} key={key} />)}
    <Pagination page={page} onChange={(page) => setPage(page)} total={total} />
  </>
}
const TdaoAllView = () => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <Spaced size="72" />
      <ContainerWrapper>
        <PageTitle>
          {t('What are Tusima points?')}
        </PageTitle>
        <Spaced size="30" />
        <PageContxt>
          {t('Tusima points are used to measure the contribution of community members to the construction of the Tusima network. The more points a member has, the greater their contribution and activity. Points hold high community equity value.')}
        </PageContxt>
        <Spaced size="120" />
        <PageTitle>
          {t('How do points work?')}
        </PageTitle>
        <Spaced size="30" />
        <PageContxt>
          <div>
            {t('Points serve as proof of community contribution. After the mainnet launch, points can be exchanged for Tusima community governance tokens, as well as freely exchanged for community derivatives, NFTs, USDT, or other tokens. The specific rules for this need to be voted on by the community for discussion in the future.')}
          </div>
          <Spaced size="30" />
          <div>
            {t("We aim to fairly reward community members who contribute to Tusima's ecological development and construction by measuring their contribution points. Therefore, a member's free exchange privileges increase as their points total increases.")}
          </div>
        </PageContxt>
        <Spaced size="50" />
        <PointsLists />
      </ContainerWrapper>
      <Spaced size="200" />
      {/* <DiscordModel url="https://discord.com/channels/965918503070728203/1011838365110378617"></DiscordModel> */}
    </Wrapper>
  )
}
export default TdaoAllView


const TableSelectInputs: React.FC<{ onSelect?: any }> = ({ onSelect }) => {
  const [value, setValue] = useState('')
  const {t} = useTranslation()
  const onChangeInput = (e: any) => {
    let { value } = e.target
    setValue(value)
  }
  const onSubmit = () => {
    if(value && value.length !== 42){
      Toast.error(t('The address format is incorrect'))
      return
    }
    onSelect(value)
  }
  return <TableSelectBox>
    <TableSelectImgBox className="left" src={SelectImg} />
    <TableSelectInputBox placeholder="Address" value={value} onChange={onChangeInput} />
    <TableSelectImgBtn onClick={onSubmit}>
      <SelectBtnIcon/>
      {/* <TableSelectImgBox className="right" src={JianTimg} /> */}
    </TableSelectImgBtn>
  </TableSelectBox>
}
const SelectBtnIcon=styled(SelectBtnImg)`
  fill: #000;
  padding-top: 5px;
  &:hover{
    fill: #fff;
  }
` 
const TableSelectImgBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  cursor: pointer;
`
const TableSelectInputBox = styled.input`
  flex:1;
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  background-color:#000;
  border:none;
  padding: 0;
  margin: 0;
  padding: 0 10px 0 38px;
  height: 38px;
  line-height: 38px;
  &:focus-visible {
    outline:none;
}

`
const TableSelectImgBox = styled.img`
  &.left{
    position: absolute;
    width: 16px;
    top: 11px;
    left: 13px;
  }
  user-select: none;
  &.right{
    width: 24px;
  }

`
const TableHeadBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const TableTitleBox = styled.div`
font-size: 24px;
font-family: Poppins;
color: #FFFFFF;
line-height: 24px;
`
const TableSelectBox = styled.div`
  position: relative;
  width: 509px;
  height: 40px;
  background: linear-gradient(328deg, #4742DE 0%, #30F8BF 100%);
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-left: 1px;
`
const UserHeadIcon = styled.img`
  width: 16px;
  margin-right: 5px;
`
const PointsListsItem = styled.div`
font-size: 14px;
font-family: Poppins-Regular, Poppins;
font-weight: 400;
color: #FFFFFF;
line-height: 14px;
display: flex;
align-items: center;
  &.w20{
    width: 25%;
  }
  &.w30{
    width: 40%;
  }
  &.all{
    flex:1;
  }
  .r15{
    min-width: 30px;
    /* display: block; */
    margin-right: 15px;
  }
  span{
    color:#18F195;
  }
`
const PointsListsLineBox = styled.div`
  display: flex;
  align-items: center;
  padding:0px 40px;
  &.head{
    height: 56px;
    background: #1D1D1D;
    border-radius: 12px;
    ${PointsListsItem}{
      color: #999;
      font-size: 16px;
      font-weight: 600;
    }
  }
  &.item{
    background: #151515;
    margin: 12px 0;
    height: 56px;
    border-radius: 12px;
    font-weight: 400;
    &:hover{
      font-size: 16px;
      font-weight: 500px;
      height: 56px;
      border-radius: 12px;
      opacity: 0.9;
      background: rgba(24,241,149,0.2);
    }
    &.active{
      height: 56px;
      border-radius: 12px;
      opacity: 0.9;
      background: rgba(24,241,149,0.2);
    }
  }

`
const PointsListsBox = styled.div`

`
const PageTitle = styled.div`
font-size: 24px;
font-family: Poppins;
color: #FFFFFF;
line-height: 24px;
`
const PageContxt = styled.div`
font-size: 16px;
font-family: Poppins-Medium, Poppins;
font-weight: 500;
color: #CCCCCC;
line-height: 32px;
`
const Wrapper = styled.div`
  width: auto;
  height: 100%;
  background-image: url(${WalletBg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
`


const ContainerWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 56px;
  color: #fff;
  /* display: flex; */
`

