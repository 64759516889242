import React, { Component } from "react";
import Icons from "./toast_up.png";
import Closed from "./closed.png";
import styled, { keyframes } from "styled-components";
import Toast_loading_close from "../../assets/connect/toast_loading_close.png";
import Toast_loading_icon from "../../assets/connect/toast_loading_icon.png";
import Toast_pending_close from "../../assets/connect/toast_pending_close.png";
import Toast_pending_icon from "../../assets/connect/toast_pending_icon.png";
import Toast_success_close from "../../assets/connect/toast_success_close.png";
import Toast_success_icon from "../../assets/connect/toast_success_icon.png";
import Totast_error_close from "../../assets/connect/totast_error_close.png";
import Totast_error_icon from "../../assets/connect/totast_error_icon.png";
import TweenOne from 'rc-tween-one';
import {Trans} from 'react-i18next'
const logosByIcon = {
  INFO: Toast_success_icon,
  LOADING: Toast_loading_close,
  PENDING: Toast_pending_icon,
  SUCCESS: Toast_success_icon,
  ERROR: Totast_error_icon,
};

const logosByClose = {
  INFO: Toast_success_close,
  LOADING: Toast_loading_icon,
  PENDING: Toast_pending_close,
  SUCCESS: Toast_success_close,
  ERROR: Totast_error_close,
};
const ToastColors={
  INFO: ['#18F195','#031F13'],
  LOADING: ['#18BEF1','#06243C'],
  PENDING: ['#18BEF1','#06243C'],
  SUCCESS:  ['#18F195','#031F13'],
  ERROR: ['#B66562','#20100E']
}
const ToastIcon = ({ str }) => {
  const Icon = logosByIcon[str];
  return <img src={Icon} alt="/" />;
};
const ToastClose = ({ str }) => {
  const Icon = logosByClose[str];

  return <img src={Icon} alt="/" />;
};
class ToastBox extends Component {
  constructor() {
    super();
    this.transitionTime = 10000;
    this.state = { notices: [] };
    this.removeNotice = this.removeNotice.bind(this);
  }

  getNoticeKey() {
    const { notices } = this.state;
    return `notice-${new Date().getTime()}-${notices.length}`;
  }

  addNotice(notice) {
    const { notices } = this.state;
    notice.key = this.getNoticeKey();

    if(notices.length > 0){
      return
    }
    // console.log(notices.length)
    // notices.push(notice);//展示所有的提示
    notices[0] = notice; //仅展示最后一个提示

    console.log(notice.duration)
    this.setState({ notices });
    if (notice.duration > 0) {
      setTimeout(() => {
        this.removeNotice(notice.key);
      }, notice.duration + 1200);
    }
    return () => {
      this.removeNotice(notice.key);
    };
  }
  removeNotice(key) {
    const { notices } = this.state;
    console.log({key})
    this.setState({
      notices: notices.filter((notice) => {
        if (notice.key === key) {
          // if (notice.onClose) setTimeout(notice.onClose,this.transitionTime + 1200 );
          return false;
        }
        return true;
      }),
    });
  }


  render() {
    const { notices } = this.state;
   
    console.log({notices})
    return (
      <>
        {notices.map((notice,key) => {
           const [color,bgcolor]=ToastColors[String(notice.type).toLocaleUpperCase()]
           console.log(notice.content)
          return (
            <ToastBg animation={[
              { x: 200, opacity: 0,scale:1,delay:0,duration:600, type: 'from' },
              { x: 200, opacity: 0,scale:1,delay:notice.duration-600,duration:600, type: 'to' }
            ]} key={key}>
              <ToastCtx bgcolor={bgcolor}>
                <ToastTop >
                  <ToastLoading>
                    <ToastIcon str={String(notice.type).toLocaleUpperCase()} />
                  </ToastLoading>
                  <div>
                    <ToastText color={color} dangerouslySetInnerHTML={{__html: notice.content}}/>
                    {notice.href ? (
                      <Toast_href>
                        <a href={notice.href} target="_blank">
                          <Trans>View the transaction</Trans>
                        </a>
                      </Toast_href>
                    ) : ("")}
                  </div>
                  <Toast_closed onClick={() => this.removeNotice(notice.key)}>
                    <ToastClose str={String(notice.type).toLocaleUpperCase()} />
                  </Toast_closed>
                </ToastTop>
                <Toast_progress>
                  <Toast_progressBox>
                    <Toast_progressBar duration={notice.duration} color={color} />
                  </Toast_progressBox>
                </Toast_progress>
              </ToastCtx>
            </ToastBg>
          );
        })}
      </>
    );
  }
}

export default ToastBox;

const Loadingkeyframes = keyframes`
    25% {
        -webkit-transform: translateY(-5px);
    }
    50%,
    100% {
        -webkit-transform: translateY(0);
    }
    75% {
        -webkit-transform: translateY(5px);
    }
`;
const Progresskeyframes = keyframes`
    0% {
        width: 100%;
    }
    100% {
        width: 0%;
    }
`;

const Toast_progressBar = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 4px;
  background: ${props=>props.color};
  border-radius: 3px;
  z-index: 2;
  animation: ${Progresskeyframes} ${props=>props.duration/1000}s linear;
  -moz-animation: ${Progresskeyframes} ${props=>props.duration/1000}s linear;
  /* Firefox */
  -webkit-animation: ${Progresskeyframes} ${props=>props.duration/1000}s linear;
  /* Safari and Chrome */
  -o-animation: ${Progresskeyframes} ${props=>props.duration/1000}s linear;
  /* Opera */
`;
const Toast_progressBox = styled.div`
  width: 360px;
  height: 4px;
  background: rgba(0, 0, 0, 0.36);
  border-radius: 3px;
  position: relative;
`;
const Toast_progress = styled.div`
position: relative;
  width: 100%;
  height: 5px;
  padding: 0 10px;
  margin-top: -5px;
`;
const Toast_closed = styled.div`
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
  img {
    width: 14px;
    height: 14px;
    display: inline-block;
  }
`;
const Toast_href = styled.div`
  font-size: 11px;
  color: #fff;
  margin-top: 12px;
  line-height: 11px;
  margin-bottom: 30px;
  a {
    color: #fff;
  }
`;
const ToastText = styled.div`
  font-size: 18px;
  font-family: Poppins-SemiBold, Poppins;
  font-weight: 600;
  line-height: 24px;
  padding-top: 30px;
  width:255px ;
  color:${props=>props.color};
`;

const ToastLoading = styled.div`
  margin-right: 20px;
  margin-top:30px;
  /* display: flex; */
  /* align-items: center; */
  img {
    width: 21px;
    display: inline-block;
    animation: ${Loadingkeyframes} 1.5s linear infinite;
    -webkit-animation: ${Loadingkeyframes} 1.5s linear infinite;
  }
`;

const ToastTop = styled.div`
  width: 100%;
  min-height: 90px;
  display: flex;
  /* line-height: 90px; */
  padding: 0 30px;
  color: #18f195;
`;

const ToastCtx = styled.div`
 
  background:${props=>props.bgcolor};
  border-radius: 15px;
  float: right;
`;
const ToastBg = styled(TweenOne)`
  width: 379px;
  position:fixed;
  right: 60px;
  top: 30px;
  padding-bottom:10px;
`;
// const ToastBase = styled.div``;
