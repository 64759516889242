/* eslint-disable */
import React, { memo, useState,useReducer,useEffect, useMemo } from 'react';
import { Table,ConfigProvider } from 'antd';
import styled from 'styled-components';
import { CustomTableProps, initStateType, actionType } from './data';
import TbaleBg from '../../assets/home/home-table-bg.png';
import Nodata from '../../assets/home/noDataTable.png'
import { tableList} from "../../utils/fetch/homepage";
import useStarterWallet from '../../hooks/useStarterWallet';
import LoadPage from '../LoadPage';
import config from '../../config';
import NodataDetails from '../../views/Nodata';
import NoDataIcon from '../NoDataIcon';
import { getChainLng } from '../../utils/tools';
const initState = {
  total: 0,
  max_page: 0,
  current_page: 1,
};

const reducer = (state: initStateType, action: actionType) => {
  switch (action.type) {
    case 'showPage':
      return {
        total: action.total,
        max_page: action.max_page,
        current_page: action.current_page,
      };
    default:
      return state;
  }
};

const CustomTable = (props: CustomTableProps) => {

  const {
    isLight = true,
    columns = [],
    pagination,
    scroll,
    onRow,
    url = '', 
    params,
    bordered = false,
    type,
    isDecry,
    onCallBack
  } = props;
  const newParams = {
    ...params,
    page: 1,
    size: 15,
  };

  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any[]>([]);
  const [pageNumber, setPageNumber] = useState<{ page: number; size: number }>({
    page: 1,
    size: 15,
  });

  const {chainId,decryptStr} =useStarterWallet()
  const [state, dispatch] = useReducer(reducer, initState);
  const { total, max_page, current_page } = state;

  const cnf = useMemo(()=>{
    return config[getChainLng(chainId)]
  },[chainId])
  const getList = async (params: Record<string, unknown>) => {
    // setLoading(true);
    if(params.page === 1 && data.length === 0){
      setLoading(true)
    }
    const res: any = await tableList(url, params);
    if (res.code === 200) {

      // if(type === 'aaa'){
      //   const list = res?.data.list.map((item=>{
      //     const nums =
      //       allTokens.find(
      //         (itm) => String(itm.tokenId) === String(item?.token)
      //       )?.price || 0;

      //     return {
      //       nums,
      //       ...item
      //     }
      //   }))
      //   setData(list)
      // }else{
      //   setData(res?.data?.list ??res?.data?.balance ?? []);
      // }
      const lists = (res?.data?.list ??res?.data?.balance ?? []).map((itm)=>({...decryptStr(itm,type),isDecry,chainName:cnf.chainName}))
      setData(lists);
      localStorage.setItem('totalPage', res?.data?.total);
      onCallBack?onCallBack(lists) :''
      dispatch({
        type: 'showPage',
        total: res?.data?.total || 0,
        current_page: res?.data?.page || 1,
        max_page: res?.data?.max_page || 0,
      });
      setLoading(false);
    } else {
      setData([])
      setLoading(false);
    }
  };

  useEffect(() => {
    getList({ ...newParams });
  }, [isDecry,url]);
  
  return (
    <CustomWrapper isLight={isLight}>
     {loading ? <LoadPage/>:<ConfigProvider renderEmpty={()=><NoDataIcon/>}><Table
        rowKey="id"
        className={'tableWrap'}
        showSorterTooltip={false}
        bordered={bordered}
        onRow={
          onRow
            ? (record: any) => {
                return {
                  onClick: () => {
                    onRow(record);
                  }, // 点击行
                };
              }
            : false
        }
        // scroll={{ y: 552 }}
        pagination={
          pagination
            ? {
                showSizeChanger: false,
                showQuickJumper: false,
                total,
                onChange: (page: number, pageSize: number) => {
                  if (pageSize !== pageNumber.size) {
                    return;
                  }
                  getList({ ...params, page, size: pageSize });
                  setPageNumber({ page, size: pageSize });
                },
                onShowSizeChange: (current: number, size: number) => {
                  getList({ ...params, page: current, size });
                  setPageNumber({ page: current, size });
                },
                current: pageNumber.page,
                pageSize: pageNumber.size,
              }
            : false
        }
        scroll={scroll}
        loading={loading}
        columns={columns}
        dataSource={data}
      /> </ConfigProvider>}
    </CustomWrapper>
  );
};

export default memo(CustomTable);

const CustomWrapper = styled.div<{ isLight: any }>`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  .tableWrap {
    background-image: ${({ isLight }) =>
      isLight ? `url(${TbaleBg})` : 'none'};
    background-size: 100% 100%;
    .ant-table table {
      border-collapse: separate;
      border-spacing: 0px 12px;
    }
    .ant-table {
      border: none;
      border-bottom: none;
    }
    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      width: 0;
    }
    .ant-table-thead > tr > th {
      height: 54px;
      padding: 16px 16px 16px 30px;
      color: rgba(255, 255, 255, 0.4);
      background-color: #000;
      font-weight: 600;
      border: none;
    }
    .ant-table-tbody > tr > td {
      border-bottom: none;
      padding: 17px 16px 17px 30px;
      cursor: pointer;
    }
    .ant-table-tbody > tr > td:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    .ant-table-tbody > tr > td:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    .ant-table-pagination.ant-pagination {
      margin: 24px 0;
    }
    .ant-table-tbody > tr {
      background: rgba(255, 255, 255, 0.1);
      border-radius: 8px;
      color: #fff;
    }
    .ant-table-placeholder {
      background: #000;
      border: none;
    }
    .ant-empty-image {
      height: 200px;;
      background-image:url(${Nodata});
      background-repeat:no-repeat;
      background-size:192px auto;
      background-position-x:center;
      svg{
        display: none;
      }
    }
    .ant-empty-description {
      color: #fff;
      display: none;
    }
    .ant-table .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
      background: #243e32;
    }
    .ant-table-thead > tr > th .ant-table-header-column {
      height: 14px;
    }
    .ant-table-pagination.ant-pagination {
      width: 100%;
      text-align: center;
      margin: 35px 0 0;
    }
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      background: rgba(255, 255, 255, 0.1);
      border: none;
    }
    .ant-pagination-prev a,
    .ant-pagination-next a {
      color: #fff;
      border-radius: 50%;
      &:hover {
        color: #18f195;
      }
    }
    .ant-pagination-item {
      background: rgba(255, 255, 255, 0.1);
      border: none;
    }
    .ant-pagination-item-active {
      background: #18f195;
      border-radius: 4px;
    }
    .ant-pagination-item a {
      color: #fff;
    }
    .ant-pagination-item-active a {
      color: #000000;
    }
    .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis{
      color:#fff;
    }
    .ant-pagination-item-container {
      svg {
        color: #18f195;
      }
    }
  }
`;
