import { useCallback, useEffect, useState } from 'react';
import { BigNumber, ethers } from 'ethers';
import ERC20 from '../utils/ERC20';
import useStarterWallet from './useStarterWallet';
import { getDefaultProvider } from '../utils/provider';
import config from '../config';

const useAllowance = (token: ERC20, spender: string, pendingApproval?: boolean,amount?:any) => {
  const [allowance, setAllowance] = useState<BigNumber>(null);
  const [balance, setBalance] = useState(null);
  const {account,chainId,rollupWallet}  = useStarterWallet()

  const fetchAllowance = useCallback(async () => {
    const allowance = await token.allowance(account, spender);
    console.log(`Allowance: ${allowance.toString()} ${token.symbol} for ${spender}`);
    setAllowance(allowance);
  }, [account, spender, token,chainId]);

  const updateBalace = useCallback(async () => {
    const provider = getDefaultProvider(chainId)
    const balanceHex = await provider.getBalance(account)
    const balance = ethers.utils.formatEther(balanceHex)
    setBalance(balance)
  }, [account,balance,chainId, rollupWallet])

  useEffect(() => {
    if (account) {
      updateBalace().catch(console.error)
      const timeId = setInterval(() => {
        updateBalace().catch(console.error)
      }, config.refreshInterval)
      return () => {
        clearTimeout(timeId)
      }
    }
  }, [account, rollupWallet,chainId])
  
  useEffect(() => {
    if (account && spender && token) {
      fetchAllowance().catch((err) => console.log(`Failed to fetch allowance: ${err.stack}`));
    }
  }, [account,balance, spender, token, pendingApproval,chainId,amount]);

  return allowance;
};

export default useAllowance;
