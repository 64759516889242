import styled from "styled-components";
import { Select } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import SanjiaoBaiIcon from "../../assets/connect/sanjiao_bai@2x.png";
import SanjiaoHeiIcon from "../../assets/connect/sanjiao_hei@2x.png";
import TokenSymbol from "../TokenSymbol";
import { Bank } from "../../utils/poolsType";
import useStarterWallet from "../../hooks/useStarterWallet";
import { number_format } from "../../utils/utils";
import { ethers } from "ethers";
import config from "../../config";
import { getDisplayBalance } from "../../utils/tools";


interface StyleProp {
  width?:any;
  className?: string;
  value?: any;
  deposit?:boolean; 
  onChange: any;
}
interface SelectPrimaryProps extends StyleProp{
  options: { title: string; value: any; symbol?: any; balance?: any }[];
}
interface SelectHighlightsProps extends StyleProp{
  options: Bank[];
}
const SelectPrimaryOption:React.FC<{item:Bank,setShow:any,onChange:any,deposit:boolean}>=({item,setShow,onChange,deposit})=>{
  const { allTokens,account } = useStarterWallet();
  const [balance, setBalance] = useState(null);
  const tokensTotal = useMemo(()=>{
    return allTokens.find(itm=>itm.tokenId === item?.tokenId)?.number || 0
  },[allTokens,item])

  const updateBalance = async () => {
    if (item) {
      const balance = await item.depositToken.balanceOf(account)
      setBalance(getDisplayBalance(balance,item.depositToken.decimal))
    }
  };
  useEffect(() => {
    updateBalance().catch(()=>{});
    const timeId = setInterval(() => {
      updateBalance().catch(()=>{});
    }, config.refreshInterval);
    return () => {
      clearTimeout(timeId);
    };
  }, [item, account]);


  return <SelectPrimaryOptionSty
  onClick={() => {
    setShow(false);
    onChange(item.tokenId);
  }}
>
  <OptionVal>
    <TokenSymbol size={18} symbol={item.depositTokenName} />
    <span>{item.name}</span>
  </OptionVal>
  <BalanceBox>{number_format( deposit ?balance:tokensTotal,2)}</BalanceBox>
</SelectPrimaryOptionSty>
} 
export const SelectHighlights: React.FC<SelectHighlightsProps> = ({
  className,
  value,
  options,
  onChange,
  deposit,
  width
}) => {
  const [show, setShow] = useState(false);
  const bank = useMemo(() => {
    const item = options.find((itm) => itm.tokenId === value);
    return item;
  }, [value,options]);

  const onClick = useCallback(() => {
    // console.log({show})
    setShow(!show);
  }, [show]);

  useEffect(() => {
    const fun = () => {
      if(show){
        setShow(false);
      }
    }
    document.addEventListener('click', fun)
    return ()=>document.removeEventListener('click',fun)
  }, [show])

  return (
    <SelectHighlightsSty width={width} className={`${show ? "show" : "off"} ${className}`}>
      {bank ? <SelectTitle onClick={onClick}>
        <OptionVal>
          <TokenSymbol size={18} symbol={bank?.depositTokenName} />
          <span>{bank?.name}</span>
        </OptionVal>
        <SelectSuffixIcon className="img" src={SanjiaoHeiIcon}/>
      </SelectTitle>:''}
      <SelectListsBox className={`options ${show ? "unfold" : ""}`}>
        {options.map((item, key) => {
          return (
           <SelectPrimaryOption deposit={deposit} onChange={onChange} setShow={setShow} key={key} item={item}/> 
          );
        })}
      </SelectListsBox>
    </SelectHighlightsSty>
  );
};
export const SelectPrimary: React.FC<SelectPrimaryProps> = ({
  className,
  value,
  options,
  onChange,
  width
}) => {
  const [show, setShow] = useState(false);
  const item = useMemo(() => {
    const item = options.find((itm) => itm.value === value);
    if(item){
      return item
    }
    return {
      symbol:'ETH',
      title:'ETH'
    };
  }, [value]);
  
  const onClick = useCallback(() => {
    setShow(!show)
  }, [show])

  return (
    <SelectPrimarySty width={width} className={`${show ? "show" : "off"} ${className}`}>
      <SelectTitle onClick={onClick}>
        <OptionVal>
          {item.symbol && <TokenSymbol size={18} symbol={item.symbol} />}
          <div>{item.title}</div>
        </OptionVal>
      <SelectSuffixIcon className="img" src={SanjiaoBaiIcon} />
      </SelectTitle>
      <SelectListsBox className={`options ${show ? "unfold" : ""}`}>
        {options.map((item, key) => {
          return (
            <SelectPrimaryOptionSty
              onClick={() => {
                setShow(false);
                onChange(item.value);
              }}
              key={key}>
              <OptionVal>
                {item.symbol && <TokenSymbol size={18} symbol={item.symbol} />}
                <div>{item.title}</div>
              </OptionVal>
            </SelectPrimaryOptionSty>
          )
        })}
      </SelectListsBox>
    </SelectPrimarySty>
  );
};
export const AccountSelect: React.FC<SelectPrimaryProps> = ({
  className,
  value,
  options,
  onChange,
  width
}) => {
  const [show, setShow] = useState(false);
  const item = useMemo(() => {
    const item = options.find((itm) => itm.value === value);
    if(item){
      return item
    }
    return {
      symbol:'ETH',
      title:'ETH'
    };
  }, [value]);
  
  const onClick = useCallback(() => {
    setShow(!show)
  }, [show])

  return (
    <SelectPrimarySty width={width} className={`${show ? "show" : "off"} ${className}`}>
      <SelectTitle onClick={onClick}>
        <OptionVal>
          <div>{value}</div>
        </OptionVal>
      <SelectSuffixIcon className="img" src={SanjiaoBaiIcon} />
      </SelectTitle>
      <SelectListsBox className={`options ${show ? "unfold" : ""}`}>
        {options.map((item, key) => {
          return (
            <SelectPrimaryOptionSty
              onClick={() => {
                setShow(false);
                onChange(item.value);
              }}
              key={key}>
              <OptionVal>
                {item.symbol && <TokenSymbol size={18} symbol={item.symbol} />}
                <div>{item.title}</div>
              </OptionVal>
            </SelectPrimaryOptionSty>
          )
        })}
      </SelectListsBox>
    </SelectPrimarySty>
  );
};
const Base = styled.div<{width:number}>`
  min-width: ${props=>props.width || '120'}px;
  &.show .img {
    transform: rotate(180deg);
    transition: transform 0.3s;
  }
  &.off .img {
    transform: rotate(0deg);
    transition: transform 0.3s;
  }
`;

const BalanceBox = styled.div``;
const OptionVal = styled.div`
  display:flex;
  align-items:center;
  font-weight:700;
  /* gap:10px; */
  span{
    color:#292929 !important;
  }
`;
const SelectListsBox = styled.div`
  position: absolute;
  z-index: 100;
  top: 38px;
  left: -5px;
  overflow: hidden;
  background-color: #fff;
  color: #000;
  border-radius: 11px;
  width: 200px;
  &.options {
    transition: all 0.3s;
    max-height: 0;
  }
  &.unfold {
    max-height: 200px;
  }
`;

const SelectValue = styled.div`
  flex: 1;
  margin-left: 10px;
`;

const SelectTitle = styled.div`
  cursor: pointer;
  display: flex;
  height: 30px;
 
  align-items: center;
  justify-content: space-between;
  span {
    margin-left: 7px;
  }
  ${OptionVal}{
    margin-left:10px;
  }
`;

const SelectSuffixIcon = styled.img`
  height: 9px;
  margin-right: 12px;
`;

const SelectPrimaryOptionSty = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  flex: 1;
  font-size:11px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
  /* img{ */
  /* margin-left:12px; */
  /* } */
  span {
    margin-left: 12px;
  }
`;

const SelectHighlightsSty = styled(Base)`
  position: relative;
  background: linear-gradient(90deg, #18f195 0%, #1fb7ff 100%);
  border-radius: 15px;
  
`;

const SelectPrimarySty = styled(Base)`
  position: relative;
`;
