import styled from "styled-components";
import { Select } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import SanjiaoBaiIcon from "../../assets/connect/sanjiao_bai@2x.png";
import SanjiaoHeiIcon from "../../assets/connect/sanjiao_hei@2x.png";
import TokenSymbol from "../TokenSymbol";
import { Bank } from "../../utils/poolsType";
import useStarterWallet from "../../hooks/useStarterWallet";
import { number_format } from "../../utils/utils";
import { ethers } from "ethers";
import config from "../../config";
import Checkout_Yes from '../../assets/connect/checkout_yes.png'
import Checkout_No from '../../assets/connect/checkout_no.png'

const Checkout:React.FC<{checkout:boolean,title?:string,onChange?:any}>=({checkout,title,onChange})=>{
  return <CheckoutBox onClick={()=>onChange && onChange(!checkout)}>
    <CheckoutIcon className={checkout ? 'checkout':''}/>
    <span>{title}</span>
    </CheckoutBox>
}
export default Checkout

const CheckoutIcon=styled.div`
 width:14px;
  height:14px;
  background-image:url(${Checkout_No}) ;
  &.checkout{
    background-image:url(${Checkout_Yes}) ;
  }
  background-size:100% 100%;
`
const CheckoutBox=styled.div`
cursor: pointer;
font-size: 11px;
font-family: Poppins-Medium, Poppins;
font-weight: 500;
color: #CCCCCC;
line-height: 12px; 
display:flex;
span{
  margin-left:5px;
}
`