import httpUrl from "../axios";

export function getLogin(data: any) {
  return httpUrl.post("/api/oauth/login/", data);
}

export function getHomeData() {
  return httpUrl.get("/api/v1/home");
}
export function getLastBlock() {
  return httpUrl.get("/api/v1/home/last_block");
}

export function tableList(url: any, params: any) {
  return httpUrl.get(url, params);
}
//detail
export function getLastDetail(params: any) {
  return httpUrl.get(`/api/v1/home/block/${params}/`);
}

export function getLastList(params: any) {
  return httpUrl.get(`/api/v1/home/block/${params}/transactions`);
}

export function getTransactionDetail(params: any, datas: any) {
  return httpUrl.get(`/api/v1/home/transaction_details/${params}/`, datas);
}

export function getMyDetail(params: any,datas:any) {
  return httpUrl.get(`/api/v1/home/my_details/${params}/`,datas);
}
//search
export function getSearch(params: any) {
  return httpUrl.get(`/api/v1/search`, params);
}
