import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { CardHight } from "../../../../components/Card";

export interface InputProps {
  noerr?: boolean;
  error?: string;
  className?: string;
  type?: "password" | "text";
  endAdornment?: React.ReactNode;
  disabled?: boolean;
  onBlur?: any;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  placeholder?: string;
  startAdornment?: React.ReactNode;
  value: string;
  style?: React.CSSProperties;
  onKeyDown?:any;
  bgcolor?:string;
}

const Input: React.FC<InputProps> = ({
  noerr,
  error,
  endAdornment,
  onBlur,
  onChange,
  placeholder,
  startAdornment,
  value,
  disabled,
  style,
  type = "text",
  className,
  onKeyDown,
  bgcolor = 'transparent'
}) => {
  const {t}=useTranslation()
  return (
    <StyledInputWrapper>
      <CardBox bgcolor={error ? 'rgba(227, 113, 101, 1)':bgcolor }>
      <StyledInputBox className={className} isVal={!!value} isErr={!!error}>
        {!!startAdornment && startAdornment}
        <StyledInput
          onBlur={onBlur}
          className="input"
          type={type}
          style={style}
          placeholder={placeholder}
          disabled={disabled}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
        />
        {!!endAdornment && endAdornment}
      </StyledInputBox>
      </CardBox>
      {!noerr && <StyledErrorLine>{t(error)}</StyledErrorLine>}
    </StyledInputWrapper>
  );
};

const CardBox = styled(CardHight)`
`;
const StyledErrorLine = styled.div`
  height: 16px;
  font-size: 16px;
  font-weight: 400;
  color: #e37165;
  line-height: 16px;
  margin: 10px 16px;
`;
const StyledInputWrapper = styled.div``;

const StyledInput = styled.input`
  background: none;
  border: 0;
  font-size: 16px;
  flex: 1;
  height: 45px;
  padding: 0;
  outline: none;
  width: 100%;
  @media (max-width: 1000px) {
    width: 100px;
  }
`;

const StyledInputBox = styled.div<{ isErr: boolean,isVal }>`
  align-items: center;
  display: flex;
  height: 45px;
  border-radius: 12px;
  /**border: 1px solid
    ${(props) =>
      props.isErr ? "rgba(227, 113, 101, 1)" : (props=>props.isVal ? "rgba(255, 255, 255,1)" : "rgba(255, 255, 255, 0.6)")};**/
  ${StyledInput} {
    color: ${(props) => (props.isErr ? "rgba(227, 113, 101, 1)" : "#fff")};
  }
`;

export default Input;
