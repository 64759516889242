import React, { useCallback, useEffect, useState, useMemo } from "react";
import ContainerWrapper from "../../components/ContainerWrapper";
import CommonInput from "../../components/Input";
import blockImg from "../../assets/home/block.png";
import transactionsImg from "../../assets/home/triathon.png";
import tvlImg from "../../assets/home/tvl.png";
import totalImg from "../../assets/home/total.png";
import line_icon from "../../assets/home/line.png";
import { Tabs, Button, Spin, Icon } from "antd";
import EchartsBar from "../../components/Echarts";
import { useHistory } from "react-router-dom";
import CustomTable from "../../components/CustomTable";
import no_connect from "../../assets/images/no-connect.png";
import { block } from "./columns";
import styled from "styled-components";

import ValueSkeleton from "../../components/ValueSkeleton";
// import lasttransaction from './columns';
// import mytransaction from './columns';
// import styled from 'styled-components';
import { getHomeData } from "../../utils/fetch/homepage";
import useStarterWallet from "../../hooks/useStarterWallet";
import Excel from "exceljs";
import { saveAs } from "file-saver";
import { tableList } from "../../utils/fetch/homepage";
import { Divide } from "react-feather";
import { ImageGroup } from "semantic-ui-react";
import NoData from "../../assets/home/noData.png";
import { reserveNum } from "../../utils/utils";
import { JsonRpcBatchProvider } from "@ethersproject/providers";
import DiscordModel from "../../components/DiscordModel";
import axios from "axios";
import { math } from "polished";
import LoadPage from "../../components/LoadPage";
import { useDecry, useSwitchTab } from "../../state/application/hooks";
import { toggleDecry } from "../../state/application/actions";
import { ButtonPink } from "../../components/Button";
import WelcomeTitle from "../Faucel/WelcomeTitle";
import { postSbtReg } from "../../utils/fetch/login";
import { useTranslation } from "react-i18next";
const { TabPane } = Tabs;
export default function Index() {
  const history = useHistory();
  const [loading, setLoading] = useState<Boolean>(false);
  const [headerloading, setHeaderloading] = useState<Boolean>(false);
  const { wallet, account, getSignStr} =
    useStarterWallet();
  const accountSecond = wallet?.babyjubWallet?.publicCompressed;
  // const accountSecond =
  //   "0x91647a650a1ac6cd0d109bf1c7803598a3c8d6ec4679d8e9af16952185fa9115";

  const toDetail = useCallback(
    (id: any, adress: any) => {
      history.push(`/explore/${adress}/${id}`);
      sessionStorage.setItem("isFromOther", "true");
    },
    [history]
  );
  const [headData, setHeadData] = useState<any>();
  const [tvlData, setTvlData] = useState();
  const [transactionData, setTransactionData] = useState();
  const [tvlTimeArr, SetTvlTimeArr] = useState([]);
  const [tvlArr, SetTvlArr] = useState([]);
  const [tranTimeArr, SetTranTimeArr] = useState([]);
  const [tranArr, setTranArr] = useState<any>();
  const [echartKey, setEchartKey] = useState<any>();
  // const [tabKey, setTabKey] = useState<any>(1);
  const [listDats, setListData] = useState<any>();
  // const [isDecry, setIsDecry] = useState<boolean>();
  const {t,i18n}=useTranslation()
  const [isDecry, setIsDecry] = useDecry() 
  const [tabKey,setTabKey] = useSwitchTab() 
  const [showmy,setShowMy] = useState(true)
  const onChange = (key: string) => {
    setEchartKey(key);
  };
  const tabChange = (key: any) => {
    console.log(key);
    setTabKey(key);
  };

  const downFun = useCallback(() => {
    // 初始化excel
    const wb = new Excel.Workbook();
    const ws = wb.addWorksheet("Sheet1");
    let cat = localStorage.getItem("totalPage");
    const url = `/api/v1/home/my_transaction/${accountSecond}?is_decryption=true`;
    const params = {
      page: 1,
      size: cat,
    };
    // 设置表头
    ws.columns = [
      // { header: "Id", key: "id", width: 20 },
      { header: t("Hash"), key: "hash", width: 20 },
      { header: t("From"), key: "fromEthAddr", width: 20 },
      { header: t("To"), key: "toEthAddr", width: 20 },
      { header: t("Type"), key: "type", width: 20 },
      { header: t("Amount"), key: "amount", width: 20 },
      { header: t("Gas Fee"), key: "fee", width: 20 },
      { header: t("Status"), key: "status", width: 20 },
      { header: t("L1Chain"), key: "L1Chain", width: 20 },
      { header: t("Origin Balance"), key: "origin_balance", width: 20 },
      { header: t("New Balance"), key: "new_balance", width: 20 },
      { header: t("Assets"), key: "assets", width: 20 },
      { header: t("Created At"), key: "created_at", width: 20 },
      { header: t("Executed At"), key: "executed_at", width: 20 },
    ];
    tableList(url, params).then((res: any) => {
      if (res?.code === 200) {
        // 循环添加数据
        res?.data?.list.forEach((v: any) => {
          ws.addRow(v);
        });
        wb.xlsx.writeBuffer().then((buffer) => {
            saveAs(
              new Blob([buffer], { type: "application/octet-stream" }),
              `My Transaction.xlsx`
            );
          }).catch((err) => {
            console.log(err)
          })
      }
    })
  }, [])

  const getHeadFun = () => {
    setHeaderloading(false)
    getHomeData().then((res: any) => {
        if (res?.code === 200) {
          setHeadData(res?.data?.data) //headdata
          setTvlData(res?.data?.tvl_chart) //tvldata
          setTransactionData(res?.data?.transaction_chart) //transaction_chart

          const timeArr: any = []
          const dataArr: any = []
          const tranTimeArr: any = []
          const tranDataArr: any = []

          res?.data?.tvl_chart.map((item: any) => {
            timeArr.push(item?.create_at)
            dataArr.push(item?.amount)
          })
          res?.data?.transaction_chart.map((item: any) => {
            tranTimeArr.push(item?.time)
            tranDataArr.push(item?.amount)
          })

          SetTvlTimeArr(timeArr)
          SetTvlArr(dataArr)
          SetTranTimeArr(tranTimeArr)
          setTranArr(tranDataArr)
          setHeaderloading(true)
        }
      }).catch((error) => {
        console.log("request ", error);
      })
  }
  const DecryBtn = async () => {
    if (isDecry) {
      setIsDecry(false)
    } else {
      const [signStr]  = await getSignStr()
      if (signStr) {
        const res = postSbtReg({
          addr: account,
          optype: 'decrypt',
          txhash:signStr
        })
        console.log('save...',res)
        setIsDecry(true)
      }
    }
  };

  //掉接口
  useEffect(() => {
    getHeadFun();
  }, [echartKey]);


  const lastblock=useMemo(()=>block.lastblock.map(((itm:any)=>({
    ...itm,title:t(itm.title)
  }))),[i18n?.language])
  const mytransaction=useMemo(()=>block.mytransaction.map(((itm:any)=>({
    ...itm,title:t(itm.title)
  }))),[i18n?.language])
  const lasttransaction=useMemo(()=>block.lasttransaction.map(((itm:any)=>({
    ...itm,title:t(itm.title)
  }))),[i18n?.language])
  return (
    <ContainerWrapper padding={"50px 0 100px"}>
      {/* {headerloading === true ? ( */}
      <>
        <Homeheader>
          <HeaderTitle>
            <WelcomeTitle/>
          </HeaderTitle>
          <CommonInput />
        </Homeheader>
        <HomeInformation>
          <div className="information-card">
            <div className="data-card">
              <img src={blockImg} alt="" />
              <div>
                <div className="data-card-title">{t('BLOCK')}</div>
                <div className="data-card-count">
                  {!headData ? <ValueSkeleton/>:Math.floor(headData?.block || 0)}
                </div>
              </div>
            </div>
            <div className="data-card">
              <img src={transactionsImg} alt="" />
              <div>
                <div className="data-card-title">{t('TRANSACTIONS')}</div>
                <div className="data-card-count">
                  {!headData ? <ValueSkeleton/>:Math.floor(headData?.transactions || 0)}
                </div>
              </div>
            </div>

            <div className="data-card">
              <img src={tvlImg} alt="" />
              <div>
                <div className="data-card-title">{t('TVL')}</div>
                <div className="data-card-count">
                {!headData ? <ValueSkeleton/>:Math.floor(headData?.tvl || 0)}
                </div>
              </div>
            </div>

            <div className="data-card">
              <img src={totalImg} alt="" />
              <div>
                <div className="data-card-title">{t('TOTAL ACCOUNT')}</div>
                <div className="data-card-count">
                  {!headData ? <ValueSkeleton/>:Math.floor(headData?.total_account || 0)}
                </div>
              </div>
            </div>
          </div>
          <div className="information-bar">
            <Tabs defaultActiveKey="1" onChange={onChange}>
              <TabPane tab={t('TVL')} key="1">
                {tvlTimeArr.length !== 0 && (
                  <EchartsBar yData={tvlTimeArr} xData={tvlArr} />
                )}
                {tvlTimeArr.length === 0 && (
                  <div className="noDataMoudle">
                    <img src={NoData} />
                  </div>
                )}
              </TabPane>
              <TabPane tab={t('TRANSACTIONS')} key="2">
                {tranTimeArr.length !== 0 && (
                  <EchartsBar yData={tranTimeArr} xData={tranArr} />
                )}
                {tranTimeArr.length === 0 && (
                  <div className="noDataMoudle">
                    <img src={NoData} />
                  </div>
                )}
              </TabPane>
            </Tabs>
          </div>
        </HomeInformation>
        <HomeTable>
          <Tabs defaultActiveKey={tabKey} onChange={tabChange}>
            <TabPane tab={t('Last Block')} key="1">
              <CustomTable
                columns={lastblock}
                pagination
                onRow={(params) => {
                  params?.id && toDetail(params.id, "blockdetail");
                }}
                url={"/api/v1/home/last_block"}
              />
            </TabPane>
            <TabPane tab={t('Last Transaction')} key="2">
              <CustomTable
                columns={lasttransaction}
                pagination
                params={{ addr: accountSecond, is_decryption: isDecry }}
                onRow={(params) => {
                  params?.id && toDetail(params.id, "transactiondetail");
                }}
                url={"/api/v1/home/last_transaction"}
              />
            </TabPane>
            <TabPane tab={t('My Transaction')} key="3">
              {account && (
                <>
                  <MyOption>
                    <InterButton style={{width:i18n.language === 'jp'?150:100}} onClick={DecryBtn}>
                      {isDecry ? t('Decrypted') : t('Encrypted')}
                    </InterButton>
                    {isDecry ? <InterButton style={{width:i18n.language === 'jp'?120:100}} onClick={downFun}>{t('Download')}</InterButton> : ""}
                  </MyOption>
                  {!showmy?<LoadPage/>:<CustomTable
                    isDecry={isDecry}
                    columns={mytransaction}
                    pagination
                    params={{ addr: accountSecond, is_decryption: false }}
                    onRow={(params) => {
                      params?.id && toDetail(params.id, "transactiondetail");
                      // params?.type === "CrossChainTransfer"
                      //   ? toAccountDetail(
                      //       params?.toidx,
                      //       "accountdetail",
                      //       params?.chain
                      //     )
                      //   : toAccountDetail(
                      //       params?.fromIdx,
                      //       "accountdetail",
                      //       params?.chain
                      //     );
                    }}
                    url={`/api/v1/home/my_transaction/${accountSecond}`}
                  />}
                </>
              )}
              {!account && (
                <NoConnect>{t('Connect your Web3 wallet to start')}</NoConnect>
              )}
            </TabPane>
          </Tabs>
        </HomeTable>
      </>

      <DiscordModel
        tabKey={tabKey}
        url="https://discord.com/channels/965918503070728203/1011838420630380614"
      ></DiscordModel>
    </ContainerWrapper>
  );
}

const Homeheader = styled.div`
  text-align: center;
  margin-bottom: 81px;
`;

const HeaderTitle = styled.div`
  margin-top: 50px;
  margin-bottom: 45px;
  /* height: 104px; */
  line-height: 52px;
  .title {
    font-size: 36px;
    font-family: Poppins;
    color: #ffffff;
    font-weight: 600;
  }
`;

const HomeInformation = styled.div`
  margin-bottom: 80px;
  .information-card {
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
    .data-card {
      display: flex;
      width: 260px;
      height: 130px;
      padding-top: 37px;
      :nth-child(1) {
        width: 230px;
      }
      :nth-child(2),
      :nth-child(3),
      :nth-child(4) {
        img {
          margin-left: 20px;
        }
        :before {
          content: " ";
          height: 50px;
          width: 3px;
          margin-right: 20px;
          background-image: url(${line_icon});
          background-size: 100% 100%;
        }
      }

      img {
        display: inline-block;
        width: 51px;
        height: 50px;
        margin-right: 20px;
      }
      .data-card-title {
        height: 16px;
        margin-bottom: 12px;
        font-size: 16px;
        font-family: Poppins-Regular, Poppins;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.4);
      }
      .data-card-count {
        height: 24px;
        font-size: 24px;
        font-family: Poppins-SemiBold, Poppins;
        font-weight: 600;
        color: #ffffff;
      }
    }
  }
  .information-bar {
    color: #fff;
    .ant-tabs-nav .ant-tabs-tab {
      display: inline-block;
      font-size: 20px;
      font-family: Poppins-Regular, Poppins;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.4);
      margin: 0;
      padding: 0 20px 0 0;
    }
    .ant-tabs-nav .ant-tabs-tab-active {
      color: #fff;
      font-size: 20px;
      font-family: Poppins;
      margin: 0;
      padding: 0 20px 0 0;
    }
    .ant-tabs-ink-bar {
      visibility: hidden;
    }
    .ant-tabs-bar {
      margin-bottom: 35px;
      border: none;
    }
    .noDataMoudle {
      text-align: center;
      img {
        width: 192px;
        /* height: 196px; */
      }
    }
  }
`;

const HomeTable = styled.div`
  .ant-tabs-nav .ant-tabs-tab {
    font-size: 24px;
    font-family: Poppins-Regular, Poppins;
    font-weight: 500;
    color: #fff;
    margin: 0;
    padding: 0 40px 0 0;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    color: #18f195;
    font-size: 24px;
    font-family: Poppins;
    margin: 0;
    padding: 0 40px 0 0;
  }
  .ant-tabs-ink-bar {
    visibility: hidden;
  }
  .ant-tabs-bar {
    margin-bottom: 20px;
    border: none;
  }
`;

const MyOption = styled.div`
  margin-top: 20px;
  display:flex;
  gap:10px;
  .ant-btn {
    height: 42px;
    border: 1px solid #18f195;
    background: transparent;
  }
  .ant-btn > i,
  .ant-btn > span {
    color: #18f195;
  }
`;

const NoConnect = styled.div`
  margin-top: 20px;
  text-align: center;
  height: 224px;
  font-size: 24px;
  font-family: Poppins-SemiBold, Poppins;
  font-weight: 600;
  color: #ffffff;
  line-height: 224px;
  background-image: url(${no_connect});
  background-size: 100% 100%;
`;

const InterButton = styled(ButtonPink)`
  width: 100px;
  /* margin: 68px auto 0; */
`;