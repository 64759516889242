import abiRollup from './abiRollup.json';
import abiShuiPool from './abifaucel.json';
import abiShuiPoolNew from './abifaucel_new.json';
import { BankInfo, Deployments, VersionType } from './poolsType';


export const externalTokens: { [contractName: string]: [string, number] } = {
  wETH: ['0x47a4357315e1d3AB3294c161c3a52e8b0d3A4109', 18],
  USDT: ['0xa41Ed18AdF3fe3B2f4aF72B4c1df1FCA3e97B3f9', 18],
  USDC: ['0xAe8012B09CDE1D5D77bE7EC87E95D52E241489df', 18],
  wBNB: ['0x708415d42f977E252bf787D1e9D71b1fD0F88171', 18],
  TSM: ['0x5995D54A4bD0b79C4164086d24c70C3807CFEFF9', 18],
  testJPY:['0x3c5FE6001dD6f5E85Af75a4e69fFB7785DeFd230',8],


  wETH_V2: ['0x0A3C056A7d7a21F47fd95a5D50a287120fAB8c24', 18],
  USDT_V2: ['0x42e83203fD73c64794A8a14b2BB4d9B5F1075752', 18],
  USDC_V2: ['0xF40029194728512D95Cf67C8A047Dcdf422a934b', 18],
  wBNB_V2: ['0xc59d01d2707235D5234ace4d6AD7Be49e1cC491b', 18],
  TSM_V2: ['0xE04c51Cc56E824543A048207519674e5Cf14f5d8', 18],
  testJPY_V2:['0xeA9ac79A1173348B495056d24Ac7C717Ca9bA797',8]
}

export const deployments: Deployments = {
  ETHPool:{
    'address':'0x302FD86163Cb9Ad5533B3952dafA3B633a82Bc51',
    'abi':abiRollup
  },
  USDTPool:{
    'address':'0x302FD86163Cb9Ad5533B3952dafA3B633a82Bc51',
    'abi':abiRollup
  },
  BNBPool:{
    'address':'0x302FD86163Cb9Ad5533B3952dafA3B633a82Bc51',
    'abi':abiRollup
  },
  USDCPool:{
    'address':'0x302FD86163Cb9Ad5533B3952dafA3B633a82Bc51',
    'abi':abiRollup
  },
  TSMPool:{
    'address':'0x302FD86163Cb9Ad5533B3952dafA3B633a82Bc51',
    'abi':abiRollup
  },
  testJPYPool:{
    'address':'0x302FD86163Cb9Ad5533B3952dafA3B633a82Bc51',
    'abi':abiRollup
  },
  ShuiPool:{
    'address':'0xcd261B50d934e0b701C4742ef91648351533bc07',
    'abi':abiShuiPoolNew
  },
  testJPYFaucet:{
    'address':'0x471da253FCcFa1D592477757dBC501aa3d4BE86D',
    'abi':abiShuiPool
  }
}

export const tokens721: { [contractName: string]: [string, number] } = {
  'NFTToken':['0x6dadD1cb33521700b6ea9B278adEe08891fB55C4',18] 
}

export const bankDefinitions: BankInfo[] = [
  {
    name: 'wETH',
    contract: 'ETHPool',
    tokenId:0,
    depositTokenName: 'wETH',
    earnTokenName: 'wETH',
    finished: false,
    fee:'0.001%',
    sort: 1,
    minVal:0.001,
    stepVal:0.001,
    display:true,
    version:VersionType.V1
  },
  {
    name: 'TSM',
    contract: 'TSMPool',
    tokenId:1,
    depositTokenName: 'TSM',
    earnTokenName: 'TSM',
    finished: false,
    fee:'0.005%',
    sort: 2,
    minVal:1,
    stepVal:0.5,
    display:true,
    version:VersionType.V1
  },
  {
    name: 'USDT',
    contract: 'USDTPool',
    tokenId:2,
    depositTokenName: 'USDT',
    earnTokenName: 'USDT',
    finished: false,
    fee:'0.005%',
    sort: 3,
    minVal:1,
    stepVal:0.5,
    display:true,
    version:VersionType.V1
  },
  {
    name: 'USDC',
    contract: 'USDCPool',
    tokenId:3,
    depositTokenName: 'USDC',
    earnTokenName: 'USDC',
    finished: false,
    fee:'0.005%',
    sort: 4,
    minVal:1,
    stepVal:0.5,
    display:true,
    version:VersionType.V1
  },
  {
    name: 'wBNB',
    contract: 'BNBPool',
    tokenId:4,
    depositTokenName: 'wBNB',
    earnTokenName: 'wBNB',
    finished: false,
    fee:'0.002%',
    sort: 5,
    minVal:0.01,
    stepVal:0.01,
    display:true,
    version:VersionType.V1
  },
  {
    name: 'testJPY',
    contract: 'testJPYPool',
    tokenId:6,
    depositTokenName: 'testJPY',
    earnTokenName: 'testJPY',
    finished: false,
    fee:'0.002%',
    sort: 6,
    minVal:1,
    stepVal:20,
    display:false,
    version:VersionType.V1
  },





  {
    name: 'wETH',
    contract: 'ETHPool',
    tokenId:7,
    depositTokenName: 'wETH_V2',
    earnTokenName: 'wETH_V2',
    finished: false,
    fee:'0.001%',
    sort: 1,
    minVal:0.001,
    stepVal:0.001,
    display:true,
    version:VersionType.V2
  },
  {
    name: 'TSM',
    contract: 'TSMPool',
    tokenId:8,
    depositTokenName: 'TSM_V2',
    earnTokenName: 'TSM_V2',
    finished: false,
    fee:'0.005%',
    sort: 2,
    minVal:1,
    stepVal:0.5,
    display:true,
    version:VersionType.V2
  },
  {
    name: 'USDT',
    contract: 'USDTPool',
    tokenId:9,
    depositTokenName: 'USDT_V2',
    earnTokenName: 'USDT_V2',
    finished: false,
    fee:'0.005%',
    sort: 3,
    minVal:1,
    stepVal:0.5,
    display:true,
    version:VersionType.V2
  },
  {
    name: 'USDC',
    contract: 'USDCPool',
    tokenId:10,
    depositTokenName: 'USDC_V2',
    earnTokenName: 'USDC_V2',
    finished: false,
    fee:'0.005%',
    sort: 4,
    minVal:1,
    stepVal:0.5,
    display:true,
    version:VersionType.V2
  },
  {
    name: 'wBNB',
    contract: 'BNBPool',
    tokenId:11,
    depositTokenName: 'wBNB_V2',
    earnTokenName: 'wBNB_V2',
    finished: false,
    fee:'0.002%',
    sort: 5,
    minVal:0.01,
    stepVal:0.01,
    display:true,
    version:VersionType.V2
  },
  {
    name: 'testJPY',
    contract: 'testJPYPool',
    tokenId:12,
    depositTokenName: 'testJPY_V2',
    earnTokenName: 'testJPY_V2',
    finished: false,
    fee:'0.002%',
    sort: 6,
    minVal:1,
    stepVal:20,
    display:false,
    version:VersionType.V2
  },
].filter(itm=>!itm.finished)