import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ContainerWrapper from "../../components/ContainerWrapper";
import CommonInput from "../../components/Input";
import CustomTable from "../../components/CustomTable";
import columns from "./columns";
import styled from "styled-components";
import useCopyClipboard from "../../hooks/useCopyClipboard";
import copyIcon from "../../assets/images/copy.png";
import copyIcon_act from "../../assets/images/copy_act.png";
import leftoutlined from "../../assets/images/left.png";
import line_icon from "../../assets/home/line.png";
import {
  CustomDescriptions,
  CustomDescriptionsItems,
} from "../../components/Descriptions";
import { Tabs, Descriptions, Icon, Spin } from "antd";
import { getLastDetail } from "../../utils/fetch/homepage";
import useStarterWallet from "../../hooks/useStarterWallet";
import { toNonExponential } from "../../utils/utils";
import Copide from "../../components/Copide";
import { dateFormat } from "../../utils/tools";
import { useDecry } from "../../state/application/hooks";
import { useTranslation } from "react-i18next";
const { TabPane } = Tabs;
const antIcon = <Icon type="loading" style={{ fontSize: 30 }} spin />;

const BlockDetails = () => {
  const [isCopied, setCopied] = useCopyClipboard();
  const history = useHistory();
  const paramsId = useParams<any>();
  const isFromOther = sessionStorage.getItem("isFromOther");
  const [blockData, setBlockData] = useState<any>();
  const [transationData, setTransactionData] = useState<any>();
  const [blockList, setBlockList] = useState<any>();
  const [loading, setLoading] = useState<Boolean>(false);
  // const [isDecry, setIsDecry] = useState<Boolean>(
  //   JSON.parse(sessionStorage.getItem("decryItem")) || false
  // );
  const [isDecry, setIsDecry] = useDecry() 
  const { wallet } = useStarterWallet();
  const accountSecond = wallet?.babyjubWallet?.publicCompressed;
  // const accountSecond =
  // "0x91647a650a1ac6cd0d109bf1c7803598a3c8d6ec4679d8e9af16952185fa9115";

  const {t,i18n}=useTranslation()
  const statusColor = {
    Verified: "#2070FF",
    Pending: "#FF6F00",
    Committed: "#FF00D8",
    Executed: "#18F195",
    Failed: "#FF3700",
  };
  // const account =
  //   "0x6b73bc5f02fd82f8fcfb9792809a04d330ea94d3f25208982f43c4f2c826de21";
  const LastFun = () => {
    setLoading(false);
    const params = paramsId?.id;
    getLastDetail(params)
      .then((res: any) => {
        if (res.code === 200) {
          setBlockData(res?.data?.block_data);
          setTransactionData(res?.data?.transactions_log);
          setLoading(true);
        }
      })
      .catch((error) => {
        console.log("request ", error);
      });
  };

  useEffect(() => {
    LastFun();
  }, [paramsId?.id]);

  const goBack = () => {
    if (isFromOther === "true") {
      history.goBack();
      return;
    }
    history.push("/");
  };
  const columnsArr=useMemo(()=>columns.map(((itm:any)=>({
    ...itm,title:t(itm.title)
  }))),[i18n?.language])
  return (
    <ContainerWrapper padding={"80px 0 93px"}>
      {loading ? (
        <>
          <BlockHeader>
            <div className="block-back" onClick={() => goBack()}>
              <img src={leftoutlined} alt="" />
              <span className="title">{t('Block Details')}</span>
            </div>
            <CommonInput width="523px" />
          </BlockHeader>
          <BlockDetail>
            <BlockTitle>
              {t('Block data')} # <span>{blockData?.id}</span>
            </BlockTitle>
            <Basetab marginLeft="10px" height="336px">
              <Tabs defaultActiveKey="1">
                <TabPane tab={t('Base')} key="1">
                  <div className="block-hash">
                    <div className="block-title">{t('Hash')}</div>
                    <div className="block-content">
                      <span>{blockData?.hash}</span>
                      <Copide text={blockData?.hash}/>
                    </div>
                  </div>
                  <div className="block-hash" style={{ marginBottom: "23px" }}>
                    <div className="block-title">{t('Root Hash')}</div>
                    <div className="block-content">
                      <span>{blockData?.root_hash}</span>
                      <Copide text={blockData?.root_hash}/>
                    </div>
                  </div>
                  <Descriptions title={false} column={2} colon={false}>
                    <Descriptions.Item label={t('Status')}>
                      <StatusBar color={statusColor[blockData?.status]}>
                        {t(blockData?.status)}
                      </StatusBar>
                    </Descriptions.Item>
                    <Descriptions.Item label={t('Validated by')}>
                      <div style={{display:'flex',gap:5,alignItems:'center',justifyContent:'flex-end'}}>
                      {blockData?.validated_by}
                      <Copide text={blockData?.validated_by}/>
                      </div>
                    </Descriptions.Item>
                    <Descriptions.Item label={t("Size")}>
                      {blockData?.size}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("Committed At")}>
                      {dateFormat(blockData?.committed_at)}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("Transactions")}>
                      {blockData?.transactions}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("Verified At")}>
                      {dateFormat(blockData?.verified_at)}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("Gas Fee")}>
                      {toNonExponential(JSON.parse(blockData?.gas_fee))}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("Executed At")}>
                      {dateFormat(blockData?.executed_at)}
                    </Descriptions.Item>
                    <div className="line"></div>
                  </Descriptions>
                </TabPane>
                {/* <TabPane tab="Proof" key="2">
              <span className="Proof">
                0x00000000000000000000000000000000000000000000000000000000000000012a0a59522bbb8e272c60a378f92c6e28702a3e63951f848d55949030ba02934320062deb85ca5ee50fa720e269e0a3e8d5cfe31fb662cdc2151304d977476c600000000106a38eac16661b018dd193767b47f2d51ca26f8598003fb4bd69bd688a474c8079887147be52236dcc9f918a6a1b1da8179c2e524e116683fcf8d4bd21065420d25f1669506d1874b9d793259d062a0a59522bbb8e272c60a378f92c6e639…
              </span>
              <span
                className="copy"
                onClick={() =>
                  setCopied(
                    "0x00000000000000000000000000000000000000000000000000000000000000012a0a59522bbb8e272c60a378f92c6e28702a3e63951f848d55949030ba02934320062deb85ca5ee50fa720e269e0a3e8d5cfe31fb662cdc2151304d977476c600000000106a38eac16661b018dd193767b47f2d51ca26f8598003fb4bd69bd688a474c8079887147be52236dcc9f918a6a1b1da8179c2e524e116683fcf8d4bd21065420d25f1669506d1874b9d793259d062a0a59522bbb8e272c60a378f92c6e639…"
                  )
                }
              />
              {isCopied && <CopyShowTips />}
            </TabPane> */}
              </Tabs>
            </Basetab>
          </BlockDetail>
          <BlockTable>
            <BlockTitle marginbottom={"20px"}>
              {t('Transactions in this block')}
            </BlockTitle>
            <CustomTable
              columns={columnsArr}
              pagination
              onRow={(params) => {
                history.push(`/explore/transactiondetail/${params?.id}`);
              }}
              params={{ addr: accountSecond, is_decryption: isDecry }}
              url={`/api/v1/home/block/${paramsId?.id}/transactions`}
            />
          </BlockTable>
          <BlockLog>
            <BlockTitle>{t('Transactions log')}</BlockTitle>
            <Basetab marginLeft="28px" height="180px">
              <Tabs defaultActiveKey="1">
                {/* <TabPane tab="Goerli" key="1"> */}
                <TabPane tab="Goerli" key="1">
                  <CustomDescriptions
                    marginBottom={"17px"}
                    titleWidth={"119px"}
                  >
                    {transationData?.committed_at && (
                     <div>
                      <CustomDescriptionsItems label={t('Committed Hash')}>
                        {transationData?.executed_hash}
                      </CustomDescriptionsItems>
                     </div> 
                    )}
                    {transationData?.verified_at && (
                      <CustomDescriptionsItems label={t("Verified Hash")}>
                        {dateFormat(transationData?.verified_at)}
                      </CustomDescriptionsItems>
                    )}
                    {transationData?.executed_at && (
                      <CustomDescriptionsItems label={t("Executed At")}>
                        {dateFormat(transationData?.executed_at)}
                      </CustomDescriptionsItems>
                    )}
                    {transationData?.eth_forge_hash && (
                      <CustomDescriptionsItems isCopy label={t("forge Hash")} name="BNB">
                        {transationData?.eth_forge_hash}
                      </CustomDescriptionsItems>
                    )}
                  </CustomDescriptions>
                </TabPane>
                {/* <TabPane tab="BNB Chain" key="2">
                  <CustomDescriptionsItems label={"forge Hash"} name="BNB">
                    {transationData?.bnb_forge_hash}
                  </CustomDescriptionsItems>
                </TabPane> */}
              </Tabs>
            </Basetab>
          </BlockLog>
          {/* <BlockLight></BlockLight> */}
        </>
      ) : (
        <Spin indicator={antIcon} />
      )}
    </ContainerWrapper>
  );
};

export default BlockDetails;

const BlockHeader = styled.div`
  margin-bottom: 60px;
  display: flex;
  justify-content: space-between;
  .block-back {
    cursor: pointer;
    img {
      width: 24px;
      height: 24px;
      margin-right: 20px;
      vertical-align: unset;
    }
    .title {
      font-size: 32px;
      font-family: Poppins;
      color: #ffffff;
    }
  }
`;


const StatusBar = styled.div<{ color: string }>`
  color: ${({ color }) => color};
`;

const BlockDetail = styled.div`
  margin-bottom: 60px;
  border-radius: 8px;
  .block-hash {
    display: flex;
    margin-left: 272px;
    margin-bottom: 17px;
    position:relative ;
    z-index:1 ;
    .block-title {
      width: 71px;
      font-size: 14px;
      font-family: Poppins-Regular, Poppins;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.4);
      margin-right: 20px;
    }
    .block-content {
      font-size: 14px;
      font-family: Poppins;
      color: #ffffff;
      display:flex;
      align-items:center;
      gap:5px;
    }
  }
  .line {
    position: absolute;
    height: 92px;
    width: 3px;
    background-image: url(${line_icon});
    background-size: 100% 100%;
    top: 56%;
    left: 47%;
  }
  .Proof {
    display: inline-block;
    color: rgba(255, 255, 255, 0.8);
    max-height: 72px;
    overflow: auto;
  }
  .copy {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-top: 20px;
    background-image: url(${copyIcon});
    background-size: 100% 100%;
    cursor: pointer;
    &:hover {
      color: #fff;
      background-image: url(${copyIcon_act});
    }
  }
  .ant-descriptions-item:nth-child(odd) {
    .ant-descriptions-item-content {
      width: 39%;
    }
  }
  .ant-table-body {
    background-color: black;
  }

  .ant-descriptions-item-label {
    width: 50%;
    text-align: left;
    font-family: Poppins-Regular, Poppins;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.4);
  }
  .ant-descriptions-row > th,
  .ant-descriptions-row > td {
    padding-bottom: 17px;
  }
  .ant-descriptions-item-content {
    width: 50%;
    text-align: right;
    font-size: 14px;
    font-family: Poppins-Medium, Poppins;
    font-weight: 500;
    color: #ffffff;
  }
  .ant-descriptions-item:nth-child(even) {
    .ant-descriptions-item-label {
      width: 30%;
    }
    .ant-descriptions-item-content {
      width: 70%;
    }
  }
`;

const BlockTable = styled.div`
  margin-bottom: 60px;
`;
const BlockLog = styled.div`

`;

const BlockTitle = styled.div<{ marginbottom?: string }>`
  font-size: 24px;
  font-family: Poppins;
  color: #ffffff;
  margin-bottom: ${({ marginbottom }) =>
    marginbottom ? marginbottom : "40px"};
`;

const Basetab = styled.div<{ marginLeft?: string; height: string }>`
  height: ${({ height }) => height};
  padding: 30px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  
  .ant-tabs-nav{
    position:relative ;
  }
 
  .ant-tabs-nav .ant-tabs-tab {
    font-size: 16px;
    font-family: Poppins-Regular, Poppins;
    font-weight: 500;
    color: #fff;
    margin: 0;
    // margin-right: 30px;
    padding: 0 0px 8px 0;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    color: #18f195;
    font-size: 16px;
    font-family: Poppins;
    
    padding: 0 0px 8px 0;
  }
  .ant-tabs-ink-bar {
    width: 16px !important;
    position:relative;
    margin:0 auto;
    background: #18f195 !important;
  }
  .ant-tabs-bar {
    margin-bottom: 30px;
    border: none;
  }
  .ant-tabs-nav-scroll {
    text-align: center;
  }
`;
