import React, { useEffect } from "react";
import styled from "styled-components";
import SuccessImg from "../../assets/connect/success@2x.png";
import CopyIcon from "../../assets/connect/copy@2x.png";
import CopyIcon1 from "../../assets/connect/copy1@2x.png";
import useCopyClipboard from "../../hooks/useCopyClipboard";
import { ButtonHighlights } from "../../components/Button";
import { ReactComponent as Close } from "../../assets/images/x.svg";
import { Spaced } from "../Spaced";
import { hidehash } from "../../utils/utils";
import Toast from "../Toast";
import WebopenIcon from "../../assets/connect/webopen@2x.png";
import WebopenIcon2 from "../../assets/connect/webopen2@2x.png";
import { Link, useHistory } from "react-router-dom";
import Copide from "../Copide";
import {Trans} from 'react-i18next'
interface SuccessPageProps {
  hash: string;
  to: string;
  nei?: boolean;
}

export default function SuccessPage({ hash, to, nei }: SuccessPageProps) {
  const [isCopied, staticCopy] = useCopyClipboard();

  
  const history = useHistory();
  const onBack = () => {
    history.push("/wallet");
  };
  return (
    <>
      <SuccessPageBox>
        <div>
          <SuccessIcon src={SuccessImg} />
        </div>
        <Spaced size="27" />
        <SuccessTitleBox><Trans>Transaction submitted</Trans></SuccessTitleBox>
        <Spaced size="38" />
        <SuccessDescLine>
          <Trans>
          Use the transaction link to track the progress
          </Trans>
        </SuccessDescLine>
        <Spaced size="18" />
        <SuccessDescHash>
          <span>{hidehash(hash, 17)}</span>
          <Copide text={hash}/>
          {nei ? (
            ''
          ) : (
            <LinkHref target="_blank" href={to} />
          )}
        </SuccessDescHash>
      </SuccessPageBox>

      <SubmitButtonSty onClick={onBack}><Trans>Back to wallet</Trans></SubmitButtonSty>
    </>
  );
}

const LinkTxt = styled(Link)``;
const LinkHref = styled.a`
  cursor: pointer;
  display:block ;
  margin-left:10px ;
  width: 12px;
  height: 12px;
  background-size:100%;
  background-image:url(${WebopenIcon}) ;
  &:hover{
    background-image:url(${WebopenIcon2}) ;
  }
`;
const SubmitButtonSty = styled(ButtonHighlights)`
  width: 508px;
  font-size: 11px;
  font-weight: 600;
  color: #292929;
  margin: 45px auto 0;
`;
const OpenImg = styled.img<any>`
  cursor: pointer;
  width: 12px;
  height: 12px;
`;
const CopyBox=styled.div`
  cursor: pointer;
  width: 12px;
  height: 12px;
  margin-right: 5px;
  background-size:100%;
  background-image:url(${CopyIcon});
  &:hover{
    background-image:url(${CopyIcon1});
  }
`

const SuccessDescHash = styled.div`
display:flex ;
align-items:center ;
  font-size: 12px;
  font-family: Poppins-SemiBold, Poppins;
  font-weight: 600;
  color: #ffffff;
  line-height: 12px;
  span {
    margin-right: 5px;
  }
`;
const SuccessDescLine = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #cccccc;
  line-height: 12px;
`;
const SuccessTitleBox = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  line-height: 18px;
`;
const SuccessPageBox = styled.div`
  background: #1d1d1d;
  border-radius: 14px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 66px;
`;

const SuccessIcon = styled.img`
  width: 48px;
  height: 48px;
  margin: 0 auto;
`;
