import React from "react";
import styled from "styled-components";
import useCopyClipboard from "../../hooks/useCopyClipboard";
import CopyIcon from "../../assets/connect/copy@2x.png";
import CopyIcon1 from "../../assets/connect/copy1@2x.png";
import { Popover } from "antd";
import { Tooltip } from "antd";
const Copide: React.FC<{ text: string }> = ({ text }) => {
  const [isCopied, staticCopy] = useCopyClipboard();
//   console.log({isCopied})
//   return <Tooltip open={isCopied} placement="top" trigger="click" title={'Copide'}>
//  <CopyImg onClick={() => staticCopy(text)} />
// </Tooltip>
  return <Popover placement="top" content="Copied" trigger="click">
  <CopyImg onClick={() => staticCopy(text)} />
</Popover>
};

export default Copide

const CopyImg = styled.div`
  cursor: pointer;
  width: 12px;
  height: 12px;
  background-size:100% 100%;
  background-image: url(${CopyIcon});
  &:hover {
    background-image: url(${CopyIcon1});
  }
`;
