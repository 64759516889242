/* eslint-disable */
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Select } from "antd";
import styled from "styled-components";
import WalletBg from "../../assets/images/walletBg.png";
import useStarterWallet, { LoginState } from "../../hooks/useStarterWallet";
import iconFixed from "../../assets/images/icon_fixed.png";
import iconFixedHover from "../../assets/images/icon_fixed_hover.png";
import DiscordModel from "../../components/DiscordModel";
import config from "../../config";
import useFaucel from "../../hooks/useFaucel";
import { TranscationStatus } from "../../hooks/useHandleTransactionReceipt";
import { ButtonHighlights } from "../../components/Button";
import Toast from "../../components/Toast";
import LoadPage from "../../components/LoadPage";
import WelcomeTitle from "./WelcomeTitle";
import { Spaced } from "../../components/Spaced";
import useBanks from "../../hooks/useBanks";
import { CardHight } from "../../components/Card";
import { Bank, VersionType } from "../../utils/poolsType";
import { getTokenSymbol } from "../../components/TokenSymbol/TokenSymbol";
import { injected } from "../../connectors";
import { useTranslation } from "react-i18next";
import { getChainLng } from "../../utils/tools";
export interface OptionProp {
  name: string;
  value: string;
}
const { Option } = Select;

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  .fixModel {
    width: 166px;
    height: 48px;
    background-color: red;
    position: fixed;
    right: 0;
    bottom: 20%;
    background-image: url(${iconFixed});
    background-size: 100% 100%;
    font-size: 16px;
    font-family: Poppins-SemiBold, Poppins;
    font-weight: 600;
    color: #18f195;
    line-height: 16px;
    background: linear-gradient(90deg, #18f195 0%, #1fb7ff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    :hover {
      background: url(${iconFixedHover});
      background-size: 100% 100%;
      color: #292929;
      line-height: 16px;
      text-shadow: 0px 0px 11px rgba(132, 132, 132, 0.6);
    }
  }
`;
const FaucelLine = styled.div`
display: flex;
`
const FaucelBox = styled.div`
  padding: 90px 60px;
  /* height: 350px; */
  
  background: url(${WalletBg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  
  h3 {
    font-size: 24px;
    font-family: Poppins;
    color: #ccc;
    line-height: 24px;
    margin: 0;
    text-align: left;
  }
  h4 {
    font-size: 20px;
    font-family: Poppins-Medium, Poppins;
    font-weight: 500;
    color: #cccccc;
    line-height: 24px;
    padding: 0;
    margin: 0;
  }
`;
const WalletAccount = styled.div`
  font-size: 14px;
  color: #18f195;
  font-family: Poppins-Medium, Poppins;
  font-weight: 500;
  margin: 0 auto;
  text-align: left;
`;
const FaucelSelect = styled.div`
  /* height: 60px; */
  margin: 0 auto;

  .selectClass {
    width: 346px !important;
  }
  .ant-select-selection {
    background-color: rgba(255, 255, 255, 0) !important;
    width: 346px;
    height: 50px;
    line-height: 50px;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
  .ant-select-selection--single .ant-select-selection__rendered {
    height: 100%;
  }
  .ant-select-selection-selected-value {
    color: #fff;
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: left;
  }
  .common-btn {
    position: relative;
    z-index: 8;
    .ant-btn {
      width: 103px;
      height: 50px;
      position: absolute;
      right: 4px;
      top: -49px;
      border: transparent;
      border-radius: 8px;
      background: linear-gradient(to left, #4742de 0%, #30f8bf 70%) !important;
    }
    .common-toright {
      font-size: 14px;
      font-family: Poppins-Medium, Poppins;
      font-weight: 500;
      color: #000000;
      line-height: 14px;
    }
  }
  .ant-select-selection {
    background-color: rgba(255, 255, 255, 0) !important;
  }
  .ant-select-arrow {
    color: #fff;
  }
`;

const FaucelButton: React.FC<{ faucelName: string, cnf: any, setAccountLoading: any }> = ({ faucelName, cnf, setAccountLoading }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation()
  const { onFaucel } = useFaucel(faucelName, (status: TranscationStatus, hash: any) => {
    if (TranscationStatus.loading === status) {
      setAccountLoading(true);
      setLoading(true);
    } else if (
      [TranscationStatus.await, TranscationStatus.pengding].includes(status)
    ) {
      Toast.pending("Pending", 10000, null, `${cnf.etherscanUrl}/tx/${hash}`);
    } else if (TranscationStatus.success === status) {
      setAccountLoading(false);
      setLoading(false);
      Toast.success("Success", 10000, null, `${cnf.etherscanUrl}/tx/${hash}`);
    } else if (TranscationStatus.error === status) {
      setAccountLoading(false);
      setLoading(false);
    } else if (TranscationStatus.close === status) {
      setAccountLoading(false);
      setLoading(false);
    }
  });

  return <SubmitButtonSty load={loading} onClick={onFaucel}>
    {t('Claim')}
  </SubmitButtonSty>
}
export default function Index() {
  const history = useHistory();
  const { account, chainId, loginState, setAccountLoading } =
    useStarterWallet();
  const { banks } = useBanks();
  const cnf = useMemo(() => {
    return config[getChainLng(chainId)];
  }, [chainId, config]);



  const onChange = () => {
    console.log(123);
  };


  const addToken = async (bank: Bank) => {
    const tokenAddress = bank.depositToken.address;
    const tokenSymbol = bank.name;
    const tokenDecimals = bank.depositToken.decimal;
    const tokenImage = getTokenSymbol(bank.depositTokenName);
    const { ethereum } = window as any;
    try {
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      const wasAdded = await ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20", // Initially only supports ERC20, but eventually more!
          options: {
            address: tokenAddress, // The address that the token is at.
            symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: tokenDecimals, // The number of decimals in the token
            image: tokenImage, // A string url of the token logo
          },
        },
      });

      if (wasAdded) {
        console.log("Thanks for your interest!");
      } else {
        console.log("Your loss!");
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    loginState === LoginState.init && history.push("/login");
  }, [loginState]);


  const { t } = useTranslation()
  return (
    <Wrapper style={{ textAlign: "center" }}>
      <Spaced size="100" />
      <WelcomeTitle />
      <Spaced size="94" />
      {LoginState.loading === loginState ? (
        <LoadPage />
      ) : (
        <FaucelBox>
          <FaucelLine>
            <FaucelItem>
              <h3>{t('Tusima Testnet Faucet')}</h3>
              <Spaced size="36" />
              <WalletAccount>{account || '-'}</WalletAccount>
              <Spaced size="58" />
              <FaucelSelect>
                <SelectTit>{t('Select a chain to claim test tokens')}</SelectTit>
                <Spaced size="16" />
                <SelectLineBox>
                  <Select
                    showSearch
                    className="selectClass"
                    style={{ width: 200 }}
                    placeholder={
                      getChainLng(chainId) === "BSC"
                        ? `${config.BSC.fullChainName}`
                        : `${config.ETH.fullChainName}`
                    }
                    optionFilterProp="children"
                    onChange={onChange}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {<Option value="0">{cnf.fullChainName}</Option>}
                  </Select>
                  <FaucelButton cnf={cnf} setAccountLoading={setAccountLoading} faucelName="ShuiPool" />
                </SelectLineBox>
              </FaucelSelect>
            </FaucelItem>
            <FaucelItem>
              <h4>{t('Tusima Testnet Faucet')}</h4>
              <Spaced size="24" />
              <ul>
                <li>
                  {t('Before claiming the Layer 1 test token')},{" "}
                  <a href={cnf.faucelUrl} target="_blank">
                    {t('you need to obtain the testnet token', { name: cnf.fullChainName })}
                  </a>
                </li>
                <Spaced size="16" />
                <li>
                  {t('You will get 100 wETH, 100 TSM, 100 USDT, 100 USDC, 100 wBNB for each claim')}
                </li>
              </ul>
              <Spaced size="24" />
              <FaucelAddTit>{t('Add test token to metamask')}:</FaucelAddTit>
              <Spaced size="16" />
              <AddTokenBox>
                {banks.filter((itm) => itm.display && itm.version === VersionType.V2).map((bank) => {
                  return (
                    <AddTokenItem key={bank.contract} onClick={() => addToken(bank)}>
                      <span>{bank.name}</span>
                    </AddTokenItem>
                  );
                })}
              </AddTokenBox>
            </FaucelItem>
          </FaucelLine>
          <Spaced size="80" />
          <HeiLineBox />
          <Spaced size="59" />
          <JpywLineBox>
            <JpywLineItem >
              <JpywLineTitle>
                {t('New testing phase')}
              </JpywLineTitle>
              <Spaced size="20" />
              <JpywLineContxt>
                <p>
                  {t('Tusima is about to support a new yen stablecoin test: Stablecoin privacy transactions, coming soon')}
                </p>
              </JpywLineContxt>
              <Spaced size="52" />
              <JpywLineBtnTxt>
                {t('Claim')} testJPY
              </JpywLineBtnTxt>
              <Spaced size="16" />
              <FaucelButton cnf={cnf} setAccountLoading={setAccountLoading} faucelName="testJPYFaucet" />
            </JpywLineItem>
            <JpywLineItem>
              <JpywLineTitle>
                {t('Official website')}
              </JpywLineTitle>
              <Spaced size="20" />
              <JpywLineLink>
                {t('Coming soon')}
              </JpywLineLink>
              <Spaced size="70" />
              <JpywLineBtnTxt>
                {t('Add test token to metamask')}:
              </JpywLineBtnTxt>
              <Spaced size="16" />
              {banks.filter((itm) => !itm.display && itm.version === VersionType.V2).map((bank) => {
                return (
                  <AddTokenItem key={bank.contract} onClick={() => addToken(bank)}>
                    <span>{bank.name}</span>
                  </AddTokenItem>
                );
              })}
            </JpywLineItem>
          </JpywLineBox>
        </FaucelBox>
      )}
      <Spaced size="200" />
      <DiscordModel url="https://discord.com/channels/965918503070728203/1011838509713203261"></DiscordModel>
    </Wrapper>
  );
}

const JpywLineLink = styled.div`
font-size: 14px;
font-family: Poppins-Regular, Poppins;
font-weight: 400;
color: #18F195;
line-height: 24px;
`
const JpywLineBtnTxt = styled.div`
font-size: 20px;
font-family: Poppins-Medium, Poppins;
font-weight: 500;
color: #CCCCCC;
line-height: 24px;
`
const JpywLineContxt = styled.div`
p{
font-size: 14px;
font-family: Poppins-Regular, Poppins;
font-weight: 400;
color: #FFFFFF;
line-height: 24px;
}
`
const JpywLineTitle = styled.div`
font-size: 20px;
font-family: Poppins-Medium, Poppins;
font-weight: 500;
color: #CCCCCC;
line-height: 24px;
`
const JpywLineItem = styled.div`
  flex:1;
  text-align:left;
`
const JpywLineBox = styled.div`
  display:flex;
`
const HeiLineBox = styled.div`
  height:1px;
  background-color:#2F3935 ;
`
const AddTokenBox = styled.div`
  display: flex;
  gap: 16px;
`;
const AddTokenItem = styled(ButtonHighlights)`
  width: 94px;
  height: 32px;
  z-index:0;
  /* border-radius: 4px; */
  /* border: 1px solid; */
  span {
  }
  &:hover {
    span {
    }
  }
`;
const FaucelAddTit = styled.div`
  font-size: 20px;
  font-family: Poppins-Medium, Poppins;
  font-weight: 500;
  color: #cccccc;
  line-height: 24px;
`;
const FaucelItem = styled.div`
  flex: 1;
  text-align: left;
  ul {
    position: relative;
    /* left:-22px; */
    padding: 0;
    margin: 0;
    padding-left: 15px;
  }
  li {
    font-size: 14px;
    font-family: Poppins-Regular, Poppins;
    font-weight: 400;
    color: #ffffff;
    line-height: 16px;
    a {
      color: #18f195;
    }
  }
`;
const SelectTit = styled.div`
  font-size: 20px;
  font-family: Poppins-Regular, Poppins;
  font-weight: 400;
  color: #ffffff;
  opacity: 80%;
  margin: 0 auto;
  text-align: left;
`;
const SelectLineBox = styled.div`
  display: flex;
  gap: 30px;
  /* width: 346px; */
  margin: 0 auto;
`;
const SubmitButtonSty = styled(ButtonHighlights)`
  width: 103px;
  /* font-size: 11px; */
  font-weight: 600;
  color: #292929;
`;
