import { useCallback, useEffect, useState } from "react";
import { parseUnits } from "ethers/lib/utils";
import useStarterWallet from "./useStarterWallet";
import useHandleTransactionReceipt, {
  TranscationStatus,
} from "./useHandleTransactionReceipt";
import { Bank } from "../utils/poolsType";
import config from "../config";
import { showExitsBatch, withdrawOffChainTx } from "../utils/cli-utils";
// const feeTable = require('../../js/constants').fee;
import { fee as feeTable } from "../js/constants";
import { CliExternalOperator } from "../js/cliExternalOperator";
import { getBatchIds, postBatchIds } from "../utils/fetch/login";
import { getDefaultProvider } from "../utils/provider";

const useWithdraw = (bank: Bank, setLoading: any) => {
  const [batchId, setBatchId] = useState<number>();
  const {
    starterCash,
    wallet,
    account: ethAddress,
    rollupWallet,
    operatorStr,
    operatorUrl,
    getSignStr
  } = useStarterWallet();
  const handleTransactionReceipt = useHandleTransactionReceipt();
  const [withdrawstar,setWithdrawstar] = useState(false)
  const oldBatchInfo = () => {
    const oldbatchs = JSON.parse(window.localStorage.getItem("batchIds")) || [];
    window.localStorage.setItem("batchIds", "[]");
    return oldbatchs;
  };

  const saveBatchs = async (hash: any, numExitRoot: number) => {
    try {
      const { code, data } = await getBatchIds({
        addr: ethAddress,
        node: operatorStr,
      });

      console.log({ data });
      let batchIds = JSON.parse(data) || [];
      const cachebatchid = oldBatchInfo();

      console.log({ cachebatchid });

      if (cachebatchid.length) {
        cachebatchid.map((id: any) => {
          batchIds.push({
            id,
            hash: "nohash",
          });
        });
      }
      let edit = false;
      batchIds = batchIds.map((item) => {
        if (item.id === numExitRoot) {
          edit = true;
          return {
            hash: hash,
            id: numExitRoot,
          };
        } else {
          return item;
        }
      });
      if (!edit) {
        batchIds.push({
          hash: hash,
          id: numExitRoot,
        });
      }
      console.log("save", batchIds);
      postBatchIds({
        addr: ethAddress,
        node: operatorStr,
        batchId: JSON.stringify(batchIds),
      });
    } catch (e) {
      console.log("save batch error", e);
    }
  };
  const handleOnChainTx = async (numExitRoot: number) => {
    setLoading(TranscationStatus.loading);

    const { ax } = wallet.babyjubWallet.public;
    const { ay } = wallet.babyjubWallet.public;
    const apiOperator = new CliExternalOperator(operatorUrl);
    const infoExitTree = await apiOperator.getExitInfo( bank.tokenId, `0x${ax}`, `0x${ay}`, numExitRoot);
    const pubKeyBabyjubEthCall = [`0x${ax}`, `0x${ay}`];
    if (infoExitTree.found) {
      handleTransactionReceipt(
        await starterCash.Withdraw( bank, numExitRoot, infoExitTree.state.amount, infoExitTree.siblings,
          pubKeyBabyjubEthCall
        ),
        (status: any, hash: any) => {
          if (status === TranscationStatus.pengding) {
            saveBatchs(hash, numExitRoot);
          }else if([TranscationStatus.error,TranscationStatus.success].includes(status)){
            setWithdrawstar(false)
          }
          setLoading(status, hash);
        }
      );
    }
    // console.log(infoExitTree);
  };
  const handleWithdraw = async (amount: string) => {
    if (!amount) {
      return;
    }
    setLoading(TranscationStatus.confirm) 
    const [signStr] = await getSignStr()
    if(!signStr){
      setLoading(TranscationStatus.close)
      return
    }
    setLoading(TranscationStatus.loading);
    const amountBn = parseUnits(amount, bank.depositToken.decimal);
    console.log({
      operatorUrl,
      amountBn,
      rollupWallet,
      tokenId:bank.tokenId,
      fee:feeTable[bank.fee],
      ethAddress
    })
    const res = await withdrawOffChainTx(
      operatorUrl,
      amountBn,
      rollupWallet,
      bank.tokenId,
      feeTable[bank.fee],
      ethAddress
    );

    console.log(`Status: 200, Nonce: ${res.nonce}, txHash: ${res.txHash}`);
    console.log({ amount });
  };

  const fetchBatchs = async () => {
    const { ax } = wallet.babyjubWallet.public;
    const { ay } = wallet.babyjubWallet.public;

    try {
      const bbarr = await showExitsBatch(
        operatorUrl,
        bank.tokenId,
        `0x${ax}`,
        `0x${ay}`
      );

      // console.log({ bbarr });

      const { code, data } = await getBatchIds({
        addr: ethAddress,
        node: operatorStr,
      });

      const arr = data ? JSON.parse(data):[];
      const lists = await Promise.all(
        bbarr.map(async (num: any) => {
          const item = arr.find((im: { id: any }) => String(im.id)  === String(num));
          if (item && item.hash !== "nohash") {
            // const receipt = await provider.getTransactionReceipt(item.hash)
            // if(receipt.status === 1 || receipt.status === 0){
            //   return 0
            // }
            return 0;
          }
          return num;
        })
      );

      const enlist = lists.filter((num) => num);
      const id = enlist.length ? Number(enlist[enlist.length-1]) : 0
      if(id && id !== batchId){
        if(!withdrawstar){
          setWithdrawstar(true)
          handleOnChainTx(id)
        }
      }
      console.log(id)
      setBatchId(id === batchId ? batchId : 0);
    } catch (e) {
      console.log(e);
    }
    return;
  };

  useEffect(() => {
    fetchBatchs().catch(console.error);
    const timeId = setInterval(() => {
      fetchBatchs().catch(console.error);
    }, config.refreshInterval);
    return () => {
      clearTimeout(timeId);
    };
  }, [wallet, ethAddress,withdrawstar]);

  return { onWithdraw: handleWithdraw, batchId, onChainTx: handleOnChainTx,withdrawstar };
};

export default useWithdraw;
