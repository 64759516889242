import { UnsupportedChainIdError } from "@web3-react/core"
import React, {
  createContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { formatEther, parseUnits } from "ethers/lib/utils"
import { AbstractConnector } from "@web3-react/abstract-connector"
import { SUPPORTED_WALLETS } from "../../constants"
import { useActiveWeb3React } from "../../hooks"
import rollupwallet from "../../utils/wallet"
import { convertUtf8ToHex } from "@walletconnect/utils"
// import wallet from "./wallet.json"
import { StarterCash } from "../../utils/StarterCash"
import config from "../../config"
import { computeFee } from "../../js/utils"
import { BigNumber, ethers } from "ethers"
import { fee as feeTable } from "../../js/constants"
import { CliExternalOperator } from "../../js/cliExternalOperator"
import {
  getNonce,
  signMsg,
  regKeystore,
  getTokensPrices,
} from "../../utils/fetch/login"
import { RsaKey } from "../../rollup-utils/rsakey"
import NodeRSA from "node-rsa"
import { Bank } from "../../utils/poolsType"
import { injected, walletconnect } from "../../connectors"
import { useDecry } from "../../state/application/hooks"
import Decrydict from "./decrydict.json"
import { getChainLng, getDisplayBalance, getOperatorUrl } from "../../utils/tools"

export enum LoginState {
  init,
  loading,
  noRegister,
  yesRegister,
  loginSuccess,
}

interface WalletProps {
  ethWallet: {
    address: string;
    id: string;
    Crypto: any;
    "x-ethers": any;
  }
  babyjubWallet: {
    public: {
      ax: string;
      ay: string;
    }
    publicCompressed: string;
    crypto: any;
    ciphertext: string;
    kdfparams: any;
    mac: string;
  }
  rsaKey: {
    public: string;
    crypto: any;
    ciphertext: string;
    kdfparams: any;
    mac: string;
  }
}

export interface StarterContext {
  loadKey?: any;
  clearLogin: any;
  tokenComputeFee: any;
  decryptStr: any;
  getSignStr: any;
  accountloading: boolean;
  setAccountLoading: any;
  operatorUrl?: string;
  operatorStr?: string;
  chainId: number;
  onChangeChain: any;
  starterCashBSC?: StarterCash;
  starterCashETH?: StarterCash;
  starterCash: StarterCash;
  children?: any;
  loginState: LoginState;
  wallet: WalletProps;
  tokensTotal: string;
  account: any
  createKeystore: any
  loadWallet: any
  tryActivation: any
  activationInjected: any
  rollupWallet: any
  active: boolean
  // balance: string
  gasPrice: string
  ethPrice: string
  allTokens: {
    tokenId: number
    number: any
    price: number
  }[]
}

export const Context = createContext<StarterContext>({
  clearLogin: "",
  tokenComputeFee: "",
  decryptStr: "",
  getSignStr: "",
  accountloading: false,
  setAccountLoading: "",
  chainId: config.BSC.chainId,
  onChangeChain: "",
  starterCashBSC: null,
  starterCashETH: null,
  starterCash: null,
  tokensTotal: null,
  loginState: LoginState.loading,
  wallet: undefined,
  account: "",
  createKeystore: "",
  loadWallet: "",
  rollupWallet: "",
  tryActivation: "",
  activationInjected: "",
  active: false,
  // balance: null,
  gasPrice: "",
  ethPrice: "",
  allTokens: [],
})
const SUCCESS_CODE = 200
export const StarterProvider: React.FC<StarterContext> = ({ children }) => {
  const { active, account, activate, chainId } = useActiveWeb3React()
  const [lastBlock, setLastBlock] = useState(0)
  const [loginState, setLoginState] = useState<LoginState>(LoginState.loading)
  const [wallet, setWallet] = useState<any>()
  const [rollupWallet, setRollupWallet] = useState(null)
  const [starterCashBSC, setStarterCashBSC] = useState<StarterCash>()
  const [starterCashETH, setStarterCashETH] = useState<StarterCash>()
  const [allTokens, setAllTokens] = useState([])
  const [tokensPrices, setTokensPrices] = useState([])
  const [tokensTotal, setTokensTotal] = useState(null)
  const [gasPrice, setGasPrice] = useState("")
  const [accountloading, setAccountLoading] = useState(false)
  const [ethPriceVal, setEthPriceVal] = useState()
  const [bnbPriceVal, setBNBPriceVal] = useState()
  const [loadKey, setLoadKey] = useState(null)
  const [isDecry, setIsDecry] = useDecry()
  const { ethereum } = window as any

  const savewallet_name_key = "walletConnector_name"
  const savewallet_psw_key = "walletConnector_psw"
  const savewallet_keystore_key = "walletConnector_keystore"

  const saveWalletInfo = async (savekey: string, value: any) => {
    return localStorage.setItem(savekey, value)
  }

  const getWalletInfo = async (savekey: string) => {
    return localStorage.getItem(savekey)
  }

  const tokenComputeFee = (amount: any, bank:Bank) => {
    const feeSelector = feeTable[bank.fee]
    // console.log({
    //   amount:String(amount),
    //   decimal:bank.depositToken.decimal
    // })
    const amountBn = parseUnits(String(amount), bank.depositToken.decimal)
    const num = computeFee(amountBn, feeSelector)
    // console.log({num},getDisplayBalance(BigNumber.from(num),bank.depositToken.decimal,10))
    const nn = getDisplayBalance(BigNumber.from(num),bank.depositToken.decimal)
    return nn
  };

 

  const ethPrice = useMemo(() => {
    return getChainLng(chainId) === 'BSC' ? bnbPriceVal : ethPriceVal
  }, [chainId, bnbPriceVal, ethPriceVal])

  const BSCCnf = useMemo(() => {
    const data = [
      {
        chainId: `0x${config.BSC.chainId.toString(16)}`,
        // chainName: config.BSC.fullChainName,
        // nativeCurrency:
        // {
        //   name: config.BSC.symbol,
        //   symbol: config.BSC.symbol,
        //   decimals: 18
        // },
        // rpcUrls: [config.BSC.defaultProvider],
        // blockExplorerUrls: [config.BSC.etherscanUrl],
      }
    ]
    return data
  }, [chainId])

  const ETHCnf = useMemo(() => {
    const data = [
      {
        chainId: `0x${config.ETH.chainId.toString(16)}`,
        // chainName: config.ETH.fullChainName,
        // nativeCurrency:
        // {
        //   name: config.ETH.symbol,
        //   symbol: config.ETH.symbol,
        //   decimals: 18
        // },
        // rpcUrls: [config.ETH.defaultProvider],
        // blockExplorerUrls: [config.ETH.etherscanUrl],
      }
    ]
    return data
  }, [chainId])

  const onChangeChain = async (lng: string) => {
    const data = lng === config.BSC.smallChain ? BSCCnf : ETHCnf
    ethereum.request({ method: "wallet_switchEthereumChain", params: data })
  }

  const starterCash = useMemo(() => {
    return getChainLng(chainId) === 'BSC' ? starterCashBSC : starterCashETH
  }, [chainId, starterCashBSC, starterCashETH])

  const operatorUrl = useMemo(() => getOperatorUrl(chainId), [chainId])

  const operatorStr = useMemo(() => {
    var myURL = new URL(config[getChainLng(chainId)].operatorUrl2)
    return myURL.host
  }, [operatorUrl])

  const decryptDetail = (str: any) => {
    if (!str) {
      return
    }
    let transactions_data = {}
    for (const [name, value] of Object.entries(str.transactions_data)) {
      let newval = value
      if (["assets", "gas_fee"].includes(name)) {
        newval = decryptStr(value)
      } else if (["from", "to"].includes(name) && value instanceof Array) {
        newval = value?.map((itm) => decryptStr(itm))
      }
      transactions_data[name] = newval
    }
    return {
      ...str,
      transactions_data,
    }
  }
  const decryptFun = (value) => {
    try {
      return loadKey.decrypt(value, "utf-8")
    } catch (e) {
      return value
    }
  }
  const decryptStr = (str: any, type?: "wallet" | "explorer" | "detail") => {
    if (loadKey) {
      if (type === "detail") {
        return decryptDetail(str)
      }
      let dd = 18
      if(str?.coin){
        const coin = decryptFun(str.coin)
        // console.log({coin})
        if(['6','12'].includes(coin)){
          dd=8
        }
      }
      if (typeof str === "object") {
        let item = {}
        for (const [name, value] of Object.entries(str)) {
          let newval: any
          if (type === "wallet" ||
            (isDecry && Decrydict?.Decry.includes(name))) {
            if ("balance" === name && value instanceof Array) {
              newval = value.map((itm) => {
                let obj = {}
                for (const [nn, vv] of Object.entries(itm)) {
                  obj[nn] = decryptStr(vv)
                }
                return obj
              })
            } else {
              newval = decryptFun(value)
            }
            if ("coin" === name) {
              newval = Decrydict.Tokens[newval] || newval
            } else if (Decrydict.BigNumber.includes(name) && !isNaN(newval)) {
              newval = getDisplayBalance(BigNumber.from(newval),dd)
            }
          } else {
            newval = value
          }
          item[name] = newval
        }
        return item
      }
      const res = isDecry ? loadKey.decrypt(str, "utf-8") : str
      return res
    }
    return str
  };
  const getSignStr = async () => {
    const res = await getNonce({ addr: account, node: operatorStr })
    const { code, data } = res
    if (SUCCESS_CODE === code) {
      const { nonce } = data
      const hexMsg = convertUtf8ToHex(nonce)
      const signed = await ethereum?.request({
        method: "personal_sign",
        params: [hexMsg, account],
      }).catch(() => { })
      return [signed, hexMsg]
    }
    return [null]
  };

  const clearLogin = async () => {
    await saveWalletInfo(savewallet_name_key, "")
    await saveWalletInfo(savewallet_psw_key, "")
    await saveWalletInfo(savewallet_keystore_key, "")
    // sessionStorage.setItem("decryItem", "false")
    setIsDecry(false)
    setWallet("")
    setRollupWallet("")
    setLoginState(LoginState.init)
  };

  const saveKeystore = (keystore) => {
    setWallet(JSON.parse(keystore))
    setLoginState(LoginState.yesRegister)
    saveWalletInfo(savewallet_keystore_key, keystore)
  };

  const initEthereumOn = () => {
    ethereum.on("accountsChanged", () => {
      clearLogin()
      setTimeout(() => {
        window.location.reload()
      }, 200)
    })
    ethereum.on("networkChanged", async () => {
      clearLogin()
      setTimeout(() => {
        window.location.reload()
      }, 200);
    });
  };

  const signLogin = async () => {
    const [signed] = await getSignStr()
    const res = await signMsg({
      sign: signed,
      addr: account,
      node: operatorStr,
    });
    if (res.code === SUCCESS_CODE) {
      const { keystore, status } = res.data
      if (status) {
        saveKeystore(keystore)
      } else {
        setLoginState(LoginState.noRegister)
      }
    }
  };
  const initLogin = async () => {
    try {
      initEthereumOn()
      wallet ? setLoginState(LoginState.loginSuccess) : signLogin()
    } catch (error) {
      // setError(error);
    }
  };

  const loadWallet = async (password: string, callBack: any, errorCall: any, loadCall: any) => {
    try {
      if (!wallet) {
        loadCall(false)
        return
      }
      const rollup = await rollupwallet.Wallet.fromEncryptedJson(wallet, password)
      console.log({ wallet, rollup })
      setRollupWallet(rollup)
      setLoginState(LoginState.loginSuccess)
      callBack()
      saveWalletInfo(savewallet_psw_key, password)
      loadCall(false)
    } catch (e) {
      loadCall(false)
      console.error(e)
      errorCall(e.message)
    }
  };
  const activationInjected = async (
    connector: AbstractConnector | undefined,
    callback?: any
  ) => {
    let name = ""
    Object.keys(SUPPORTED_WALLETS).map((key) => {
      if (connector === SUPPORTED_WALLETS[key].connector) {
        return (name = SUPPORTED_WALLETS[key].name)
      }
      return true
    });
    if (connector) {
      if (![config.BSC.chainId, config.ETH.chainId].includes(Number(ethereum.networkVersion))) {
        await onChangeChain(config.ETH.smallChain)
      }
      saveWalletInfo(savewallet_name_key, name)
      await activate(connector, undefined, true)
    }
  };
  const tryActivation = async (
    connector: AbstractConnector | undefined,
    callback?: any
  ) => {
    let name = ""
    Object.keys(SUPPORTED_WALLETS).map((key) => {
      if (connector === SUPPORTED_WALLETS[key].connector) {
        return (name = SUPPORTED_WALLETS[key].name)
      }
      return true
    });
    if (connector) {
      if (![config.BSC.chainId, config.ETH.chainId].includes(Number(ethereum.networkVersion))) {
        await onChangeChain(config.ETH.smallChain)
      }
      saveWalletInfo(savewallet_name_key, name)
      await activate(connector, undefined, true).then(initLogin).catch((error) => {
          if (error instanceof UnsupportedChainIdError) {
            console.log(error)
            activate(connector) // a little janky...can't use setError because the connector isn't set
          } else {
            console.log("error", error)
          }
      })
    }
  }

  const createKeystore = async (passphrase: string, callback: any) => {
    try {
      console.log("creating new rollup wallet...\n")
      const wallet = await rollupwallet.Wallet.createRandom()
      const encWallet = await wallet.toEncryptedJson(passphrase)
      let encKey = new RsaKey()
      encWallet.rsaKey = JSON.parse(encKey.toEncryptedJson(passphrase))
      let JsonRsa = JSON.stringify(encWallet.rsaKey)
      let bufferPriKey = RsaKey.fromEncryptedJson(JsonRsa, passphrase)
      let stringPriKey = bufferPriKey.toString("hex")

      const params = {
        keystore: JSON.stringify(encWallet, null, 1),
        layer1_addr: account,
        rsa: stringPriKey,
        addr: `0x${encWallet.babyjubWallet.publicCompressed}`,
        node: operatorStr,
      }

      const res = await regKeystore(params)
      if (res.code === SUCCESS_CODE) {
        setWallet(encWallet)
        setLoginState(LoginState.yesRegister)
      }
      callback(false)
    } catch (e) {
      callback(false)
      console.error(e)
    }
  };

  const updateALlTokens = async () => {
    if (!rollupWallet) {
      return
    }
    const { babyjubWallet: { public: publicas } } = wallet
    try {
      const apiOperator = new CliExternalOperator(operatorUrl)
      const allTokens = await Promise.all(
        Object.values(config.ETH.bankDefinitions).map(async (bank: Bank) => {
          let number = undefined
          let price = 0
          if (["USDT", "USDC"].includes(bank.name)) {
            price = 1
          } else if (bank.name === "TSM") {
            price = 2
          } else if(bank.name.includes('JPY') ){
            price = 0.0077
          } else {
            const tokensymbol = `${bank.name === "wETH" ? "ETH" : bank.name === "wBNB" ? "BNB" : bank.name}USDT`
            const itm = tokensPrices.find((im) => im.symbol === tokensymbol)
            if (itm) {
              price = Number(itm.price)
            }
          }
          try {
            const [_,decimal] = config.ETH.externalTokens[bank.name]
            const tokeninfo = await apiOperator.getStateAccount(
                bank.tokenId,
                `0x${publicas.ax}`,
                `0x${publicas.ay}`
              )
              .catch(() => { });
            number = getDisplayBalance(BigNumber.from(tokeninfo?.amount),decimal)
          } catch (e) {
            // console.log(bank.depositTokenName, "----------------");
          }
          // console.log(bank.depositTokenName,price, "----------------");
          return {
            tokenId: bank.tokenId,
            number,
            price,
          }
        })
      )
      const sum = allTokens.reduce(function (prev, cur) {
        return (cur.price * cur.number || 0) + prev
      }, 0)

      setTokensTotal(sum)
      setAllTokens(allTokens)
    } catch (e) { }
  };

  const updateGasPrice = async () => {
    if (starterCash) {
      setGasPrice(String(await starterCash.getPrices()))
    }
  };

  const updateTokensPrices = async () => {
    try {
      let { data, code } = await getTokensPrices({})
      const prices = JSON.parse(data)
      // console.log(prices)
      const bnb = prices.find((itm) => itm.symbol === `BNBUSDT`)
      const eth = prices.find((itm) => itm.symbol === `ETHUSDT`)
      bnb && setBNBPriceVal(bnb.price)
      eth && setEthPriceVal(eth.price)
      setTokensPrices(prices)
    } catch (e) { }
  };


  const autoLogin = async () => {
    const psw = await getWalletInfo(savewallet_psw_key)
    const name = await getWalletInfo(savewallet_name_key)
    const keystore = await getWalletInfo(savewallet_keystore_key)
    if (psw && name && keystore) {
      setLoginState(LoginState.loading)
      const connector = name === "MetaMask" ? injected : walletconnect
      const wallet = JSON.parse(keystore)
      setWallet(wallet)
      const rollup = await rollupwallet.Wallet.fromEncryptedJson(wallet, psw)
      console.log({ wallet, rollup })
      setRollupWallet(rollup)
      await activate(connector, undefined, true).then(initLogin).catch((error) => {
        setLoginState(LoginState.init)
        if (error instanceof UnsupportedChainIdError) {
          console.log(error)
          activate(connector) // a little janky...can't use setError because the connector isn't set
        } else {
          console.log("error", error)
        }
      })
    } else {
      setLoginState(LoginState.init)
    }
  };
  const initNodeRSA = async () => {
    const { rsaWallet } = rollupWallet
    let loadKey = new NodeRSA()
    let bufEncPubKey = Buffer.from(rsaWallet, "hex")
    loadKey.importKey(bufEncPubKey, "pkcs1-private-der")
    setLoadKey(loadKey)
  }

  useEffect(() => {
    autoLogin().catch(console.error)
  }, [])
  useEffect(() => {
    if (rollupWallet) {
      initNodeRSA()
    }
  }, [rollupWallet])
  useEffect(() => {
    if (account) {
      updateGasPrice().catch(console.error)
      const timeId = setInterval(() => {
        updateGasPrice().catch(console.error)
      }, config.refreshInterval)
      return () => {
        clearTimeout(timeId)
      };
    }
  }, [account, rollupWallet])

  useEffect(() => {
    if (account) {
      updateTokensPrices().catch(console.error)
      const timeId = setInterval(() => {
        updateTokensPrices().catch(console.error)
      }, config.refreshInterval)
      return () => {
        clearTimeout(timeId)
      }
    }
  }, [account, rollupWallet, chainId])

  useEffect(() => {
    if (account) {
      updateALlTokens().catch(console.error)
      const timeId = setInterval(() => {
        updateALlTokens().catch(console.error)
      }, config.refreshInterval)
      return () => {
        clearTimeout(timeId)
      };
    }
  }, [account, rollupWallet, tokensPrices, operatorUrl])

  useEffect(() => {
    if (!starterCashETH && ethereum) {
      const starter = new StarterCash(config["ETH"])
      if (account) {
        starter.unlockWallet(ethereum, account)
      }
      setStarterCashETH(starter)
    } else if (account) {
      starterCashETH.unlockWallet(ethereum, account)
    }
  }, [account])

  useEffect(() => {
    if (!starterCashBSC && ethereum) {
      const starter = new StarterCash(config["BSC"])
      if (account) {
        // wallet was unlocked at initialization
        starter.unlockWallet(ethereum, account)
      }
      setStarterCashBSC(starter)
    } else if (account) {
      starterCashBSC.unlockWallet(ethereum, account)
    }
  }, [account])

  useEffect(() => {
    // console.log({ active })
    active && initLogin().catch(console.error)
  }, [active])

  return (
    <Context.Provider
      value={{
        loadKey,
        clearLogin,
        tokenComputeFee,
        decryptStr,
        getSignStr,
        accountloading,
        setAccountLoading,
        operatorStr,
        operatorUrl,
        onChangeChain,
        allTokens,
        starterCash,
        chainId,
        ethPrice,
        gasPrice,
        createKeystore,
        tokensTotal,
        // balance,
        starterCashBSC,
        starterCashETH,
        loginState,
        wallet,
        account,
        tryActivation,
        activationInjected,
        loadWallet,
        rollupWallet,
        active,
      }}
    >
      {children}
    </Context.Provider>
  );
};
