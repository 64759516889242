import React, { useEffect, useRef } from "react"
import { Input, Button } from "antd"
import searchIcon from "../../assets/home/search.png"
import toright from "../../assets/home/toright.png"
import styled from "styled-components"
import iconFixed from "../../assets/images/icon_fixed.png"
import iconFixedHover from "../../assets/images/icon_fixed_hover.png"
import { CardHight } from "../Card"
import { useTranslation } from "react-i18next"

const DiscordModel = (props: any) => {
  const { url } = props
  const {t} = useTranslation()
  return (
    <CommonWrapper>
      <ButtonSty radius={20} border bgcolor="#122">
        <a href={url} target="_blank">
          <span>
          {t('Contribute ideas')}
          </span>
        </a>
      </ButtonSty>
      {/* <CommonWrapperText>
        <a href={url} target="_blank"></a>
      </CommonWrapperText> */}
    </CommonWrapper>
  )
}

export default DiscordModel

const ButtonSty = styled(CardHight)`
  width: 136px;
  height: 38px;
  display:flex ;
  position:relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-direction: column; */
  border-radius: 20px;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  text-align:center ;
  a{
    flex:1;
  }
  strong{
    font-size:12px;
    font-weight:400;
    color:#fff;
  }
  span {
    font-size: 12px;
    font-weight: 400;
    color: #18f195;
    background: linear-gradient(90deg, #18f195 0%, #1fb7ff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &:hover {
    background-color: #fff;
    -webkit-background-clip: text;
    background: linear-gradient(90deg, #18f195 0%, #1fb7ff 100%);

    span {
      font-size: 12px;
      font-weight: 400;
      color: #18f195;
      background: #000;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
`
const CommonWrapper = styled.div`
  position: fixed;
  right: 0;
  bottom: 38px;
  width: 136px;
  height: 38px;
  .neibox {
    cursor: pointer;
    &:hover {
      background-image: linear-gradient(
        270deg,
        rgba(31, 183, 255, 1),
        rgba(24, 241, 149, 1)
      );
    }
  }
`
