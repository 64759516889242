import React from "react";
import styled, { Keyframes } from "styled-components";
import {Spin} from 'antd'
import ReactLoading from 'react-loading';

const LoadingIcon: React.FC<{ size?: any,color?:string }> = ({ size = 12,color='#292929' }) => {
  return (
    <ReactLoading type={'spinningBubbles'} color={color} height={size} width={size} />
  );
};

const LoadingBox = styled.img`
  animation: fade 1s infinite;
  @keyframes fade {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
export default LoadingIcon;
